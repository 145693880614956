/*
@File: Stax Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Default Btn CSS
** - Section Title CSS
** - Main Slides Area CSS
** - Main Hero Area CSS
** - Main Banner Area CSS
** - Partner Area CSS
** - About Area CSS
** - Services Area CSS
** - Services Details Area CSS
** - Choose Area CSS
** - Video Area CSS
** - Projects Area CSS
** - Projects Details Area CSS
** - Testimonials Area CSS
** - Pricing Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Skill Area CSS
** - Features Area CSS
** - Talk Area CSS
** - Team Area CSS
** - Overview Area CSS
** - Page Banner Area CSS
** - Gallery Area CSS
** - FAQ Area CSS
** - Events Area CSS
** - Events Details Area CSS
** - Career Area CSS
** - Apply Area CSS
** - Career Details Area CSS
** - Profile Authentication Area CSS
** - Membership Levels Area CSS
** - Purchase Guide Area CSS
** - Terms of Service Area CSS
** - Privacy Policy Area CSS
** - 404 Error Area CSS
** - Coming Soon Area CSS
** - Courses Area CSS
** - Courses Details Area CSS
** - Products Area CSS
** - Cart Area CSS
** - Wishlist Area CSS
** - Checkout Area CSS
** - Products Details Area CSS
** - Widget Sidebar CSS
** - Pagination CSS

*/
/*================================================
Default CSS
=================================================*/
:root {
  --main-font-family: "Open Sans", sans-serif;
  --heading-font-family: "Jost", sans-serif;
  --monoton-font-family: "Monoton", cursive;
  --main-color: #df1b1b;
  --optional-color: #3e019a;
  --white-color: #ffffff;
  --black-color: #14042c;
  --paragraph-color: #646464;
  --font-size: 16px;
  --transition: 0.6s;
  --btn-gradient-color: radial-gradient(circle at 10% 20%, rgb(221, 49, 49) 0%, rgb(119, 0, 0) 90%);
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--main-font-family);
  font-size: var(--font-size);
}

a {
  text-decoration: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--black-color);
  display: inline-block;
}

a:hover, a:focus {
  color: var(--main-color);
  text-decoration: none;
}

button {
  outline: 0 !important;
}

p {
  margin-bottom: 15px;
  line-height: 1.8;
  color: var(--paragraph-color);
  font-weight: 500;
  font-family: var(--main-font-family);
  font-size: var(--font-size);
}

p:last-child {
  margin-bottom: 0;
}

i {
  line-height: normal;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-75 {
  padding-bottom: 75px;
}

.radius-0 .services-area.with-radius {
  margin: 0;
  border-radius: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black-color);
  font-family: var(--heading-font-family);
  font-weight: 900;
}

.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

.mb-minus-100 {
  margin-bottom: -110px;
}

/*================================================
Default Btn CSS
=================================================*/
.default-btn {
  padding: 15.8px 35px;
  text-align: center;
  color: var(--black-color) !important;
  font-size: var(--font-size);
  font-weight: 600;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  border-radius: 10px;
  z-index: 0;
  background: var(--btn-gradient-color);
  -webkit-box-shadow: none;
          box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-family: var(--heading-font-family);
}

.default-btn:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 550px;
  height: 550px;
  margin: auto;
  background-color: var(--main-color);
  border-radius: 50%;
  z-index: -1;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: translateX(-50%) translateY(-5%) scale(0.4);
          transform: translateX(-50%) translateY(-5%) scale(0.4);
  -webkit-transition: -webkit-transform 0.9s;
  transition: -webkit-transform 0.9s;
  transition: transform 0.9s;
  transition: transform 0.9s, -webkit-transform 0.9s;
}

.default-btn:hover {
  color: var(--white-color);
}

.default-btn:hover:before {
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-transform: translateX(-45%) translateY(0) scale(1);
          transform: translateX(-45%) translateY(0) scale(1);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
}

/*================================================
Section Title CSS
=================================================*/
.section-title {
  max-width: 630px;
  margin: 0 auto 45px;
  text-align: center;
  position: relative;
}

.section-title .section-bg-text {
  color: #f8f7f8;
  font-size: 95px;
  font-family: var(--monoton-font-family);
  font-weight: 400;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  z-index: -1;
  line-height: 0;
}

.section-title span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.section-title h2 {
  font-size: 42px;
  margin-bottom: 0;
  line-height: 1.4;
  position: relative;
}

.section-title h2 b {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title h2 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.section-title p {
  margin-top: 15px;
}

.section-title.section-style-two {
  margin-top: 30px;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  position: fixed;
  z-index: 999999;
  background-color: var(--white-color);
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
}

.preloader .lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.preloader .lds-spinner div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}

.preloader .lds-spinner div::after {
  content: " ";
  display: block;
  position: absolute;
  top: 5px;
  left: 35px;
  width: 5px;
  height: 20px;
  border-radius: 20%;
  background: var(--main-color);
}

.preloader .lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.preloader .lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.preloader .lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.preloader .lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.preloader .lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.preloader .lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.preloader .lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.preloader .lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.preloader .lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.preloader .lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.preloader .lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

.preloader .lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*================================================
Main Slides Area CSS
=================================================*/
.main-slides-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.main-slides-item {
  background-color: var(--black-color);
  padding-top: 150px;
  padding-bottom: 100px;
}

.main-slides-item .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.main-slides-content {
  padding-right: 50px;
}

.main-slides-content span {
  font-size: var(--font-size);
  font-weight: 600;
  color: var(--main-color);
  display: block;
  margin-bottom: 18px;
  font-family: var(--heading-font-family);
}

.main-slides-content h1 {
  font-size: 85px;
  margin-bottom: 0;
  color: var(--white-color);
  position: relative;
}

.main-slides-content p {
  margin-top: 18px;
  margin-bottom: 0;
  color: #cdc4d9;
}

.main-slides-content .slides-btn {
  margin-top: 30px;
}

.home-slides .swiper-pagination {
  bottom: 30px;
}

.home-slides .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #d1cfdb;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 1;
}

.home-slides .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  width: 20px;
  border-radius: 30px;
}

.main-slides-shape-1 {
  position: absolute;
  left: 50px;
  top: 65px;
  z-index: 1;
}

.main-slides-shape-1 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.main-slides-shape-2 {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translateY(-20%) translateX(-50%);
          transform: translateY(-20%) translateX(-50%);
  z-index: 1;
  -webkit-animation: moveleftbounce 2s linear infinite;
          animation: moveleftbounce 2s linear infinite;
}

.main-slides-shape-3 {
  position: absolute;
  bottom: 4%;
  left: 20%;
  z-index: 1;
  -webkit-transform: translateY(-10%) translateX(-20%);
          transform: translateY(-10%) translateX(-20%);
  -webkit-animation: moveleftbounce 1s linear infinite;
          animation: moveleftbounce 1s linear infinite;
}

.main-slides-shape-4 {
  position: absolute;
  bottom: 10%;
  right: 32%;
  -webkit-transform: translateY(-10%) translateX(-32%);
          transform: translateY(-10%) translateX(-32%);
  z-index: 1;
}

.main-slides-shape-4 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.main-slides-shape-5 {
  position: absolute;
  bottom: 5%;
  right: 10%;
  -webkit-transform: translateY(-5%) translateX(-10%);
          transform: translateY(-5%) translateX(-10%);
  z-index: 1;
  -webkit-animation: animationFramesOne 10s infinite linear;
          animation: animationFramesOne 10s infinite linear;
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
            transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
            transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
            transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
            transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
            transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
            transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
            transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
            transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

/*================================================
Main Hero Area CSS
=================================================*/
.main-hero-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.main-hero-area.mt-94 {
  margin-top: 94px;
}

.main-hero-item {
  background-image: url(../../public/images/main-hero/hero-bg-1.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  z-index: 1;
}

.main-hero-item.item-bg-2 {
  background-image: url(../../public/images/main-hero/hero-bg-2.jpg);
}

.main-hero-item.item-bg-3 {
  background-image: url(../../public/images/main-hero/hero-bg-3.jpg);
}

.main-hero-item::before {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(20, 4, 44, 0)), to(#14042c));
  background-image: linear-gradient(to left, rgba(20, 4, 44, 0), #14042c);
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.main-hero-item .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.main-hero-content {
  max-width: 1050px;
}

.main-hero-content span {
  font-size: var(--font-size);
  font-weight: 600;
  color: var(--main-color);
  display: block;
  margin-bottom: 18px;
  font-family: var(--heading-font-family);
}

.main-hero-content h1 {
  font-size: 85px;
  margin-bottom: 0;
  color: var(--white-color);
  position: relative;
}

.main-hero-content h1 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-hero-content p {
  margin-top: 18px;
  margin-bottom: 0;
  color: #cdc4d9;
  max-width: 825px;
}

.main-hero-content .slides-btn {
  margin-top: 30px;
}

.hero-slides .swiper-button-next:after, .hero-slides .swiper-button-prev:after {
  color: var(--main-color);
}

.hero-shape-1 {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.hero-shape-1 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.hero-shape-2 {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.hero-shape-2 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.hero-shape-3 {
  position: absolute;
  bottom: 5%;
  left: 50%;
  z-index: 1;
  -webkit-transform: translateY(-5%) translateY(-50%);
          transform: translateY(-5%) translateY(-50%);
  -webkit-animation: animationFramesOne 10s infinite linear;
          animation: animationFramesOne 10s infinite linear;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url(../../public/images/main-banner/banner-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px;
}

.main-banner-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.main-banner-content {
  margin-top: -120px;
  position: relative;
  z-index: 1;
}

.main-banner-content span {
  font-size: var(--font-size);
  font-weight: 600;
  color: var(--optional-color);
  display: block;
  margin-bottom: 20px;
  font-family: var(--heading-font-family);
}

.main-banner-content h1 {
  font-size: 75px;
  margin-bottom: 0;
  position: relative;
}

.main-banner-content h1 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-banner-content p {
  margin-top: 18px;
  margin-bottom: 0;
  max-width: 825px;
}

.main-banner-content .banner-btn {
  margin-top: 30px;
}

.main-banner-image {
  position: relative;
  z-index: 1;
  text-align: center;
}

.main-banner-image .banner-circle {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 100px;
  right: 0;
  margin: auto;
  z-index: -1;
}

.main-banner-image .banner-circle img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.banner-shape-1 {
  position: absolute;
  top: -100px;
  left: -100px;
  -webkit-animation: moveleftbounce 1s linear infinite;
          animation: moveleftbounce 1s linear infinite;
  z-index: -1;
}

.banner-shape-2 {
  position: absolute;
  bottom: 25px;
  right: -50px;
  -webkit-animation: animationFramesOne 10s infinite linear;
          animation: animationFramesOne 10s infinite linear;
  z-index: -1;
}

.banner-dot-shape-1 {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translateY(-20%) translateX(-50%);
          transform: translateY(-20%) translateX(-50%);
  -webkit-animation: animationFramesOne 25s infinite linear;
          animation: animationFramesOne 25s infinite linear;
  z-index: -1;
}

.banner-dot-shape-2 {
  position: absolute;
  top: 15%;
  left: 5%;
  -webkit-transform: translateY(-15%) translateX(-5%);
          transform: translateY(-15%) translateX(-5%);
  -webkit-animation: animationFramesOne 10s infinite linear;
          animation: animationFramesOne 10s infinite linear;
  z-index: -1;
}

.banner-dot-shape-3 {
  position: absolute;
  bottom: 10%;
  left: 35%;
  -webkit-transform: translateY(-10%) translateX(-35%);
          transform: translateY(-10%) translateX(-35%);
  -webkit-animation: animationFramesOne 20s infinite linear;
          animation: animationFramesOne 20s infinite linear;
  z-index: -1;
}

.banner-dot-shape-4 {
  position: absolute;
  bottom: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  -webkit-animation: animationFramesOne 15s infinite linear;
          animation: animationFramesOne 15s infinite linear;
  z-index: -1;
}

.banner-dot-shape-5 {
  position: absolute;
  top: 20%;
  right: 10%;
  -webkit-transform: translateY(-20%) translateX(-10%);
          transform: translateY(-20%) translateX(-10%);
  -webkit-animation: animationFramesOne 20s infinite linear;
          animation: animationFramesOne 20s infinite linear;
  z-index: -1;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  /* background-image: url(../../public/images/partner/partner-bg.jpg); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background: ; */
}

.partner-card {
  text-align: center;
}

.partner-card a {
  display: block;
  position: relative;
}

.partner-card a img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin: auto;
  width: auto !important;
}

.partner-card a img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  /* visibility: hidden; */
}
/* 
.partner-card a:hover img:nth-child(1) {
  opacity: 0;
  visibility: hidden;
}

.partner-card a:hover img:nth-child(2) {
  opacity: 1;
  visibility: visible;
} */

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.about-content {
  padding-left: 10px;
  position: relative;
  z-index: 1;
}

.about-content span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.about-content h3 {
  font-size: 42px;
  margin-bottom: 15px;
  line-height: 1.4;
  position: relative;
}

.about-content h3 b {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-content .about-btn {
  margin-top: 25px;
}

.about-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
  z-index: -1;
}

.about-wrap-image {
  overflow: hidden;
  border-radius: 15px;
}

.about-wrap-image img {
  border-radius: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.about-wrap-image:hover img {
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.about-wrap-content {
  position: relative;
  z-index: 1;
  padding-left: 45px;
}

.about-wrap-content .about-bg-text {
  color: #f8f7f8;
  font-size: 95px;
  font-family: var(--monoton-font-family);
  font-weight: 400;
  position: absolute;
  top: 0;
  z-index: -1;
  line-height: 0;
}

.about-wrap-content span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.about-wrap-content h3 {
  font-size: 42px;
  margin-bottom: 15px;
  line-height: 1.4;
  position: relative;
}

.about-wrap-content h3 b {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-wrap-content h3 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.about-inner-box {
  margin-top: 50px;
}

.single-about-card {
  margin-bottom: 25px;
}

.single-about-card h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.single-about-card p {
  margin-bottom: 0;
}

.single-about-card .about-btn {
  margin-top: 25px;
}

.single-about-card .card-image {
  overflow: hidden;
  border-radius: 15px;
}

.single-about-card .card-image img {
  border-radius: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-about-card .card-image:hover img {
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.about-circle-shape {
  position: absolute;
  top: 50%;
  left: 5%;
  -webkit-transform: translateY(-50%) translateX(-5%);
          transform: translateY(-50%) translateX(-5%);
}

.about-circle-shape img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.services-area.with-radius {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  /* background-color: #020202; */
  overflow: hidden;
}

.services-area .container-fluid {
  overflow: hidden;
  left: calc((100% - 1320px) / 2);
  position: relative;
}

.services-area.bg-with-14042C-color {
  background-color: var(--black-color);
  border-radius: 0 0 100px 100px;
}

.services-area.bg-with-14042C-color .section-title h2 {
  color: var(--white-color);
}

.services-area.bg-with-14042C-color .section-title p {
  color: #cdc4d9;
}

.services-area.margin-zero {
  background-color: var(--black-color);
}

.services-area-style-two {
  background-color: var(--black-color);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.services-area-style-two .section-title h2 {
  color: var(--white-color);
}

.services-area-style-two .section-title p {
  color: #cdc4d9;
}

.services-area-style-two .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.services-section-content {
  position: relative;
}

.services-section-content .services-bg-text {
  color: #24153a;
  font-size: 95px;
  font-family: var(--monoton-font-family);
  font-weight: 400;
  position: absolute;
  top: 0;
  z-index: -1;
  line-height: 0;
}

.services-section-content span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.services-section-content h3 {
  font-size: 42px;
  margin-bottom: 15px;
  line-height: 1.4;
  position: relative;
  color: var(--white-color);
}

.services-section-content h3 b {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-section-content h3 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.services-section-content p {
  color: #cdc4d9;
}

.services-section-content .services-section-btn {
  margin-top: 25px;
}

.services-item {
  background-color: rgb(20, 4, 44);
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 25px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.services-item .services-image {
  overflow: hidden;
  border-radius: 15px;
}

.services-item .services-image a {
  display: block;
}

.services-item .services-image a img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.services-item .services-content {
  padding: 30px 15px 20px;
}

.services-item .services-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.services-item .services-content h3 a {
  color: var(--white-color);
}

.services-item .services-content p {
  color: #cdc4d9;
  margin-bottom: 0;
}

.services-item .services-content .services-btn {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-top: 20px;
  font-family: var(--heading-font-family);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.services-item .services-content .services-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1.5px;
  background: var(--btn-gradient-color);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.services-item:hover .services-image a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.services-item:hover .services-content h3 a {
  color: var(--main-color);
}

.services-item:hover .services-content .services-btn {
  padding-left: 25px;
}

.services-item:hover .services-content .services-btn::before {
  opacity: 1;
  visibility: visible;
}

.services-slides .swiper-pagination {
  position: initial;
}

.services-slides .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #d1cfdb;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.services-slides .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--btn-gradient-color);
  width: 20px;
  border-radius: 30px;
}

.services-all-btn {
  text-align: center;
  margin-top: 15px;
}

.services-shape-1 {
  position: absolute;
  top: 20%;
  left: 5%;
  -webkit-transform: translateY(-20%) translateX(-5%);
          transform: translateY(-20%) translateX(-5%);
  -webkit-animation: moveleftbounce 2s linear infinite;
          animation: moveleftbounce 2s linear infinite;
}

.services-shape-2 {
  position: absolute;
  top: 10%;
  right: 5%;
  -webkit-transform: translateY(-20%) translateX(-5%);
          transform: translateY(-20%) translateX(-5%);
}

.services-shape-2 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.services-details-desc {
  max-width: 1050px;
  margin: auto;
}

.services-details-desc .article-services-image {
  overflow: hidden;
  border-radius: 15px;
}

.services-details-desc .article-services-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.services-details-desc .article-services-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.services-details-desc .article-services-content {
  padding-top: 35px;
  padding-left: 45px;
  padding-right: 35px;
}

.services-details-desc .article-services-content h3 {
  font-size: 42px;
  margin-bottom: 18px;
}

.services-details-desc .article-services-content .list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}

.services-details-desc .article-services-content .list li {
  list-style-type: none;
  margin-bottom: 20px;
  color: var(--paragraph-color);
  position: relative;
  padding-left: 25px;
  font-weight: 500;
}

.services-details-desc .article-services-content .list li:first-child {
  padding-left: 0;
}

.services-details-desc .article-services-content .list li:last-child {
  margin-bottom: 0;
}

.services-details-desc .article-services-content .list li i {
  position: absolute;
  left: 0;
  top: 2.8px;
  font-size: 18px;
  color: #a66bff;
}

.services-details-desc .article-services-content .list li h4 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}

.services-details-desc .article-services-middle-image {
  overflow: hidden;
  border-radius: 15px;
  margin-top: 35px;
}

.services-details-desc .article-services-middle-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.services-details-desc .article-services-middle-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.services-details-desc .article-services-quote {
  background-color: #f5f5f5;
  padding: 30px 30px 30px 90px;
  border-radius: 15px;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}

.services-details-desc .article-services-quote i {
  color: #cfc3e2;
  font-size: 65px;
  position: absolute;
  left: 45px;
  top: 10px;
  z-index: -1;
  -webkit-animation: moveleftbounce 2s linear infinite;
          animation: moveleftbounce 2s linear infinite;
}

.services-details-desc .article-services-quote p {
  font-size: 22px;
  font-weight: 400;
  color: var(--black-color);
  font-family: var(--heading-font-family);
  margin-bottom: 0;
}

.services-details-desc .article-services-quote .quote-shape {
  position: absolute;
  right: -45px;
  bottom: -45px;
  max-width: 100px;
}

.services-details-desc .article-services-quote .quote-shape img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.services-details-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

/*================================================
Choose Area CSS
=================================================*/
.choose-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.bg-with-F5F5F5-color {
  background-color: #f5f5f5;
  border-radius: 100px 100px 0 0;
}

.single-choose-card {
  margin-bottom: 25px;
}

.single-choose-card .choose-image {
  margin-bottom: 25px;
  position: relative;
  display: inline-block;
}

.single-choose-card .choose-image a {
  display: block;
}

.single-choose-card .choose-image .number {
  position: absolute;
  right: -35px;
  top: -30px;
  font-size: 100px;
  font-weight: 400;
  font-family: var(--monoton-font-family);
  color: #e7e5e9;
}

.single-choose-card .choose-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.single-choose-card .choose-content h3 a {
  color: var(--black-color);
}

.single-choose-card:hover .choose-content h3 a {
  color: var(--main-color);
}

.choose-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
  z-index: -1;
}

.choose-shape-2 {
  position: absolute;
  left: 200px;
  margin: auto;
  top: -300px;
  z-index: -1;
}

/*================================================
Video Area CSS
=================================================*/
.video-area {
  position: relative;
  z-index: 1;
}

.video-view-content {
  text-align: center;
  position: relative;
  z-index: 1;
}

.video-view-content .video-image {
  overflow: hidden;
  border-radius: 15px;
}

.video-view-content .video-image img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 15px;
}

.video-view-content .video-btn {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 110px;
  background-color: var(--black-color);
  border-radius: 50%;
  color: var(--optional-color);
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 65px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #fb64bf;
}

.video-view-content .video-btn i {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-view-content .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fb64bf;
  -webkit-animation: ripple 2s linear infinite;
          animation: ripple 2s linear infinite;
}

.video-view-content .video-btn:hover {
  background-color: var(--optional-color);
}

.video-view-content .video-btn:hover i {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-view-content:hover .video-image {
  overflow: hidden;
}

.video-view-content:hover .video-image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(5px);
          filter: blur(5px);
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
            transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
            transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
  overflow: hidden;
  position: relative;
}

.projects-area.with-black-background {
  /* background-color: #020202; */
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  overflow: hidden;
  /* padding-top: 100px; */
}

.projects-area.with-black-background .section-title h2 {
  color: var(--white-color);
}

.projects-area.with-black-background .section-title p {
  color: #cdc4d9;
}

.projects-area.with-black-background .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.projects-area.style-two-area .container-fluid {
  overflow: hidden;
  left: calc((100% - 1320px) / 2);
  position: relative;
}

.projects-item {
  background-color: #14042c;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 25px;
}

.projects-item .projects-image {
  overflow: hidden;
  border-radius: 15px;
}

.projects-item .projects-image a {
  display: block;
}

.projects-item .projects-image a img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.projects-item .projects-content {
  padding: 30px 20px 20px;
}

.projects-item .projects-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 18px;
}

.projects-item .projects-content h3 a {
  color: var(--white-color);
}

.projects-item .projects-content .projects-btn {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-family: var(--heading-font-family);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.projects-item .projects-content .projects-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1.5px;
  background: var(--btn-gradient-color);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.projects-item:hover .projects-image a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.projects-item:hover .projects-content h3 a {
  color: var(--main-color);
}

.projects-item:hover .projects-content .projects-btn {
  padding-left: 25px;
}

.projects-item:hover .projects-content .projects-btn::before {
  opacity: 1;
  visibility: visible;
}

.projects-item.bg-F2F1F3 {
  background-color: #f2f1f3;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.projects-item.bg-F2F1F3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 0%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.projects-item.bg-F2F1F3 .projects-content h3 a {
  color: var(--black-color);
}

.projects-item.bg-F2F1F3 .projects-content .projects-btn {
  background: var(--main-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.projects-item.bg-F2F1F3 .projects-content .projects-btn::before {
  background: var(--main-color);
  opacity: 0;
  visibility: hidden;
}

.projects-item.bg-F2F1F3:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.projects-item.bg-F2F1F3:hover .projects-content h3 a {
  color: var(--white-color);
}

.projects-item.bg-F2F1F3:hover .projects-content .projects-btn {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.projects-item.bg-F2F1F3:hover .projects-content .projects-btn::before {
  background: var(--btn-gradient-color);
  opacity: 1;
  visibility: visible;
}

.projects-slides .swiper-button-next,
.projects-slides .swiper-button-prev {
  margin-left: 5px;
  margin-right: 5px;
  position: initial;
  margin-top: 15px;
  color: #a66bff;
  border: 2px solid #a66bff;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.projects-slides .swiper-button-next::after,
.projects-slides .swiper-button-prev::after {
  line-height: 46px;
  font-size: 26px;
}

.projects-slides .swiper-button-next:hover,
.projects-slides .swiper-button-prev:hover {
  background-color: var(--main-color);
  color: var(--white-color);
  border: 2px solid var(--main-color);
}

.projects-bg-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}

.projects-shape-1 {
  position: absolute;
  top: 40%;
  left: 10%;
  -webkit-transform: translateY(-40%) translateX(-10%);
          transform: translateY(-40%) translateX(-10%);
  -webkit-animation: animationFramesOne 10s infinite linear;
          animation: animationFramesOne 10s infinite linear;
}

.projects-shape-2 {
  position: absolute;
  bottom: 2%;
  left: 28%;
  -webkit-transform: translateY(-2%) translateX(-28%);
          transform: translateY(-2%) translateX(-28%);
  -webkit-animation: moveleftbounce 1s linear infinite;
          animation: moveleftbounce 1s linear infinite;
}

.projects-shape-3 {
  position: absolute;
  top: 35%;
  right: 5%;
  -webkit-transform: translateY(-35%) translateX(-5%);
          transform: translateY(-35%) translateX(-5%);
}

.projects-shape-3 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.projects-filter-menu {
  list-style-type: none;
  padding: 0;
  text-align: center;
  margin-bottom: 45px;
}

.projects-filter-menu li {
  display: inline-block;
  padding: 10px 25px;
  background-color: var(--white-color);
  color: var(--black-color);
  -webkit-box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-size: var(--font-size);
  font-weight: 600;
  font-family: var(--heading-font-family);
  margin-right: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  cursor: pointer;
}

.projects-filter-menu li:last-child {
  margin-right: 0;
}

.projects-filter-menu li:hover {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  color: var(--white-color);
}

.projects-filter-menu .filter.mixitup-control-active {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  color: var(--white-color);
}

.single-projects-item {
  margin-bottom: 25px;
  position: relative;
}

.single-projects-item::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  visibility: hidden;
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  border-radius: 20px;
  z-index: 1;
}

.single-projects-item .projects-image {
  overflow: hidden;
  border-radius: 20px;
}

.single-projects-item .projects-image a {
  display: block;
}

.single-projects-item .projects-image a img {
  border-radius: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-projects-item .projects-content {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  padding: 30px;
  padding-bottom: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 9;
}

.single-projects-item .projects-content .category {
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
}

.single-projects-item .projects-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.single-projects-item .projects-content h3 a {
  color: var(--white-color);
}

.single-projects-item .projects-content h3 a:hover {
  color: var(--black-color);
}

.single-projects-item .projects-content .projects-btn {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-family: var(--heading-font-family);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.single-projects-item .projects-content .projects-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1.5px;
  background: var(--btn-gradient-color);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-projects-item .projects-content .projects-btn:hover {
  padding-left: 25px;
}

.single-projects-item .projects-content .projects-btn:hover::before {
  opacity: 1;
  visibility: visible;
}

.single-projects-item:hover::before {
  margin: 10px;
  opacity: 1;
  visibility: visible;
}

.single-projects-item:hover .projects-image a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.single-projects-item:hover .projects-content {
  opacity: 1;
  visibility: visible;
  padding-bottom: 30px;
}

.projects-section-content {
  position: relative;
}

.projects-section-content .projects-bg-text {
  color: #f7f7f8;
  font-size: 95px;
  font-family: var(--monoton-font-family);
  font-weight: 400;
  position: absolute;
  top: 0;
  z-index: -1;
  line-height: 0;
}

.projects-section-content span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.projects-section-content h3 {
  font-size: 42px;
  margin-bottom: 15px;
  line-height: 1.4;
  position: relative;
}

.projects-section-content h3 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.projects-section-content p {
  margin-bottom: 0;
}

.projects-section-content .projects-section-btn {
  margin-top: 25px;
}

.projects-slides-two .swiper-pagination {
  position: relative;
}

.projects-slides-two .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #d1cfdb;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 1;
}

.projects-slides-two .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  width: 20px;
  border-radius: 30px;
}

/*================================================
Projects Details Area CSS
=================================================*/
.projects-details-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.projects-details-desc {
  max-width: 1050px;
  margin: auto;
}

.projects-details-desc .article-projects-with-info .projects-info-image {
  position: relative;
}

.projects-details-desc .article-projects-with-info .projects-info-image img {
  border-radius: 25px;
}

.projects-details-desc .article-projects-with-info .projects-info-image .info-shape {
  position: absolute;
  left: -55px;
  bottom: -50px;
}

.projects-details-desc .article-projects-with-info .projects-info-image .info-shape img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.projects-details-desc .article-projects-with-info .projects-info-content {
  padding-left: 20px;
}

.projects-details-desc .article-projects-with-info .projects-info-content span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.projects-details-desc .article-projects-with-info .projects-info-content h3 {
  font-size: 42px;
  margin-bottom: 0;
  line-height: 1.4;
  position: relative;
}

.projects-details-desc .article-projects-with-info .projects-info-content h3 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.projects-details-desc .article-projects-with-info .projects-info-content .info-list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
  display: inline-block;
}

.projects-details-desc .article-projects-with-info .projects-info-content .info-list li {
  font-family: var(--heading-font-family);
  font-weight: 400;
  color: var(--paragraph-color);
  list-style-type: none;
  margin-bottom: 10px;
  display: block;
  padding: 15px 30px;
  border-radius: 30px;
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
}

.projects-details-desc .article-projects-with-info .projects-info-content .info-list li:last-child {
  margin-bottom: 0;
}

.projects-details-desc .article-projects-with-info .projects-info-content .info-list li span {
  color: var(--optional-color);
  font-weight: 600;
  margin-bottom: 0;
}

.projects-details-desc .article-projects-content {
  padding-top: 35px;
  padding-left: 45px;
}

.projects-details-desc .article-projects-content .content h3 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 18px;
}

.projects-details-desc .article-projects-content .content p {
  margin-bottom: 0;
}

.projects-details-desc .article-projects-content .image {
  overflow: hidden;
  border-radius: 15px;
}

.projects-details-desc .article-projects-content .image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.projects-details-desc .article-projects-content .image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.projects-details-desc .article-projects-content .projects-list {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}

.projects-details-desc .article-projects-content .projects-list li {
  list-style-type: none;
  margin-bottom: 20px;
  color: var(--paragraph-color);
  position: relative;
  padding-left: 25px;
  font-weight: 500;
}

.projects-details-desc .article-projects-content .projects-list li:first-child {
  padding-left: 0;
}

.projects-details-desc .article-projects-content .projects-list li:last-child {
  margin-bottom: 0;
}

.projects-details-desc .article-projects-content .projects-list li i {
  position: absolute;
  left: 0;
  top: 2.8px;
  font-size: 18px;
  color: #a66bff;
}

.projects-details-desc .article-projects-content .projects-list li h4 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}

.projects-details-desc .article-projects-image {
  overflow: hidden;
  border-radius: 15px;
  margin-top: 35px;
}

.projects-details-desc .article-projects-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.projects-details-desc .article-projects-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.projects-details-desc .article-projects-quote {
  background-color: #f5f5f5;
  padding: 30px 30px 30px 90px;
  border-radius: 15px;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}

.projects-details-desc .article-projects-quote i {
  color: #cfc3e2;
  font-size: 65px;
  position: absolute;
  left: 45px;
  top: 10px;
  z-index: -1;
  -webkit-animation: moveleftbounce 2s linear infinite;
          animation: moveleftbounce 2s linear infinite;
}

.projects-details-desc .article-projects-quote p {
  font-size: 22px;
  font-weight: 400;
  color: var(--black-color);
  font-family: var(--heading-font-family);
  margin-bottom: 0;
}

.projects-details-desc .article-projects-quote .quote-shape {
  position: absolute;
  right: -45px;
  bottom: -45px;
  max-width: 100px;
}

.projects-details-desc .article-projects-quote .quote-shape img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.projects-details-desc .article-projects-text {
  padding-top: 35px;
  padding-left: 45px;
  padding-right: 30px;
}

.projects-details-desc .article-projects-text h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.projects-details-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.testimonials-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.testimonials-section-content {
  padding-right: 35px;
}

.testimonials-section-content span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.testimonials-section-content h3 {
  font-size: 42px;
  margin-bottom: 15px;
  line-height: 1.4;
  position: relative;
}

.testimonials-section-content h3 b {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonials-section-content h3 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.testimonials-section-content .testimonials-btn {
  margin-top: 25px;
}

.testimonials-item .item-box {
  position: relative;
  border: 1px solid #f2f1f3;
  border-radius: 15px;
  padding: 30px 30px 30px 155px;
  margin-bottom: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.testimonials-item .item-box:last-child {
  margin-bottom: 0;
}

.testimonials-item .item-box img {
  position: absolute;
  left: 30px;
  top: 28px;
  max-width: 100px;
}

.testimonials-item .item-box h4 {
  font-size: var(--font-size);
  margin-bottom: 0;
  font-weight: 500;
  color: var(--optional-color);
}

.testimonials-item .item-box h4 span {
  font-weight: 400;
  color: var(--paragraph-color);
}

.testimonials-item .item-box:hover {
  border: 1px solid var(--white-color);
  -webkit-box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
}

.single-testimonials-card {
  margin-bottom: 25px;
}

.single-testimonials-card p {
  background-color: #f2f1f3;
  padding: 30px;
  border-radius: 25px 25px 25px 0;
  font-size: 20px;
  font-weight: 400;
  font-family: var(--heading-font-family);
  color: var(--black-color);
}

.single-testimonials-card .info-item-box {
  position: relative;
  margin-top: 25px;
  padding-left: 72px;
}

.single-testimonials-card .info-item-box img {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 55px;
}

.single-testimonials-card .info-item-box h4 {
  font-size: var(--font-size);
  margin-bottom: 0;
  font-weight: 500;
  color: var(--optional-color);
}

.single-testimonials-card .info-item-box h4 span {
  font-weight: 400;
  color: var(--paragraph-color);
}

.single-testimonials-card .info-item-box .rating-list {
  padding: 0;
  margin-top: 15px;
  margin-bottom: 0;
}

.single-testimonials-card .info-item-box .rating-list li {
  list-style-type: none;
  margin-right: 2px;
  display: inline-block;
}

.single-testimonials-card .info-item-box .rating-list li:last-child {
  margin-right: 0;
}

.single-testimonials-card .info-item-box .rating-list li i {
  color: #ffc744;
  font-size: var(--font-size);
}

.testimonials-slides .swiper-pagination {
  position: initial;
}

.testimonials-slides .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #d1cfdb;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 1;
}

.testimonials-slides .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--btn-gradient-color);
  width: 20px;
  border-radius: 30px;
}

.testimonialsbg-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.pricing-area.with-black-background {
  background-color: var(--black-color);
  overflow: hidden;
}

.pricing-area.with-black-background .section-title h2 {
  color: var(--white-color);
}

.pricing-area.with-black-background .section-title p {
  color: #cdc4d9;
}

.pricing-area.with-black-background .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.single-pricing-card {
  background-color: #2e2141;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 25px;
  position: relative;
}

.single-pricing-card h3 {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.single-pricing-card .price {
  font-size: 35px;
  color: var(--white-color);
  font-family: var(--heading-font-family);
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

.single-pricing-card .price span {
  color: #9b8dac;
  font-size: var(--font-size);
  font-weight: 400;
  position: relative;
  top: -2px;
}

.single-pricing-card p {
  color: #cdc4d9;
  margin-bottom: 0;
}

.single-pricing-card .pricing-list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}

.single-pricing-card .pricing-list li {
  list-style-type: none;
  list-style-type: none;
  margin-bottom: 20px;
  color: #f2f1f3;
  position: relative;
  padding-left: 35px;
}

.single-pricing-card .pricing-list li:last-child {
  margin-bottom: 0;
}

.single-pricing-card .pricing-list li i {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: var(--black-color);
  border: 1px solid #a66bff;
  border-radius: 50px;
  text-align: center;
  font-size: var(--font-size);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-pricing-card .pricing-list li:hover i {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: var(--black-color);
}

.single-pricing-card .pricing-btn {
  margin-top: 30px;
}

.single-pricing-card .most-popular {
  position: absolute;
  right: 20px;
  top: -15px;
}

.single-pricing-card .most-popular span {
  display: inline-block;
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  padding: 10px 20px;
  border-radius: 15px;
  color: var(--white-color);
  font-family: var(--heading-font-family);
  font-size: 15px;
  font-weight: 600;
}

.pricing-bg-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

.pricing-shape-1 {
  position: absolute;
  top: 10%;
  right: 10%;
  -webkit-transform: translateY(-10%) translateX(-10%);
          transform: translateY(-10%) translateX(-10%);
  z-index: -1;
  -webkit-animation: animationFramesOne 10s infinite linear;
          animation: animationFramesOne 10s infinite linear;
}

.pricing-shape-2 {
  position: absolute;
  bottom: 5%;
  left: 25%;
  -webkit-transform: translateY(-5%) translateX(-25%);
          transform: translateY(-5%) translateX(-25%);
  z-index: -1;
}

.pricing-shape-2 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.blog-card {
  margin-bottom: 25px;
}

.blog-card .blog-image {
  overflow: hidden;
  border-radius: 15px;
}

.blog-card .blog-image a {
  display: block;
}

.blog-card .blog-image a img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-card .blog-content .date {
  display: inline-block;
  font-size: var(--font-size);
  font-weight: 500;
  font-family: var(--heading-font-family);
  padding: 12px 25px;
  border-radius: 50px;
  border: 1px solid var(--white-color);
  -webkit-box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  color: var(--optional-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-card .blog-content h3 {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-weight: bold;
}

.blog-card .blog-content h3 a {
  color: var(--black-color);
}

.blog-card .blog-content p {
  margin-bottom: 0;
}

.blog-card .blog-content .blog-btn {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-family: var(--heading-font-family);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  margin-top: 20px;
}

.blog-card .blog-content .blog-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1.5px;
  background: var(--btn-gradient-color);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-card:hover .blog-image a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.blog-card:hover .blog-content .date {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  color: var(--white-color);
}

.blog-card:hover .blog-content h3 a {
  color: var(--main-color);
}

.blog-card:hover .blog-content .blog-btn {
  padding-left: 25px;
}

.blog-card:hover .blog-content .blog-btn::before {
  opacity: 1;
  visibility: visible;
}

.blog-slides .swiper-pagination {
  position: relative;
}

.blog-slides .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #d1cfdb;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 1;
}

.blog-slides .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  width: 20px;
  border-radius: 30px;
}

.blog-shape-1 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.blog-shape-1 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.blog-grid-sorting {
  margin-bottom: 45px;
  text-align: end;
}

.blog-grid-sorting label {
  display: inline-block;
  margin-bottom: 0;
  color: var(--paragraph-color);
  margin-right: 5px;
  font-weight: 600;
  font-size: 15px;
}

.blog-grid-sorting .nice-select {
  display: inline-block;
  width: 300px;
  background: #f2f1f3;
  border-color: #f2f1f3;
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0 0 0 25px;
  height: 70px;
  line-height: 70px;
  font-weight: 400;
  border-radius: 15px;
}

.blog-grid-sorting .nice-select .list {
  background-color: var(--white-color);
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blog-grid-sorting .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: var(--black-color);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 15px;
  padding-right: 25px;
  font-weight: 600;
}

.blog-grid-sorting .nice-select .list .option.selected.focus {
  color: var(--main-color);
  background-color: var(--white-color);
}

.blog-grid-sorting .nice-select .list .option:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.blog-grid-sorting .nice-select:after {
  border-color: var(--black-color);
  height: 8px;
  width: 8px;
  margin-top: -5px;
  right: 20px;
}

.blog-grid-sorting .nice-select:hover {
  border-color: var(--main-color);
  background-color: transparent;
}

.blog-grid-sorting form {
  position: relative;
  max-width: 450px;
}

.blog-grid-sorting form .search-field {
  height: 70px;
  color: #9b8dac;
  border: 1px solid #f2f1f3;
  display: block;
  width: 100%;
  border-radius: 15px;
  padding: 15px 20px 15px 70px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 400;
  outline: 0;
  background: #f2f1f3;
}

.blog-grid-sorting form .search-field::-webkit-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-grid-sorting form .search-field:-ms-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-grid-sorting form .search-field::-ms-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-grid-sorting form .search-field::placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-grid-sorting form .search-field:focus::-webkit-input-placeholder {
  color: transparent;
}

.blog-grid-sorting form .search-field:focus:-ms-input-placeholder {
  color: transparent;
}

.blog-grid-sorting form .search-field:focus::-ms-input-placeholder {
  color: transparent;
}

.blog-grid-sorting form .search-field:focus::placeholder {
  color: transparent;
}

.blog-grid-sorting form button {
  border: none;
  background-color: transparent;
  color: var(--black-color);
  position: absolute;
  left: 32px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 22.8px;
  font-size: 25px;
  line-height: 0;
}

.blog-grid-sorting form button:hover, .blog-grid-sorting form button:focus {
  color: var(--main-color);
}

.single-blog-card {
  margin-bottom: 25px;
}

.single-blog-card .blog-image {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.single-blog-card .blog-image a {
  display: block;
}

.single-blog-card .blog-image a img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-card .blog-image .date {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--heading-font-family);
  padding: 12px 25px;
  border-radius: 50px;
  color: var(--white-color);
  position: absolute;
  left: 15px;
  bottom: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-card .blog-content {
  margin-top: 25px;
}

.single-blog-card .blog-content h3 {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-weight: bold;
}

.single-blog-card .blog-content h3 a {
  color: var(--black-color);
}

.single-blog-card .blog-content p {
  margin-bottom: 0;
}

.single-blog-card .blog-content .blog-btn {
  color: var(--optional-color);
  font-weight: 600;
  font-family: var(--heading-font-family);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  margin-top: 15px;
}

.single-blog-card .blog-content .blog-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1.5px;
  background: var(--optional-color);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-card:hover .blog-image a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.single-blog-card:hover .blog-image .date {
  -webkit-animation: bounce 1s;
          animation: bounce 1s;
}

.single-blog-card:hover .blog-content h3 a {
  color: var(--main-color);
}

.single-blog-card:hover .blog-content .blog-btn {
  padding-left: 25px;
}

.single-blog-card:hover .blog-content .blog-btn::before {
  opacity: 1;
  visibility: visible;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc .article-image {
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}

.blog-details-desc .article-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-desc .article-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.blog-details-desc .article-image .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 90px;
  background-color: var(--black-color);
  border-radius: 50%;
  color: var(--optional-color);
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 45px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #fb64bf;
}

.blog-details-desc .article-image .video-btn i {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-details-desc .article-image .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fb64bf;
  -webkit-animation: ripple 2s linear infinite;
          animation: ripple 2s linear infinite;
}

.blog-details-desc .article-image .video-btn:hover {
  background-color: var(--optional-color);
}

.blog-details-desc .article-image .video-btn:hover i {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-details-desc .article-content .entry-list {
  padding: 0;
  margin-bottom: 18px;
}

.blog-details-desc .article-content .entry-list li {
  color: var(--black-color);
  font-size: var(--font-size);
  font-family: var(--heading-font-family);
  font-weight: 400;
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
  position: relative;
  padding-left: 25px;
}

.blog-details-desc .article-content .entry-list li:last-child {
  margin-right: 0;
}

.blog-details-desc .article-content .entry-list li::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 15px;
  height: 2.5px;
  background: var(--optional-color);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 50px;
}

.blog-details-desc .article-content .entry-list li a {
  color: var(--optional-color);
  font-weight: 500;
}

.blog-details-desc .article-content h3 {
  font-size: 42px;
  margin-bottom: 15px;
  line-height: 1.5;
}

.blog-details-desc .article-content .list {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}

.blog-details-desc .article-content .list li {
  list-style-type: none;
  margin-bottom: 20px;
  color: var(--paragraph-color);
  position: relative;
  padding-left: 25px;
  font-weight: 500;
}

.blog-details-desc .article-content .list li:first-child {
  padding-left: 0;
}

.blog-details-desc .article-content .list li:last-child {
  margin-bottom: 0;
}

.blog-details-desc .article-content .list li i {
  position: absolute;
  left: 0;
  top: 2.8px;
  font-size: 18px;
  color: #a66bff;
}

.blog-details-desc .article-content .list li h4 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}

.blog-details-desc .article-content .block-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blog-details-desc .article-content .block-item img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 15px;
}

.blog-details-desc .article-content .block-item:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.blog-details-desc .article-content .article-quote {
  background-color: #f5f5f5;
  padding: 30px 30px 30px 90px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blog-details-desc .article-content .article-quote i {
  color: #cfc3e2;
  font-size: 65px;
  position: absolute;
  left: 45px;
  top: 10px;
  z-index: -1;
  -webkit-animation: moveleftbounce 2s linear infinite;
          animation: moveleftbounce 2s linear infinite;
}

.blog-details-desc .article-content .article-quote p {
  font-size: 22px;
  font-weight: 400;
  color: var(--black-color);
  font-family: var(--heading-font-family);
  margin-bottom: 0;
}

.blog-details-desc .article-content .article-quote .quote-shape {
  position: absolute;
  right: -45px;
  bottom: -45px;
  max-width: 100px;
}

.blog-details-desc .article-content .article-quote .quote-shape img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.blog-details-desc .article-share {
  background-color: #f2f1f3;
  padding: 30px;
  border-radius: 10px;
  margin-top: 30px;
}

.blog-details-desc .article-share .share-content h4 {
  margin-bottom: 0;
  font-size: var(--font-size);
  font-weight: bold;
}

.blog-details-desc .article-share .share-social {
  padding: 0;
  margin-bottom: 0;
}

.blog-details-desc .article-share .share-social li {
  display: inline-block;
  list-style-type: none;
  margin-right: 5px;
}

.blog-details-desc .article-share .share-social li:last-child {
  margin-right: 0;
}

.blog-details-desc .article-share .share-social li a i {
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50px;
  background-color: var(--white-color);
  color: #9b8dac;
  font-size: 18px;
}

.blog-details-desc .article-share .share-social li a i:hover {
  color: var(--white-color);
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
}

.blog-details-desc .article-comments {
  margin-top: 35px;
}

.blog-details-desc .article-comments h3 {
  font-size: 22px;
  margin-bottom: 35px;
}

.blog-details-desc .article-comments .comments-list {
  position: relative;
  padding-left: 150px;
  margin-bottom: 35px;
}

.blog-details-desc .article-comments .comments-list img {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px;
  max-width: 128px;
}

.blog-details-desc .article-comments .comments-list h5 {
  font-size: 18px;
  margin-bottom: 12px;
  color: var(--optional-color);
  font-weight: 600;
}

.blog-details-desc .article-comments .comments-list h5 span {
  font-size: var(--font-size);
  font-weight: 400;
  color: #9b8dac;
}

.blog-details-desc .article-comments .comments-list p {
  margin-bottom: 12px;
}

.blog-details-desc .article-comments .comments-list .reply-btn {
  font-size: var(--font-size);
  font-weight: 600;
  color: var(--main-color);
  font-family: var(--heading-font-family);
}

.blog-details-desc .article-comments .comments-list.children {
  margin-left: 50px;
}

.blog-details-desc .article-leave-comment {
  border-top: 1px solid #f2f1f3;
  margin-top: 45px;
  padding-top: 35px;
}

.blog-details-desc .article-leave-comment h3 {
  font-size: 22px;
  margin-bottom: 35px;
}

.blog-details-desc .article-leave-comment .form-group {
  margin-bottom: 25px;
}

.blog-details-desc .article-leave-comment .form-group label {
  display: block;
  margin-bottom: 10px;
  color: var(--black-color);
  font-weight: 500;
  font-size: 15px;
}

.blog-details-desc .article-leave-comment .form-group .form-control {
  height: 55px;
  padding: 15px 20px;
  line-height: initial;
  color: #9b8dac;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--font-size);
  font-weight: 400;
  outline: 0;
  font-family: var(--heading-font-family);
}

.blog-details-desc .article-leave-comment .form-group .form-control::-webkit-input-placeholder {
  color: #9b8dac;
}

.blog-details-desc .article-leave-comment .form-group .form-control:-ms-input-placeholder {
  color: #9b8dac;
}

.blog-details-desc .article-leave-comment .form-group .form-control::-ms-input-placeholder {
  color: #9b8dac;
}

.blog-details-desc .article-leave-comment .form-group .form-control::placeholder {
  color: #9b8dac;
}

.blog-details-desc .article-leave-comment .form-group .form-control:focus {
  border: 1px solid var(--main-color);
}

.blog-details-desc .article-leave-comment .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-desc .article-leave-comment .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-desc .article-leave-comment .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-desc .article-leave-comment .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-desc .article-leave-comment .form-group textarea.form-control {
  min-height: 115px;
}

.blog-details-desc .article-leave-comment .form-cookies-consent {
  margin-bottom: 20px;
}

.blog-details-desc .article-leave-comment .form-cookies-consent a {
  color: var(--main-color);
}

.blog-details-desc .article-leave-comment .form-cookies-consent a:hover {
  color: var(--optional-color);
}

.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:checked,
.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:not(:checked) {
  display: none;
}

.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:checked + label,
.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  color: var(--paragraph-color);
  font-weight: 400;
  font-size: 15px;
}

.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:checked + label:before,
.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: var(--white-color);
}

.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:checked + label:after,
.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 5px;
  height: 5px;
  background: var(--main-color);
  position: absolute;
  top: 10.5px;
  left: 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 30px;
}

.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:hover + label:before {
  border-color: var(--main-color);
}

.blog-details-desc .article-leave-comment .form-cookies-consent [type="checkbox"]:checked + label:before {
  border-color: var(--main-color);
}

.blog-details-desc .article-leave-comment .form-cookies-consent p {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 25px;
}

.blog-details-desc .article-leave-comment .form-cookies-consent p:last-child {
  margin-right: 0;
}

.blog-details-desc .article-leave-comment .default-btn {
  border: none;
}

/*================================================
Skill Area CSS
=================================================*/
.skill-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.skill-content span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.skill-content h3 {
  font-size: 42px;
  margin-bottom: 15px;
  line-height: 1.4;
  position: relative;
}

.skill-content h3 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.skill-bar {
  margin-top: 25px;
}

.skill-bar .progress-title {
  font-size: var(--font-size);
  font-weight: 500;
  color: var(--black-color);
  font-family: var(--heading-font-family);
  margin-bottom: 10px;
}

.skill-bar .progress {
  border-radius: 30px;
  padding: 5px;
  background-color: transparent;
}

.skill-bar .progress .progress-bar {
  border-radius: 30px;
}

.skill-bar .border-3e019a {
  border: 1px solid #3e019a;
}

.skill-bar .bg-3e019a {
  background-color: #3e019a;
}

.skill-bar .border-D5158F {
  border: 1px solid #D5158F;
}

.skill-bar .bg-D5158F {
  background-color: #D5158F;
}

.skill-bar .border-feb302 {
  border: 1px solid #feb302;
}

.skill-bar .bg-feb302 {
  background-color: #feb302;
}

.skill-bar .border-A66BFF {
  border: 1px solid #A66BFF;
}

.skill-bar .bg-A66BFF {
  background-color: #A66BFF;
}

.skill-bar-btn {
  margin-top: 35px;
}

.skill-image {
  padding-left: 45px;
  position: relative;
  z-index: 1;
}

.skill-image .skill-shape-1 {
  position: absolute;
  right: 55px;
  top: -15px;
}

.skill-image .skill-shape-1 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.skill-image .skill-shape-2 {
  position: absolute;
  left: 0;
  bottom: -15px;
}

.skill-image .skill-shape-2 img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.skill-bg-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

/*================================================
Features Area CSS
=================================================*/
.features-inner-box {
  position: relative;
  margin-top: -50px;
  z-index: 1;
}

.single-features-card {
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
  padding: 25px 25px 25px 140px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 25px;
}

.single-features-card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 0%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.single-features-card .features-image {
  position: absolute;
  left: 25px;
  top: 22px;
  max-width: 90px;
}

.single-features-card .content {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-features-card .content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 14px;
}

.single-features-card .content h3 a {
  color: var(--black-color);
}

.single-features-card .content p {
  margin-bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-features-card .hover-content {
  position: absolute;
  top: 45px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-features-card .hover-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 14px;
}

.single-features-card .hover-content h3 a {
  color: var(--black-color);
}

.single-features-card .hover-content .features-btn {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-family: var(--heading-font-family);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.single-features-card .hover-content .features-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1.5px;
  background: var(--btn-gradient-color);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-features-card .hover-content .features-btn:hover {
  padding-left: 25px;
}

.single-features-card .hover-content .features-btn:hover::before {
  opacity: 1;
  visibility: visible;
}

.single-features-card:hover::before {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
}

.single-features-card:hover .content {
  opacity: 0;
  visibility: hidden;
}

.single-features-card:hover .content h3 a {
  color: var(--white-color);
}

.single-features-card:hover .content p {
  color: var(--white-color);
}

.single-features-card:hover .hover-content {
  opacity: 1;
  visibility: visible;
}

.single-features-card:hover .hover-content h3 a {
  color: var(--white-color);
}

.single-features-card:hover .hover-content p {
  color: var(--white-color);
}

/*================================================
Talk Area CSS
=================================================*/
.talk-area {
  background-color: #f5f5f5;
}

.talk-image {
  position: relative;
  z-index: 1;
}

.talk-image .talk-circle {
  position: absolute;
  bottom: -12px;
  left: -50px;
}

.talk-image .talk-circle img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.talk-content {
  position: relative;
  z-index: 1;
  margin-top: 45px;
}

.talk-content .talk-bg-text {
  color: #eeedee;
  font-size: 95px;
  font-family: var(--monoton-font-family);
  font-weight: 400;
  position: absolute;
  top: 0;
  z-index: -1;
  line-height: 0;
}

.talk-content span {
  font-size: var(--font-size);
  color: var(--main-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.talk-content h3 {
  font-size: 42px;
  margin-bottom: 15px;
  line-height: 1.4;
  position: relative;
}

.talk-content h3 b {
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.talk-content h3 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--btn-gradient-color);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.talk-content p {
  margin-bottom: 0;
}

.talk-content .contact-from {
  margin-top: 25px;
}

.talk-content .contact-from .form-group {
  margin-bottom: 20px;
}

.talk-content .contact-from .form-group .form-control {
  height: 55px;
  color: #9b8dac;
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  border: 1px solid var(--white-color);
  background-color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 15px;
}

.talk-content .contact-from .form-group .form-control::-webkit-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.talk-content .contact-from .form-group .form-control:-ms-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.talk-content .contact-from .form-group .form-control::-ms-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.talk-content .contact-from .form-group .form-control::placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.talk-content .contact-from .form-group .form-control:focus {
  border-color: var(--main-color);
  background-color: transparent;
}

.talk-content .contact-from .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.talk-content .contact-from .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.talk-content .contact-from .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.talk-content .contact-from .form-group .form-control:focus::placeholder {
  color: transparent;
}

.talk-content .contact-from .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}

.talk-content .contact-from .default-btn {
  border: none;
}

.talk-content .contact-from .list-unstyled {
  padding: 0;
  margin-bottom: 0;
}

.talk-content .contact-from .list-unstyled li {
  font-size: 14px;
  margin-top: 10px;
  color: red;
}

.talk-content .contact-from .text-danger {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 0;
}

.talk-content.margin-zero {
  margin-top: 0;
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.single-team-card {
  margin-bottom: 25px;
  text-align: center;
}

.single-team-card .team-image {
  position: relative;
}

.single-team-card .team-image .team-social {
  padding: 0;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-team-card .team-image .team-social li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
}

.single-team-card .team-image .team-social li:last-child {
  margin-right: 0;
}

.single-team-card .team-image .team-social li a i {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: var(--white-color);
  color: var(--black-color);
  text-align: center;
  font-size: 18px;
  border-radius: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-team-card .team-image .team-social li a i:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.single-team-card .team-content {
  margin-top: 25px;
}

.single-team-card .team-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}

.single-team-card .team-content span {
  font-size: 14px;
  color: var(--paragraph-color);
  font-weight: 600;
  display: inline-block;
  margin-top: 15px;
}

.single-team-card:hover .team-image {
  position: relative;
}

.single-team-card:hover .team-image .team-social {
  bottom: 25px;
  opacity: 1;
}

.team-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
  background-image: url(../../public/images/overview/overview-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.col-lg-3:last-child .overview-card::before {
  display: none;
}

.overview-card {
  margin-bottom: 25px;
  position: relative;
}

.overview-card::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 40px;
  width: 80px;
  height: 2px;
  background-color: var(--white-color);
}

.overview-card h3 {
  font-size: var(--font-size);
  font-weight: bold;
  margin-bottom: 0;
  color: var(--optional-color);
}

.overview-card span {
  font-size: var(--font-size);
  font-family: var(--heading-font-family);
  color: var(--black-color);
  display: inline-block;
  margin-top: 15px;
  font-weight: 600;
}

.overview-card span a {
  color: var(--black-color);
}

.overview-card span a:hover {
  color: var(--main-color);
}

.overview-card .overview-shape {
  position: absolute;
  left: -15px;
  top: -15px;
  z-index: -1;
}

.overview-card .overview-shape img {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

/*================================================
Page Banner Area CSS
=================================================*/
.page-banner-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 240px;
  padding-bottom: 150px;
}

.page-banner-content h2 {
  font-size: 70px;
  margin-bottom: 20px;
  color: var(--white-color);
}

.page-banner-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.page-banner-content ul li {
  display: inline-block;
  list-style-type: none;
  font-size: var(--font-size);
  font-weight: 400;
  color: var(--main-color);
  position: relative;
  margin-left: 25px;
}

.page-banner-content ul li::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 6px;
  height: 15px;
  width: 1px;
  background: #cdc4d9;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.page-banner-content ul li:first-child {
  margin-left: 0;
}

.page-banner-content ul li:first-child::before {
  display: none;
}

.page-banner-content ul li a {
  display: block;
  color: #cdc4d9;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.page-banner-content ul li a:hover {
  color: var(--main-color);
}

.page-banner-content.wrap-content h2 {
  color: var(--black-color);
}

.page-banner-content.wrap-content ul li {
  color: var(--main-color);
}

.page-banner-content.wrap-content ul li::before {
  background: var(--paragraph-color);
}

.page-banner-content.wrap-content ul li a {
  color: var(--paragraph-color);
}

.page-banner-content.wrap-content ul li a:hover {
  color: var(--main-color);
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
  position: relative;
  margin-bottom: 25px;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}

.single-gallery-item a {
  display: block;
  border-radius: 10px;
}

.single-gallery-item a img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-gallery-item:hover a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion {
  max-width: 1050px;
  margin: auto;
}

.faq-accordion .accordion {
  border: none;
}

.faq-accordion .accordion__item {
  border: 1px solid #e5e5e5;
  margin-bottom: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 5px;
}

.faq-accordion .accordion__item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion__item .accordion__button {
  color: var(--black-color);
  position: relative;
  text-align: start;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  margin-bottom: 0;
  display: block;
  border: none;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding: 20px 25px 20px 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: transparent;
  font-family: var(--heading-font-family);
}

.faq-accordion .accordion__item .accordion__button::before {
  position: absolute;
  right: 20px;
  top: 28px;
}

.faq-accordion .accordion__item .accordion__panel {
  padding: 0 25px 20px;
  border-bottom: 5px solid var(--main-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0 0 5px 5px;
}

.faq-accordion .accordion__item .accordion__panel p {
  color: var(--paragraph-color);
  font-size: 15px;
}

.faq-accordion .accordion__item:hover {
  border-radius: 5px;
  border: 1px solid var(--main-color);
}

.faq-accordion .accordion__item:hover .accordion__panel {
  border-radius: 5px;
}

/*================================================
Events Area CSS
=================================================*/
.coze-grid-sorting {
  margin-bottom: 30px;
}

.coze-grid-sorting .result-count p .count {
  font-weight: 600;
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.coze-grid-sorting .ordering {
  text-align: end;
}

.coze-grid-sorting .ordering label {
  display: inline-block;
  margin-bottom: 0;
  color: var(--paragraph-color);
  margin-right: 5px;
  font-weight: 600;
  font-size: 15px;
}

.coze-grid-sorting .ordering .nice-select {
  display: inline-block;
  width: 215px;
  background: #f8f8f8;
  border-color: #eeeeee;
  color: var(--black-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0 0 0 15px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  border-radius: 5px;
}

.coze-grid-sorting .ordering .nice-select .list {
  background-color: var(--white-color);
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.coze-grid-sorting .ordering .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: var(--black-color);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 15px;
  padding-right: 25px;
  font-weight: 600;
}

.coze-grid-sorting .ordering .nice-select .list .option.selected.focus {
  color: var(--main-color);
  background-color: var(--white-color);
}

.coze-grid-sorting .ordering .nice-select .list .option:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.coze-grid-sorting .ordering .nice-select:after {
  border-color: var(--black-color);
  height: 8px;
  width: 8px;
  margin-top: -5px;
  right: 15px;
}

.coze-grid-sorting .ordering .nice-select:hover {
  border-color: var(--main-color);
  background-color: transparent;
}

.single-events-box {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--white-color);
  margin-bottom: 25px;
}

.single-events-box .image {
  position: relative;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.single-events-box .image .date {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: inline-block;
  background-color: var(--white-color);
  color: var(--optional-color);
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--heading-font-family);
}

.single-events-box .image img {
  border-radius: 15px 15px 0 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-events-box .content {
  padding: 25px 20px;
  position: relative;
  background-color: #f3f3f3;
  border-radius: 0 0 15px 15px;
}

.single-events-box .content h3 {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 22px;
  font-weight: bold;
}

.single-events-box .content h3 a {
  display: inline-block;
}

.single-events-box .content .location {
  display: block;
  color: var(--paragraph-color);
  margin-top: 20px;
  position: relative;
  padding-left: 22px;
  font-size: 14px;
  font-weight: 500;
}

.single-events-box .content .location i {
  color: var(--main-color);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: var(--font-size);
}

.single-events-box:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*================================================
Events Details Area CSS
=================================================*/
.events-details-area {
  background-color: #f9f9f9;
}

.events-details-area .container-fluid {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.events-details-image {
  position: relative;
  margin-bottom: 50px;
}

.events-details-image #timer {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  bottom: 50px;
}

.events-details-image #timer div {
  background: var(--btn-gradient-color);
  color: var(--white-color);
  width: 165px;
  height: 150px;
  z-index: 1;
  border-radius: 10px;
  font-size: 60px;
  font-weight: bold;
  margin-left: 8px;
  margin-right: 8px;
  font-family: var(--heading-font-family);
}

.events-details-image #timer div span {
  display: block;
  margin-top: -6px;
  font-size: var(--font-size);
  font-weight: 500;
  color: var(--black-color);
}

.events-details-header {
  margin-bottom: 25px;
}

.events-details-header .events-list {
  margin-bottom: 0;
  list-style-type: none;
  background-color: var(--white-color);
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #f3f3f3;
}

.events-details-header .events-list li {
  display: inline-block;
  margin-right: 20px;
  color: var(--paragraph-color);
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  font-weight: 500;
}

.events-details-header .events-list li i {
  color: var(--main-color);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
}

.events-details-header .events-list li:last-child {
  margin-right: 0;
}

.events-details-location {
  margin-bottom: 25px;
}

.events-details-location iframe {
  height: 450px;
  width: 100%;
  border: none;
  border-radius: 15px;
}

.events-details-desc h3 {
  margin-bottom: 20px;
  font-size: 22px;
}

.events-details-desc h3:not(:first-child) {
  margin-top: 30px;
}

.events-details-info {
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 25px 30px 30px;
  border: 1px solid #f3f3f3;
}

.events-details-info .info {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
}

.events-details-info .info li {
  border-bottom: 1px solid #f3f3f3;
  color: var(--paragraph-color);
  font-size: var(--font-size);
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
}

.events-details-info .info li span {
  font-weight: 600;
  color: var(--black-color);
  position: relative;
}

.events-details-info .info li.price {
  padding-bottom: 10px;
  color: var(--main-color);
  font-size: 28px;
  font-weight: bold;
}

.events-details-info .info li.price span {
  color: var(--black-color);
  font-size: var(--font-size);
  font-weight: bold;
}

.events-details-info .info li:first-child {
  padding-top: 0;
}

.events-details-info .events-btn-box {
  text-align: center;
  margin-top: 30px;
}

.events-details-info .events-btn-box p {
  margin-top: 20px;
}

.events-details-info .events-btn-box p a {
  color: var(--main-color);
}

.events-details-info .events-btn-box p a:hover {
  text-decoration: underline;
}

.events-details-info .events-share {
  text-align: center;
  margin-top: 30px;
}

.events-details-info .events-share .share-info {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.events-details-info .events-share .share-info span {
  display: inline-block;
  color: var(--main-color);
  line-height: 1;
  font-size: var(--font-size);
  font-weight: bold;
  font-family: var(--heading-font-family);
}

.events-details-info .events-share .share-info .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 12px;
}

.events-details-info .events-share .share-info .social-link li {
  display: inline-block;
  margin-right: 5px;
}

.events-details-info .events-share .share-info .social-link li:last-child {
  margin-right: 0;
}

.events-details-info .events-share .share-info .social-link li a i {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #e1e1e1;
  font-size: var(--font-size);
  color: var(--black-color);
  position: relative;
  border-radius: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.events-details-info .events-share .share-info .social-link li a i:hover {
  background-color: var(--main-color);
  color: var(--white-color);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

/*================================================
Career Area CSS
=================================================*/
.career-item {
  border: 1px solid #f5f5f5;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 25px;
}

.career-item:last-child {
  margin-bottom: 0;
}

.career-item .career-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16.5px;
}

.career-item .career-content h3 a {
  color: var(--black-color);
}

.career-item .career-content h3 a:hover {
  color: var(--main-color);
}

.career-item .career-content p {
  margin-bottom: 18px;
}

.career-item .career-content .list {
  padding: 0;
  margin-bottom: 0;
}

.career-item .career-content .list li {
  font-family: var(--heading-font-family);
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
  color: var(--paragraph-color);
  font-weight: 500;
}

.career-item .career-content .list li:last-child {
  margin-right: 0;
}

.career-item .career-content .list li span {
  color: var(--main-color);
  font-weight: 500;
}

/*================================================
Apply Area CSS
=================================================*/
.apply-area {
  background-image: url(../../public/images/apply-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.apply-form-wrap {
  background-color: var(--white-color);
  padding: 45px;
  border-radius: 15px;
  max-width: 550px;
}

.apply-form-wrap .content {
  margin-bottom: 30px;
}

.apply-form-wrap .content h3 {
  font-size: 22px;
  margin-bottom: 0;
}

.apply-form-wrap .apply-form .form-group {
  margin-bottom: 15px;
}

.apply-form-wrap .apply-form .form-group .form-control {
  height: 55px;
  color: var(--paragraph-color);
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 400;
}

.apply-form-wrap .apply-form .form-group .form-control::-webkit-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.apply-form-wrap .apply-form .form-group .form-control:-ms-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.apply-form-wrap .apply-form .form-group .form-control::-ms-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.apply-form-wrap .apply-form .form-group .form-control::placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.apply-form-wrap .apply-form .form-group .form-control:focus {
  border-color: var(--main-color);
  background-color: transparent;
}

.apply-form-wrap .apply-form .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.apply-form-wrap .apply-form .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.apply-form-wrap .apply-form .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.apply-form-wrap .apply-form .form-group .form-control:focus::placeholder {
  color: transparent;
}

.apply-form-wrap .apply-form .form-group textarea.form-control {
  height: 100px;
  padding-top: 15px;
}

.apply-form-wrap .apply-form .form-group .nice-select {
  display: inline-block;
  width: 100%;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0 0 0 20px;
  height: 55px;
  line-height: 55px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 10px;
}

.apply-form-wrap .apply-form .form-group .nice-select .list {
  background-color: var(--white-color);
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.apply-form-wrap .apply-form .form-group .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: var(--black-color);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 15px;
  padding-right: 25px;
  font-weight: 400;
}

.apply-form-wrap .apply-form .form-group .nice-select .list .option.selected.focus {
  color: var(--main-color);
  background-color: var(--white-color);
}

.apply-form-wrap .apply-form .form-group .nice-select .list .option:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.apply-form-wrap .apply-form .form-group .nice-select:after {
  border-color: var(--paragraph-color);
  height: 8px;
  width: 8px;
  margin-top: -5px;
  right: 20px;
}

.apply-form-wrap .apply-form .form-group .nice-select:hover {
  border-color: var(--main-color);
  background-color: transparent;
}

.apply-form-wrap .apply-form .default-btn {
  border: none;
  width: 100%;
  padding: 20px 25px;
}

.apply-form-wrap .apply-form .default-btn::before {
  width: 850px;
  height: 850px;
}

/*================================================
Career Details Area CSS
=================================================*/
.career-details-desc h3 {
  font-size: 35px;
  margin-bottom: 0;
}

.career-details-desc .career-content {
  margin-top: 35px;
}

.career-details-desc .career-content h4 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: bold;
}

.career-details-desc .career-content .list {
  padding: 0;
  margin-bottom: 0;
}

.career-details-desc .career-content .list li {
  list-style-type: none;
  color: var(--paragraph-color);
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}

.career-details-desc .career-content .list li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 7.5px;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: var(--main-color);
  border-radius: 50px;
}

.career-details-desc .career-content .list li:last-child {
  margin-bottom: 0;
}

.career-details-desc .career-details-image {
  margin-top: 35px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
}

.career-details-desc .career-details-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.career-details-desc .career-details-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.career-details-desc .career-details-image .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 90px;
  background-color: var(--black-color);
  border-radius: 50%;
  color: var(--optional-color);
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 45px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #fb64bf;
}

.career-details-desc .career-details-image .video-btn i {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.career-details-desc .career-details-image .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fb64bf;
  -webkit-animation: ripple 2s linear infinite;
          animation: ripple 2s linear infinite;
}

.career-details-desc .career-details-image .video-btn:hover {
  background-color: var(--optional-color);
}

.career-details-desc .career-details-image .video-btn:hover i {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.career-details-info {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 30px;
}

.career-details-info h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 15px;
}

.career-details-info h3::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: var(--optional-color);
  height: 2px;
  width: 50px;
}

.career-details-info .list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
  display: inline-block;
}

.career-details-info .list li {
  list-style-type: none;
  margin-bottom: 20px;
  font-family: var(--heading-font-family);
  font-weight: 400;
  color: var(--paragraph-color);
}

.career-details-info .list li:last-child {
  margin-bottom: 0;
}

.career-details-info .list li span {
  color: var(--black-color);
  font-weight: 600;
  font-family: var(--heading-font-family);
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
  padding: 35px;
  border-radius: 15px;
  background-color: var(--white-color);
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
          box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  max-width: 650px;
  margin: auto;
  border: 1px solid #e1e1e1;
}

.login-form h2 {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}

.login-form form .form-group {
  margin-bottom: 25px;
}

.login-form form .form-group label {
  display: block;
  margin-bottom: 8px;
  color: var(--paragraph-color);
  font-weight: 500;
  font-size: var(--font-size);
}

.login-form form .form-group .form-control {
  height: 55px;
  padding: 20px;
  line-height: initial;
  color: var(--btn-gradient-color);
  background-color: transparent;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 500;
}

.login-form form .form-group .form-control:focus {
  border: 1px solid --main-color;
  background-color: transparent;
}

.login-form form .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.login-form form .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.login-form form .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.login-form form .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.login-form form .form-check-input:focus {
  border-color: var(--main-color);
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.login-form form .lost-your-password {
  text-align: end;
}

.login-form form .lost-your-password a {
  display: inline-block;
  position: relative;
  font-weight: 500;
  font-size: 15px;
  color: var(--paragraph-color);
}

.login-form form .lost-your-password a::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: #eeeeee;
}

.login-form form .lost-your-password a::after {
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: 0;
  content: "";
  background-color: var(--main-color);
}

.login-form form .lost-your-password a:hover::before {
  width: 0;
}

.login-form form .lost-your-password a:hover::after {
  width: 100%;
}

.login-form form .default-btn {
  margin-top: 25px;
  padding: 15px 35px;
  width: 100%;
  border: none;
}

.login-form form .default-btn::before {
  width: 850px;
  height: 850px;
}

.register-form {
  padding: 35px;
  border-radius: 15px;
  background-color: var(--white-color);
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
          box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  max-width: 650px;
  margin: auto;
  border: 1px solid #e1e1e1;
}

.register-form h2 {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}

.register-form form .form-group {
  margin-bottom: 25px;
}

.register-form form .form-group label {
  display: block;
  margin-bottom: 8px;
  color: var(--paragraph-color);
  font-weight: 500;
  font-size: var(--font-size);
}

.register-form form .form-group .form-control {
  height: 55px;
  padding: 20px;
  line-height: initial;
  color: var(--black-color);
  background-color: transparent;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 500;
}

.register-form form .form-group .form-control:focus {
  border: 1px solid --main-color;
  background-color: transparent;
}

.register-form form .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.register-form form .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.register-form form .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.register-form form .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.register-form form .description {
  font-style: italic;
  font-size: 14px;
  margin-top: -5px;
  margin-bottom: 15px;
}

.register-form form .default-btn {
  margin-top: 10px;
  padding: 15px 35px;
  width: 100%;
  border: none;
}

.register-form form .default-btn::before {
  width: 850px;
  height: 850px;
}

/*================================================
Membership Levels Area CSS
=================================================*/
.membership-levels-table .table {
  margin-bottom: 0;
  border: 1px solid #f5f5f5;
}

.membership-levels-table .table thead th {
  vertical-align: middle;
  background-color: var(--main-color);
  white-space: nowrap;
  padding: 20px;
  text-align: center;
  color: var(--white-color);
  border-left: 1px solid var(--white-color);
  border-right: 1px solid var(--white-color);
  border-bottom: none;
}

.membership-levels-table .table thead th .desc-1 {
  color: var(--white-color);
  margin-bottom: 0;
  font-size: 30px;
  font-weight: bold;
  display: block;
  font-family: var(--heading-font-family);
  line-height: 1;
}

.membership-levels-table .table thead th .desc-2 {
  color: var(--white-color);
  margin-top: 18px;
  margin-bottom: 10px;
  font-size: var(--font-size);
  font-weight: 400;
  display: block;
}

.membership-levels-table .table thead th .desc-3 {
  color: var(--white-color);
  font-size: var(--font-size);
  font-weight: 400;
  display: block;
}

.membership-levels-table .table thead th:first-child {
  border-left: none;
}

.membership-levels-table .table thead th:last-child {
  border-right: none;
}

.membership-levels-table .table tbody tr td {
  padding: 20px 25px;
  white-space: nowrap;
  vertical-align: middle;
  border: none;
  text-align: center;
  font-size: var(--font-size);
  font-weight: 600;
}

.membership-levels-table .table tbody tr td:first-child {
  text-align: start;
}

.membership-levels-table .table tbody tr td.item-check i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100%;
  background-color: #0eb582;
  color: var(--white-color);
  display: block;
  margin: 0 auto;
}

.membership-levels-table .table tbody tr td.item-none i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100%;
  background-color: var(--optional-color);
  color: var(--white-color);
  display: block;
  margin: 0 auto;
}

.membership-levels-table .table tbody tr td .select-btn {
  display: inline-block;
  color: var(--black-color);
  background-color: #f5f5f5;
  padding: 15px 35px;
  border-radius: 10px;
  font-size: var(--font-size);
  font-weight: 600;
}

.membership-levels-table .table tbody tr td .select-btn:hover {
  color: var(--white-color);
  background-color: var(--main-color);
}

.membership-levels-table .table tbody:not(:first-child) {
  border-top: unset;
}

/*================================================
Purchase Guide Area CSS
=================================================*/
.purchase-guide-content .guide-image {
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 30px;
}

.purchase-guide-content .guide-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.purchase-guide-content .guide-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.purchase-guide-content h3 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bold;
}

.purchase-guide-content .blockquote,
.purchase-guide-content blockquote {
  margin-top: 25px;
  margin-bottom: 30px;
  background-color: #e6f2f5;
  text-align: left !important;
  padding: 25px !important;
  border-radius: 10px;
}

.purchase-guide-content .blockquote p,
.purchase-guide-content blockquote p {
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 15px !important;
  font-weight: 500;
}

.purchase-guide-content .blockquote::after,
.purchase-guide-content blockquote::after {
  display: none;
}

.purchase-guide-content .blockquote::before,
.purchase-guide-content blockquote::before {
  display: none;
}

.purchase-guide-content ol,
.purchase-guide-content ul {
  margin-top: 20px;
}

.purchase-guide-content ol li,
.purchase-guide-content ul li {
  margin-bottom: 10px;
  color: var(--paragraph-color);
  line-height: 1.8;
  font-weight: 500;
  font-size: 15px;
}

/*================================================
Terms of Service Area CSS
=================================================*/
.terms-of-service-content .terms-of-service-image {
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 30px;
}

.terms-of-service-content .terms-of-service-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.terms-of-service-content .terms-of-service-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.terms-of-service-content h3 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bold;
}

.terms-of-service-content .blockquote,
.terms-of-service-content blockquote {
  margin-top: 25px;
  margin-bottom: 30px;
  background-color: #e6f2f5;
  text-align: left !important;
  padding: 25px !important;
  border-radius: 10px;
}

.terms-of-service-content .blockquote p,
.terms-of-service-content blockquote p {
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 15px !important;
  font-weight: 500;
}

.terms-of-service-content .blockquote::after,
.terms-of-service-content blockquote::after {
  display: none;
}

.terms-of-service-content .blockquote::before,
.terms-of-service-content blockquote::before {
  display: none;
}

.terms-of-service-content ol,
.terms-of-service-content ul {
  margin-top: 20px;
}

.terms-of-service-content ol li,
.terms-of-service-content ul li {
  margin-bottom: 10px;
  color: var(--paragraph-color);
  line-height: 1.8;
  font-weight: 500;
  font-size: 15px;
}

/*================================================
Privacy Policy Area CSS
=================================================*/
.privacy-policy-content .privacy-policy-image {
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 30px;
}

.privacy-policy-content .privacy-policy-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.privacy-policy-content .privacy-policy-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.privacy-policy-content h3 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bold;
}

.privacy-policy-content .blockquote,
.privacy-policy-content blockquote {
  margin-top: 25px;
  margin-bottom: 30px;
  background-color: #e6f2f5;
  text-align: left !important;
  padding: 25px !important;
  border-radius: 10px;
}

.privacy-policy-content .blockquote p,
.privacy-policy-content blockquote p {
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 15px !important;
  font-weight: 500;
}

.privacy-policy-content .blockquote::after,
.privacy-policy-content blockquote::after {
  display: none;
}

.privacy-policy-content .blockquote::before,
.privacy-policy-content blockquote::before {
  display: none;
}

.privacy-policy-content ol,
.privacy-policy-content ul {
  margin-top: 20px;
}

.privacy-policy-content ol li,
.privacy-policy-content ul li {
  margin-bottom: 10px;
  color: var(--paragraph-color);
  line-height: 1.8;
  font-weight: 500;
  font-size: 15px;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-content {
  text-align: center;
}

.error-content h3 {
  margin-top: 35px;
  margin-bottom: 20px;
  font-size: 30px;
}

.error-content p {
  max-width: 550px;
  margin: auto;
}

.error-content .default-btn {
  margin-top: 25px;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  background-image: url(../../public/images/coming-soon-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 120px;
  padding-bottom: 120px;
}

.coming-soon-content {
  max-width: 650px;
  background-color: var(--white-color);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 35px;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
          box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  margin: 0 auto 0 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 1px solid var(--white-color);
}

.coming-soon-content .logo {
  display: inline-block;
}

.coming-soon-content h2 {
  font-size: 35px;
  margin-top: 30px;
  margin-bottom: 0;
}

.coming-soon-content #timer {
  margin-top: 35px;
}

.coming-soon-content #timer div {
  background: var(--btn-gradient-color);
  color: var(--white-color);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  font-size: 40px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content #timer div:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.coming-soon-content #timer div span {
  display: block;
  margin-top: -2px;
  font-size: 15px;
  font-weight: 500;
}

.coming-soon-content form {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}

.coming-soon-content form .form-group {
  margin-bottom: 15px;
}

.coming-soon-content form .form-group .form-control {
  height: 55px;
  padding: 15px 20px;
  line-height: initial;
  color: var(--paragraph-color);
  background-color: var(--white-color);
  border: 1px solid #e5e7ec;
  -webkit-box-shadow: -12px 8px 25px rgba(217, 35, 12, 0.03);
          box-shadow: -12px 8px 25px rgba(217, 35, 12, 0.03);
  border-radius: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--font-size);
  font-weight: 400;
}

.coming-soon-content form .form-group .form-control::-webkit-input-placeholder {
  color: var(--paragraph-color);
}

.coming-soon-content form .form-group .form-control:-ms-input-placeholder {
  color: var(--paragraph-color);
}

.coming-soon-content form .form-group .form-control::-ms-input-placeholder {
  color: var(--paragraph-color);
}

.coming-soon-content form .form-group .form-control::placeholder {
  color: var(--paragraph-color);
}

.coming-soon-content form .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content form .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content form .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content form .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content form .default-btn {
  width: 100%;
  border: none;
  border-radius: 20px;
}

.coming-soon-content form .default-btn::before {
  width: 850px;
  height: 850px;
}

.coming-soon-content form .validation-danger {
  margin-top: 15px;
  color: red;
}

.coming-soon-content form .validation-success {
  margin-top: 15px;
}

.coming-soon-content:hover {
  border: 1px solid var(--main-color);
}

/*================================================
Courses Area CSS
=================================================*/
.single-courses-box {
  margin-bottom: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-courses-box .courses-image {
  border-radius: 10px 10px 0 0;
  position: relative;
  z-index: 1;
}

.single-courses-box .courses-image img {
  border-radius: 10px 10px 0 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-courses-box .courses-image .price {
  display: inline-block;
  background: var(--btn-gradient-color);
  color: var(--white-color);
  width: 65px;
  height: 65px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  bottom: -32.5px;
  text-align: center;
  line-height: 65px;
  font-size: 25px;
  font-weight: 500;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-courses-box .courses-content {
  border-radius: 0 0 10px 10px;
  padding: 25px;
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
          box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
}

.single-courses-box .courses-content .course-author {
  margin-bottom: 20px;
}

.single-courses-box .courses-content .course-author img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.single-courses-box .courses-content .course-author span {
  color: var(--optional-color);
  font-size: var(--font-size);
  font-weight: 600;
  font-family: var(--heading-font-family);
}

.single-courses-box .courses-content h3 {
  margin-bottom: 15px;
  line-height: 1.5;
  font-size: 22px;
  font-weight: bold;
}

.single-courses-box .courses-content h3 a {
  display: inline-block;
}

.single-courses-box .courses-content .courses-box-footer {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: 25px;
}

.single-courses-box .courses-content .courses-box-footer li {
  color: var(--paragraph-color);
  font-size: 15px;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
  padding-right: 8px;
}

.single-courses-box .courses-content .courses-box-footer li i {
  color: var(--main-color);
  position: absolute;
  left: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: var(--font-size);
}

.single-courses-box:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.single-courses-box:hover .courses-image .price {
  -webkit-animation: bounce 1s;
          animation: bounce 1s;
}

/*================================================
Courses Details Area CSS
=================================================*/
.courses-details-area {
  background-color: #f5f5f5;
}

.courses-details-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.courses-details-image {
  position: relative;
}

.courses-details-image img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-image .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 90px;
  background-color: var(--main-color);
  border-radius: 50px;
  color: var(--white-color);
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
}

.courses-details-image .video-btn i {
  font-size: 55px;
  position: relative;
  top: 12px;
  left: 2px;
}

.courses-details-image .video-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50px;
  border: 1px solid var(--main-color);
  -webkit-animation: ripple 2s linear 1s infinite;
          animation: ripple 2s linear 1s infinite;
}

.courses-details-image .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50px;
  border: 1px solid var(--main-color);
  -webkit-animation: ripple 2s linear infinite;
          animation: ripple 2s linear infinite;
}

.courses-details-image .video-btn:hover {
  background-color: var(--optional-color);
  color: var(--white-color);
}

.courses-details-desc {
  margin-top: 50px;
}

.courses-details-desc .nav {
  margin-bottom: 0;
  list-style-type: none;
  background-color: var(--white-color);
  border: none;
  border-radius: 15px;
}

.courses-details-desc .nav .nav-item {
  margin-bottom: 0;
  margin-right: 20px;
}

.courses-details-desc .nav .nav-item .nav-link {
  border-radius: 0;
  border: none;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 30px;
  border-radius: 15px;
  font-size: var(--font-size);
  font-weight: bold;
  color: var(--black-color);
}

.courses-details-desc .nav .nav-item .nav-link:hover, .courses-details-desc .nav .nav-item .nav-link.active {
  color: var(--white-color);
  background-color: var(--main-color);
}

.courses-details-desc .nav .nav-item:last-child {
  margin-right: 0;
}

.courses-details-desc .tab-content {
  margin-top: 45px;
}

.courses-details-desc .tab-content .courses-overview h3 {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}

.courses-details-desc .tab-content .courses-overview h3:not(:first-child) {
  margin-top: 25px;
}

.courses-details-desc .tab-content .courses-curriculum {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 30px;
}

.courses-details-desc .tab-content .courses-curriculum h3 {
  margin-bottom: 18px;
  font-size: 20px;
  font-weight: bold;
}

.courses-details-desc .tab-content .courses-curriculum h3:not(:first-child) {
  margin-top: 20px;
}

.courses-details-desc .tab-content .courses-curriculum ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.courses-details-desc .tab-content .courses-curriculum ul li {
  display: block;
}

.courses-details-desc .tab-content .courses-curriculum ul li a {
  background-color: #f8f9f8;
  color: var(--black-color);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: -30px;
  margin-right: -30px;
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-name {
  font-size: var(--font-size);
  font-weight: 500;
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-name:hover {
  color: var(--main-color);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta {
  text-align: right;
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta .questions {
  display: inline-block;
  background: var(--main-color);
  color: var(--white-color);
  border-radius: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta .questions:hover {
  background-color: var(--optional-color);
  color: var(--white-color);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta .duration {
  display: inline-block;
  background: var(--optional-color);
  color: var(--white-color);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta .duration:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta .status {
  display: inline-block;
  background: var(--black-color);
  color: var(--white-color);
  border-radius: 5px;
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta .status:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta .status.locked {
  color: var(--black-color);
  background-color: transparent;
  padding: 0;
  margin-left: 8px;
  border-radius: 0;
  position: relative;
  top: 1px;
  font-size: 18px;
  font-weight: normal;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-desc .tab-content .courses-curriculum ul li a .courses-meta .status.locked:hover {
  color: var(--main-color);
}

.courses-details-desc .tab-content .courses-curriculum ul li a:hover {
  color: var(--main-color);
}

.courses-details-desc .tab-content .courses-instructor .instructor-image img {
  border-radius: 15px;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content {
  padding-left: 15px;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content h3 {
  font-size: 20px;
  margin-bottom: 18px;
  font-weight: bold;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content span {
  font-weight: 500;
  color: var(--main-color);
  display: inline-block;
  margin-bottom: 15px;
  font-size: 15px;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content p {
  margin-bottom: 20px;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content .social-link {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 25px;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content .social-link li {
  list-style-type: none;
  display: inline-block;
  margin-right: 8px;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content .social-link li:last-child {
  margin-right: 0;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content .social-link li a i {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: var(--btn-gradient-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--white-color);
  border-radius: 50px;
  font-size: 18px;
}

.courses-details-desc .tab-content .courses-instructor .instructor-content .social-link li a i:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.courses-details-desc .tab-content .courses-reviews h3 {
  margin-bottom: 15px;
  display: inline-block;
  font-size: 22px;
  font-weight: bold;
}

.courses-details-desc .tab-content .courses-reviews .rating span {
  font-size: var(--font-size);
  color: #cecfd2;
}

.courses-details-desc .tab-content .courses-reviews .rating span.checked {
  color: orange;
}

.courses-details-desc .tab-content .courses-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.courses-details-desc .tab-content .courses-reviews .rating-count span {
  display: block;
  font-size: 15px;
  color: var(--paragraph-color);
  font-weight: 500;
}

.courses-details-desc .tab-content .courses-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.courses-details-desc .tab-content .courses-reviews .side {
  float: left;
  width: 8%;
  margin-top: 10px;
  padding-left: 0;
  padding-right: 0;
}

.courses-details-desc .tab-content .courses-reviews .side div {
  font-size: var(--font-size);
  font-weight: 600;
  font-family: var(--heading-font-family);
}

.courses-details-desc .tab-content .courses-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.courses-details-desc .tab-content .courses-reviews .right {
  text-align: end;
}

.courses-details-desc .tab-content .courses-reviews .bar-container {
  width: 100%;
  background-color: var(--white-color);
  text-align: center;
  border-radius: 5px;
}

.courses-details-desc .tab-content .courses-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4caf50;
  border-radius: 5px;
}

.courses-details-desc .tab-content .courses-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196f3;
  border-radius: 5px;
  border-radius: 5px;
}

.courses-details-desc .tab-content .courses-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.courses-details-desc .tab-content .courses-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.courses-details-desc .tab-content .courses-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.courses-details-desc .tab-content .courses-review-comments {
  margin-top: 40px;
}

.courses-details-desc .tab-content .courses-review-comments h3 {
  border-bottom: 1px solid var(--white-color);
  padding-bottom: 12px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.courses-details-desc .tab-content .courses-review-comments .user-review {
  border-bottom: 1px solid var(--white-color);
  padding: 20px 0 20px 110px;
  position: relative;
}

.courses-details-desc .tab-content .courses-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 20px;
}

.courses-details-desc .tab-content .courses-review-comments .user-review .sub-comment {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 15px;
  color: var(--main-color);
}

.courses-details-desc .tab-content .courses-review-comments .user-review p {
  font-size: 15px;
}

.courses-details-desc .tab-content .courses-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.courses-details-desc .tab-content .courses-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.courses-details-desc .tab-content .courses-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 15px;
  display: inline-block;
  margin-right: -2px;
}

.courses-details-desc .tab-content .courses-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.courses-details-desc .tab-content .courses-review-comments .user-review .review-rating span {
  color: var(--black-color);
  position: relative;
  top: -2px;
  font-weight: 600;
  margin-left: 5px;
}

.courses-details-info {
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 12px 12px 35px 12px;
  margin-top: 50px;
  border: 1px solid #f3f3f3;
}

.courses-details-info .image {
  text-align: center;
  position: relative;
}

.courses-details-info .image img {
  border-radius: 15px;
}

.courses-details-info .image .video-btn {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  background-color: var(--white-color);
  border-radius: 50%;
  color: var(--black-color);
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-info .image .video-btn i {
  font-size: 50px;
  position: relative;
  top: 10px;
  left: 2px;
  color: var(--main-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-info .image .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid var(--white-color);
  -webkit-animation: ripple 2s linear infinite;
          animation: ripple 2s linear infinite;
}

.courses-details-info .image .video-btn:hover {
  background-color: var(--main-color);
}

.courses-details-info .image .video-btn:hover i {
  color: var(--white-color);
}

.courses-details-info .info {
  margin-bottom: 0;
  list-style-type: none;
  padding: 30px 20px;
}

.courses-details-info .info li {
  border-bottom: 1px solid #e5e5e7;
  color: var(--paragraph-color);
  font-size: var(--font-size);
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
}

.courses-details-info .info li span {
  font-weight: 600;
  color: var(--black-color);
}

.courses-details-info .info li.price {
  padding-bottom: 10px;
  color: var(--main-color);
  font-size: 28px;
  font-weight: bold;
  font-family: var(--heading-font-family);
}

.courses-details-info .info li.price span {
  color: var(--black-color);
  font-size: var(--font-size);
  font-weight: bold;
}

.courses-details-info .info li:first-child {
  padding-top: 0;
}

.courses-details-info .courses-btn-box {
  text-align: center;
}

.courses-details-info .courses-share {
  text-align: center;
  margin-top: 25px;
}

.courses-details-info .courses-share .share-info {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.courses-details-info .courses-share .share-info span {
  display: inline-block;
  color: var(--main-color);
  line-height: 1;
  font-size: var(--font-size);
  font-weight: 500;
}

.courses-details-info .courses-share .share-info .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 12px;
}

.courses-details-info .courses-share .share-info .social-link li {
  display: inline-block;
  margin-right: 5px;
}

.courses-details-info .courses-share .share-info .social-link li:last-child {
  margin-right: 0;
}

.courses-details-info .courses-share .share-info .social-link li a i {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: var(--btn-gradient-color);
  font-size: var(--font-size);
  color: var(--white-color);
  position: relative;
  border-radius: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.courses-details-info .courses-share .share-info .social-link li a i:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

/*================================================
Products Area CSS
=================================================*/
.products-grid-sorting {
  margin-bottom: 30px;
}

.products-grid-sorting .ordering {
  text-align: end;
}

.products-grid-sorting .ordering label {
  display: inline-block;
  margin-bottom: 0;
  color: var(--paragraph-color);
  margin-right: 5px;
  font-weight: 600;
  font-size: 15px;
}

.products-grid-sorting .ordering .nice-select {
  display: inline-block;
  width: 100%;
  background: #f2f1f3;
  border-color: #f2f1f3;
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0 0 0 15px;
  height: 70px;
  line-height: 70px;
  font-weight: 400;
  border-radius: 15px;
}

.products-grid-sorting .ordering .nice-select .list {
  background-color: var(--white-color);
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.products-grid-sorting .ordering .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: var(--black-color);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 15px;
  padding-right: 25px;
  font-weight: 600;
}

.products-grid-sorting .ordering .nice-select .list .option.selected.focus {
  color: var(--main-color);
  background-color: var(--white-color);
}

.products-grid-sorting .ordering .nice-select .list .option:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.products-grid-sorting .ordering .nice-select:after {
  border-color: var(--black-color);
  height: 8px;
  width: 8px;
  margin-top: -5px;
  right: 15px;
}

.products-grid-sorting .ordering .nice-select:hover {
  border-color: var(--main-color);
  background-color: transparent;
}

.products-grid-sorting .ordering form {
  position: relative;
}

.products-grid-sorting .ordering form .search-field {
  height: 70px;
  color: #9b8dac;
  border: 1px solid #f2f1f3;
  display: block;
  width: 100%;
  border-radius: 15px;
  padding: 15px 20px 15px 70px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 400;
  outline: 0;
  background: #f2f1f3;
}

.products-grid-sorting .ordering form .search-field::-webkit-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-grid-sorting .ordering form .search-field:-ms-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-grid-sorting .ordering form .search-field::-ms-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-grid-sorting .ordering form .search-field::placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-grid-sorting .ordering form .search-field:focus::-webkit-input-placeholder {
  color: transparent;
}

.products-grid-sorting .ordering form .search-field:focus:-ms-input-placeholder {
  color: transparent;
}

.products-grid-sorting .ordering form .search-field:focus::-ms-input-placeholder {
  color: transparent;
}

.products-grid-sorting .ordering form .search-field:focus::placeholder {
  color: transparent;
}

.products-grid-sorting .ordering form button {
  border: none;
  background-color: transparent;
  color: var(--black-color);
  position: absolute;
  left: 32px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 22.8px;
  font-size: 25px;
  line-height: 0;
}

.products-grid-sorting .ordering form button:hover, .products-grid-sorting .ordering form button:focus {
  color: var(--main-color);
}

.single-products-card {
  margin-bottom: 25px;
}

.single-products-card .products-image {
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  text-align: center;
}

.single-products-card .products-image::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  visibility: hidden;
  background: radial-gradient(circle, #fb64bf, #c666ef, #dd62df, #ee61cf, #fb64bf);
  border-radius: 15px;
  z-index: 1;
}

.single-products-card .products-image a {
  display: block;
}

.single-products-card .products-image a img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-card .products-image .heart-line {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 1;
}

.single-products-card .products-image .heart-line a i {
  color: #ff3666;
  font-size: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-card .products-image .heart-fill {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 1;
}

.single-products-card .products-image .heart-fill a i {
  color: #ff3666;
  font-size: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-card .products-image .add-to-cart-btn {
  position: absolute;
  left: -30px;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-card .products-image .add-to-cart-btn .default-btn {
  display: inline-block;
}

.single-products-card .products-image .sale {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 1;
  display: inline-block;
  background-color: #a66bff;
  color: var(--white-color);
  padding: 5px 20px;
  font-size: 15px;
  border-radius: 10px;
  font-weight: 500;
  font-family: var(--heading-font-family);
}

.single-products-card .products-image .new {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 1;
  display: inline-block;
  background-color: #a66bff;
  color: var(--white-color);
  padding: 5px 20px;
  font-size: 15px;
  border-radius: 10px;
  font-weight: 500;
  font-family: var(--heading-font-family);
}

.single-products-card .products-content {
  margin-top: 25px;
}

.single-products-card .products-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}

.single-products-card .products-content h3 a {
  color: var(--black-color);
}

.single-products-card .products-content h3 a:hover {
  color: var(--main-color);
}

.single-products-card .products-content span {
  color: var(--paragraph-color);
  font-size: var(--font-size);
  font-weight: 600;
  display: inline-block;
  margin-top: 15px;
}

.single-products-card:hover .products-image::before {
  opacity: 80%;
  visibility: visible;
}

.single-products-card:hover .products-image a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.single-products-card:hover .products-image .heart-line {
  opacity: 0;
  visibility: hidden;
}

.single-products-card:hover .products-image .heart-fill {
  opacity: 1;
  visibility: visible;
}

.single-products-card:hover .products-image .add-to-cart-btn {
  left: 0;
  opacity: 1;
  visibility: visible;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead tr {
  border: none;
  border-bottom: 1px solid #f2f1f3;
}

.cart-table table thead tr th {
  vertical-align: middle;
  padding: 0 25px 25px 25px;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--heading-font-family);
  border: none;
}

.cart-table table tbody tr {
  border: none;
  border-bottom: 1px solid #f2f1f3;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: var(--paragraph-color);
  white-space: nowrap;
  padding: 25px;
  font-weight: 400;
  font-size: var(--font-size);
  border: none;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
  width: 100px;
  border-radius: 5px;
}

.cart-table table tbody tr td.product-name a {
  font-size: 18px;
  display: inline-block;
  font-weight: 600;
  font-family: var(--heading-font-family);
  color: var(--paragraph-color);
}

.cart-table table tbody tr td.product-name a:hover {
  color: var(--main-color);
}

.cart-table table tbody tr td.product-price .unit-amount {
  font-size: var(--font-size);
  font-weight: 600;
  color: var(--paragraph-color);
}

.cart-table table tbody tr td.product-subtotal .subtotal-amount {
  font-size: var(--font-size);
  font-weight: 600;
  color: var(--paragraph-color);
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: red;
  float: right;
  position: relative;
  top: -1px;
  font-size: 18px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
  text-align: center;
  display: inline-block;
  position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
  display: inline-block;
  background-color: var(--optional-color);
  cursor: pointer;
  color: var(--white-color);
  width: 50px;
  height: 50px;
  line-height: 55px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  border-radius: 10px;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: var(--white-color);
  background-color: var(--main-color);
}

.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 50px;
  color: var(--optional-color);
  outline: 0;
  display: inline-block;
  border: 1px solid #a868fb;
  background-color: var(--white-color);
  text-align: center;
  width: 50px;
  font-size: var(--font-size);
  font-weight: bold;
  border-radius: 10px;
  position: relative;
  top: -4px;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
  color: var(--optional-color);
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
  color: var(--optional-color);
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
  color: var(--optional-color);
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: var(--optional-color);
}

.cart-table table tbody:not(:first-child) {
  border-top: unset;
}

.cart-buttons {
  margin-top: 30px;
  text-align: end;
}

.cart-buttons .shopping-coupon-code {
  position: relative;
  max-width: 550px;
}

.cart-buttons .shopping-coupon-code .form-control {
  display: block;
  height: 60px;
  outline: 0;
  background-color: #f2f1f3;
  border: 1px solid #f2f1f3;
  border-radius: 10px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 15px 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--font-size);
  color: #9b8dac;
}

.cart-buttons .shopping-coupon-code .form-control::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #9b8dac;
}

.cart-buttons .shopping-coupon-code .form-control:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #9b8dac;
}

.cart-buttons .shopping-coupon-code .form-control::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #9b8dac;
}

.cart-buttons .shopping-coupon-code .form-control::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #9b8dac;
}

.cart-buttons .shopping-coupon-code .form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.cart-buttons .shopping-coupon-code .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.cart-buttons .shopping-coupon-code .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.cart-buttons .shopping-coupon-code .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.cart-buttons .shopping-coupon-code .form-control:focus::placeholder {
  color: transparent;
}

.cart-buttons .shopping-coupon-code button {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  border: none;
}

.cart-buttons .shopping-coupon-code button:hover {
  background-color: var(--optional-color);
  color: var(--black-color);
}

.cart-buttons .default-btn {
  padding: 18px 30px;
}

.cart-totals {
  border: 1px dashed #9b8dac;
  padding: 30px;
  max-width: 500px;
  margin: 50px auto 0;
  border-radius: 15px;
  text-align: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.cart-totals h3 {
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: bold;
}

.cart-totals ul {
  padding-left: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border-bottom: 1px solid #f2f1f3;
  color: var(--paragraph-color);
  overflow: hidden;
  font-weight: bold;
  font-size: var(--font-size);
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-family: var(--heading-font-family);
}

.cart-totals ul li:last-child span {
  color: var(--optional-color);
  font-weight: 500;
  font-size: 20px;
}

.cart-totals .default-btn {
  padding: 15px 30px;
}

.cart-totals:hover {
  border: 1px dashed var(--main-color);
}

/*================================================
Wishlist Area CSS
=================================================*/
.wishlist-table table {
  margin-bottom: 0;
}

.wishlist-table table thead tr {
  border: none;
  border-bottom: 1px solid #f2f1f3;
}

.wishlist-table table thead tr th {
  vertical-align: middle;
  padding: 0 25px 25px 25px;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--heading-font-family);
  border: none;
}

.wishlist-table table tbody tr {
  border: none;
  border-bottom: 1px solid #f2f1f3;
}

.wishlist-table table tbody tr td {
  vertical-align: middle;
  color: var(--paragraph-color);
  white-space: nowrap;
  padding: 25px;
  font-weight: 400;
  font-size: var(--font-size);
  border: none;
}

.wishlist-table table tbody tr td.product-thumbnail a {
  display: block;
}

.wishlist-table table tbody tr td.product-thumbnail a img {
  width: 100px;
  border-radius: 5px;
}

.wishlist-table table tbody tr td.product-name a {
  font-size: 18px;
  display: inline-block;
  font-weight: 600;
  font-family: var(--heading-font-family);
  color: var(--paragraph-color);
}

.wishlist-table table tbody tr td.product-name a:hover {
  color: var(--main-color);
}

.wishlist-table table tbody tr td.product-price .unit-amount {
  font-size: var(--font-size);
  font-weight: 600;
  color: var(--paragraph-color);
}

.wishlist-table table tbody tr td.instock {
  color: #05ce69;
  font-size: var(--font-size);
  font-weight: 600;
}

.wishlist-table table tbody tr td.remove a i {
  color: #ff3666;
  font-size: 22px;
}

.wishlist-table table tbody:not(:first-child) {
  border-top: unset;
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  background-color: #f5f5f5;
  padding: 15px 20px;
  position: relative;
  margin-bottom: 40px;
  border-radius: 15px;
}

.user-actions i {
  margin-right: 5px;
  font-size: 20px;
  position: relative;
  top: 3.5px;
  color: var(--paragraph-color);
}

.user-actions span {
  letter-spacing: 1px;
  display: inline-block;
  color: var(--paragraph-color);
  font-size: var(--font-size);
  font-weight: 600;
  font-family: var(--heading-font-family);
}

.user-actions span a {
  display: inline-block;
  color: var(--paragraph-color);
}

.user-actions span a:hover {
  color: var(--optional-color);
}

.billing-details .title {
  margin-bottom: 30px;
  position: relative;
  font-size: 22px;
  font-weight: bold;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: var(--font-size);
  color: var(--paragraph-color);
  font-family: var(--heading-font-family);
}

.billing-details .form-group label .required {
  color: red;
}

.billing-details .form-group .form-control {
  height: 55px;
  padding: 15px 20px;
  line-height: initial;
  color: var(--paragraph-color);
  border: 1px solid #eeeeee;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-radius: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 500;
}

.billing-details .form-group .form-control::-webkit-input-placeholder {
  color: var(--paragraph-color);
}

.billing-details .form-group .form-control:-ms-input-placeholder {
  color: var(--paragraph-color);
}

.billing-details .form-group .form-control::-ms-input-placeholder {
  color: var(--paragraph-color);
}

.billing-details .form-group .form-control::placeholder {
  color: var(--paragraph-color);
}

.billing-details .form-group .form-control:focus {
  border: 1px solid var(--main-color);
}

.billing-details .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.billing-details .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.billing-details .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.billing-details .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.billing-details .form-group textarea.form-control {
  min-height: 115px;
}

.billing-details .form-group .nice-select {
  float: unset;
  height: 55px !important;
  line-height: 55px;
  color: var(--paragraph-color);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
}

.billing-details .form-group .nice-select .list {
  background: var(--btn-gradient-color);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.billing-details .form-group .nice-select .list .option {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 20px;
  padding-right: 20px;
  color: var(--black-color);
  font-weight: 500;
}

.billing-details .form-group .nice-select .list .option:hover {
  background-color: var(--main-color) !important;
  color: var(--white-color);
}

.billing-details .form-group .nice-select .list .option.selected {
  background-color: transparent;
  font-weight: 400;
}

.billing-details .form-group .nice-select:after {
  right: 20px;
  width: 8px;
  height: 8px;
  margin-top: -5.5px;
}

.billing-details .form-group .form-check {
  margin-bottom: 0;
}

.billing-details .form-group .form-check .form-check-input {
  margin-top: 2.8px;
}

.billing-details .form-group .form-check .form-check-input:focus {
  outline: 0;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.billing-details .form-group .form-check label {
  margin-bottom: 0;
}

.billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}

.order-details .title {
  margin-bottom: 30px;
  position: relative;
  font-size: 22px;
  font-weight: bold;
}

.order-details .order-table table {
  margin-bottom: 0;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border: 1px solid rgba(187, 187, 187, 0.15);
  padding: 14px 20px 10px;
  color: var(--paragraph-color);
  white-space: nowrap;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: var(--font-size);
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: var(--paragraph-color);
  white-space: nowrap;
  border: 1px solid rgba(187, 187, 187, 0.15);
  font-size: 15px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 12px;
}

.order-details .order-table table tbody tr td.product-name a {
  display: inline-block;
  color: var(--paragraph-color);
}

.order-details .order-table table tbody tr td.product-name a:hover {
  color: var(--main-color);
}

.order-details .order-table table tbody tr td.order-subtotal span, .order-details .order-table table tbody tr td.order-shipping span, .order-details .order-table table tbody tr td.total-price span {
  color: var(--paragraph-color);
  font-weight: 500;
  font-size: var(--font-size);
}

.order-details .order-table table tbody tr td.shipping-price, .order-details .order-table table tbody tr td.order-subtotal-price, .order-details .order-table table tbody tr td.product-subtotal {
  color: var(--paragraph-color);
  font-weight: 500;
  font-size: var(--font-size);
}

.order-details .order-table table tbody:not(:first-child) {
  border-top: unset;
}

.order-details .payment-box {
  background-color: #f5f5f5;
  margin-top: 30px;
  padding: 30px;
  border-radius: 15px;
}

.order-details .payment-box .payment-method p {
  font-size: 15px;
}

.order-details .payment-box .payment-method p [type="radio"]:checked, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label {
  padding-left: 25.8px;
  cursor: pointer;
  display: block;
  color: var(--paragraph-color);
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: var(--font-size);
  font-family: var(--heading-font-family);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::before, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: var(--white-color);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::after, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--main-color);
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.order-details .payment-box .default-btn {
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
  -webkit-transform: unset;
          transform: unset;
  border-radius: 50px;
  padding: 15px 30px;
}

.order-details .payment-box .default-btn::before {
  width: 750px;
  height: 750px;
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.products-details-image img {
  border-radius: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-image .heart-line {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 1;
}

.products-details-image .heart-line a i {
  color: #ff3666;
  font-size: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-image .heart-fill {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 1;
}

.products-details-image .heart-fill a i {
  color: #ff3666;
  font-size: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-image .sale {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  display: inline-block;
  background-color: #a66bff;
  color: var(--white-color);
  padding: 5px 20px;
  font-size: 15px;
  border-radius: 10px;
  font-weight: 500;
  font-family: var(--heading-font-family);
}

.products-details-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.products-details-image:hover .heart-line {
  opacity: 0;
  visibility: hidden;
}

.products-details-image:hover .heart-fill {
  opacity: 1;
  visibility: visible;
}

.products-details-desc {
  padding-left: 25px;
}

.products-details-desc h3 {
  margin-bottom: 20px;
  font-size: 35px;
  font-weight: bold;
}

.products-details-desc .price {
  margin-bottom: 15px;
  color: var(--paragraph-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}

.products-details-desc .price .old-price {
  text-decoration: line-through;
  color: var(--optional-color);
  font-weight: 600;
  position: relative;
  top: -1px;
}

.products-details-desc p {
  margin-bottom: 0;
  color: var(--paragraph-color);
}

.products-details-desc .products-meta {
  margin-top: 25px;
}

.products-details-desc .products-meta span {
  display: block;
  color: var(--paragraph-color);
  margin-bottom: 20px;
  font-weight: 600;
  font-size: var(--font-size);
}

.products-details-desc .products-meta span:last-child {
  margin-bottom: 0;
}

.products-details-desc .products-meta span span {
  display: inline-block;
  margin-bottom: 0;
}

.products-details-desc .products-meta span span.sku {
  color: var(--optional-color);
}

.products-details-desc .products-meta span span.in-stock {
  color: #05ce69;
}

.products-details-desc .products-meta span a {
  display: inline-block;
  color: var(--paragraph-color);
}

.products-details-desc .products-meta span a:hover, .products-details-desc .products-meta span a:focus {
  color: var(--main-color);
}

.products-details-desc .products-add-to-cart {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #f2f1f3;
}

.products-details-desc .products-add-to-cart .input-counter {
  text-align: center;
  display: inline-block;
  position: relative;
}

.products-details-desc .products-add-to-cart .input-counter span {
  display: inline-block;
  background-color: var(--optional-color);
  cursor: pointer;
  color: var(--white-color);
  width: 55px;
  height: 55px;
  line-height: 60px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  border-radius: 10px;
}

.products-details-desc .products-add-to-cart .input-counter span:hover {
  color: var(--white-color);
  background-color: var(--main-color);
}

.products-details-desc .products-add-to-cart .input-counter input {
  height: 55px;
  color: var(--optional-color);
  outline: 0;
  display: inline-block;
  border: 1px solid #a868fb;
  background-color: var(--white-color);
  text-align: center;
  width: 55px;
  font-size: var(--font-size);
  font-weight: bold;
  border-radius: 10px;
  position: relative;
  top: -4px;
}

.products-details-desc .products-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: var(--optional-color);
}

.products-details-desc .products-add-to-cart .input-counter input:-ms-input-placeholder {
  color: var(--optional-color);
}

.products-details-desc .products-add-to-cart .input-counter input::-ms-input-placeholder {
  color: var(--optional-color);
}

.products-details-desc .products-add-to-cart .input-counter input::placeholder {
  color: var(--optional-color);
}

.products-details-desc .products-add-to-cart .default-btn {
  padding: 15.5px 30px;
  border: none;
  margin-left: 10px;
  position: relative;
  top: -4px;
}

.products-details-tabs {
  margin-top: 50px;
}

.products-details-tabs .nav {
  margin-bottom: 35px;
  list-style-type: none;
  display: block;
  border-bottom: none;
  text-align: center;
}

.products-details-tabs .nav .nav-item {
  display: inline-block;
  margin-right: 20px;
}

.products-details-tabs .nav .nav-item:last-child {
  margin-right: 0;
}

.products-details-tabs .nav .nav-item .nav-link {
  border-radius: 0;
  border: none;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 15px 30px;
  border-radius: 15px;
  font-size: var(--font-size);
  font-weight: bold;
  background-color: #f2f1f3;
  color: var(--black-color);
}

.products-details-tabs .nav .nav-item .nav-link:hover, .products-details-tabs .nav .nav-item .nav-link.active {
  color: var(--white-color);
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
}

.products-details-tabs .tab-content .tab-pane {
  padding: 30px;
  background: #f5f5f5;
  border-radius: 15px;
}

.products-details-tabs .tab-content .tab-pane .content p {
  line-height: 1.8;
}

.products-details-tabs .tab-content .tab-pane .products-reviews h3 {
  margin-bottom: 15px;
  display: inline-block;
  font-size: 22px;
  font-weight: bold;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating span {
  font-size: var(--font-size);
  color: #cecfd2;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating span.checked {
  color: orange;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating-count span {
  display: block;
  font-size: 15px;
  color: var(--paragraph-color);
  font-weight: 500;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .side {
  float: left;
  width: 8%;
  margin-top: 10px;
  padding-left: 0;
  padding-right: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .side div {
  font-size: var(--font-size);
  font-weight: 600;
  font-family: var(--heading-font-family);
}

.products-details-tabs .tab-content .tab-pane .products-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .right {
  text-align: end;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-container {
  width: 100%;
  background-color: var(--white-color);
  text-align: center;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4caf50;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196f3;
  border-radius: 5px;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments {
  margin-top: 40px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments h3 {
  border-bottom: 1px solid var(--white-color);
  padding-bottom: 12px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review {
  border-bottom: 1px solid var(--white-color);
  padding: 20px 0 20px 110px;
  position: relative;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 20px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .sub-comment {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 15px;
  color: var(--main-color);
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review p {
  font-size: 15px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 15px;
  display: inline-block;
  margin-right: -2px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating span {
  color: var(--black-color);
  position: relative;
  top: -2px;
  font-weight: 600;
  margin-left: 5px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper {
  margin-top: 30px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper h3 {
  margin-bottom: 10px;
  font-size: 18px;
  letter-spacing: 1px;
  color: var(--black-color);
  font-weight: bold;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper .comment-notes span {
  color: red;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form {
  margin-top: 20px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group {
  margin-bottom: 25px;
  text-align: start;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control {
  height: 55px;
  padding: 15px 20px;
  line-height: initial;
  color: var(--paragraph-color);
  background-color: var(--white-color);
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 400;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control::-webkit-input-placeholder {
  color: var(--paragraph-color);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control:-ms-input-placeholder {
  color: var(--paragraph-color);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control::-ms-input-placeholder {
  color: var(--paragraph-color);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control::placeholder {
  color: var(--paragraph-color);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control:focus {
  border: 1px solid var(--main-color);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group textarea.form-control {
  min-height: 115px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating {
  text-align: left;
  overflow: hidden;
  max-width: 115px;
  margin-top: -5px;
  margin-bottom: 30px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label {
  float: right;
  position: relative;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label:not(:first-of-type) {
  padding-right: 5px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label:before {
  content: "\2605";
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 27px;
  color: var(--paragraph-color);
  line-height: 1;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating input {
  display: none;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating input:checked ~ label:before,
.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating:not(:checked) > label:hover:before,
.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating:not(:checked) > label:hover ~ label:before {
  color: #f6b500;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent input {
  margin-right: 5px;
  position: relative;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .default-btn {
  margin-top: 22px;
  border: none;
  padding: 15px 30px;
}

.related-title {
  text-align: start;
  margin-bottom: 35px;
}

.related-title h2 {
  font-size: 22px;
  font-weight: bold;
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
  padding-left: 30px;
}

.widget-area .widget {
  margin-bottom: 30px;
}

.widget-area .widget:last-child {
  margin-bottom: 0;
}

.widget-area .widget .widget-title {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
}

.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: var(--main-color);
}

.widget-area .widget_search form {
  position: relative;
}

.widget-area .widget_search form .search-field {
  height: 70px;
  color: var(--black-color);
  border: 1px solid #f2f1f3;
  display: block;
  width: 100%;
  border-radius: 15px;
  padding: 15px 20px 15px 70px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 400;
  outline: 0;
  background: #f2f1f3;
}

.widget-area .widget_search form .search-field::-webkit-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_search form .search-field:-ms-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_search form .search-field::-ms-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_search form .search-field::placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_search form .search-field:focus::-webkit-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus:-ms-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus::-ms-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}

.widget-area .widget_search form button {
  border: none;
  background-color: transparent;
  color: var(--black-color);
  position: absolute;
  left: 32px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 22.8px;
  font-size: 25px;
  line-height: 0;
}

.widget-area .widget_search form button:hover, .widget-area .widget_search form button:focus {
  color: var(--main-color);
}

.widget-area .widget_categories .list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_categories .list li {
  margin-bottom: 18px;
}

.widget-area .widget_categories .list li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_categories .list li a {
  color: var(--paragraph-color);
  display: inline-block;
  font-weight: 500;
  font-family: var(--heading-font-family);
  padding: 15px 25px;
  background-color: var(--white-color);
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 30px;
  width: 100%;
}

.widget-area .widget_categories .list li a:hover {
  color: var(--main-color);
}

.widget-area .widget_recent_post {
  position: relative;
  overflow: hidden;
}

.widget-area .widget_recent_post .item {
  overflow: hidden;
  margin-bottom: 25px;
}

.widget-area .widget_recent_post .item:last-child {
  margin-bottom: 0;
}

.widget-area .widget_recent_post .item .thumb {
  float: left;
  height: 90px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 90px;
  margin-right: 15px;
  z-index: 1;
}

.widget-area .widget_recent_post .item .thumb .fullimage {
  width: 90px;
  height: 90px;
  display: inline-block;
  border-radius: 10px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget-area .widget_recent_post .item .thumb .fullimage.bg1 {
  background-image: url(../../public/images/blog/blog-1.jpg);
}

.widget-area .widget_recent_post .item .thumb .fullimage.bg2 {
  background-image: url(../../public/images/blog/blog-2.jpg);
}

.widget-area .widget_recent_post .item .thumb .fullimage.bg3 {
  background-image: url(../../public/images/blog/blog-3.jpg);
}

.widget-area .widget_recent_post .item .thumb::before, .widget-area .widget_recent_post .item .thumb::after {
  -webkit-transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: var(--white-color);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.widget-area .widget_recent_post .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}

.widget-area .widget_recent_post .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}

.widget-area .widget_recent_post .item .info {
  overflow: hidden;
  margin-top: 2.5px;
}

.widget-area .widget_recent_post .item .info span {
  display: block;
  color: var(--optional-color);
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--heading-font-family);
}

.widget-area .widget_recent_post .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: var(--font-size);
  font-weight: 600;
}

.widget-area .widget_recent_post .item .info .title a {
  display: inline-block;
}

.widget-area .widget_recent_post .item:hover .thumb::before, .widget-area .widget_recent_post .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}

.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 18px;
}

.widget-area .tagcloud a {
  display: inline-block;
  background: #f5f5f5;
  color: var(--paragraph-color);
  padding: 8px 15px;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  margin-top: 8px;
  margin-right: 5px;
}

.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: var(--white-color);
  background-color: var(--main-color);
}

.widget-area .widget_recent_courses {
  position: relative;
  overflow: hidden;
}

.widget-area .widget_recent_courses .item {
  overflow: hidden;
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
}

.widget-area .widget_recent_courses .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.widget-area .widget_recent_courses .item .thumb {
  float: left;
  height: 90px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 90px;
  margin-right: 15px;
  z-index: 1;
}

.widget-area .widget_recent_courses .item .thumb .fullimage {
  width: 90px;
  height: 90px;
  display: inline-block;
  border-radius: 10px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget-area .widget_recent_courses .item .thumb .fullimage.bg1 {
  background-image: url(../../public/images/courses/courses-1.jpg);
}

.widget-area .widget_recent_courses .item .thumb .fullimage.bg2 {
  background-image: url(../../public/images/courses/courses-2.jpg);
}

.widget-area .widget_recent_courses .item .thumb .fullimage.bg3 {
  background-image: url(../../public/images/courses/courses-3.jpg);
}

.widget-area .widget_recent_courses .item .thumb::before, .widget-area .widget_recent_courses .item .thumb::after {
  -webkit-transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: var(--white-color);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.widget-area .widget_recent_courses .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}

.widget-area .widget_recent_courses .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}

.widget-area .widget_recent_courses .item .info {
  overflow: hidden;
}

.widget-area .widget_recent_courses .item .info span {
  display: block;
  color: var(--main-color);
  margin-bottom: 14px;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
}

.widget-area .widget_recent_courses .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: var(--font-size);
  font-weight: 600;
}

.widget-area .widget_recent_courses .item .info .title a {
  display: inline-block;
}

.widget-area .widget_recent_courses .item:hover .thumb::before, .widget-area .widget_recent_courses .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}

.widget-area .widget_insight {
  border-radius: 15px;
}

.widget-area .widget_insight .list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_insight .list li {
  margin-bottom: 10px;
}

.widget-area .widget_insight .list li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_insight .list li a {
  position: relative;
  display: block;
  padding: 15px 25px 15px 40px;
  color: var(--paragraph-color);
  border: 1px solid #eeeeee;
  font-size: 15px;
  font-weight: 500;
  border-radius: 15px;
}

.widget-area .widget_insight .list li a::before {
  width: 5px;
  height: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--main-color);
  content: "";
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
}

.widget-area .widget_insight .list li a:hover, .widget-area .widget_insight .list li a.active {
  border-color: var(--main-color);
  background-color: var(--main-color);
  color: var(--white-color);
}

.widget-area .widget_insight .list li a:hover::before, .widget-area .widget_insight .list li a.active::before {
  background-color: var(--white-color);
}

.widget-area .widget_insight .list li.active a {
  border-color: var(--main-color);
  background-color: var(--main-color);
  color: var(--white-color);
}

.widget-area .widget_insight .list li.active a::before {
  background-color: var(--white-color);
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 20px;
  text-align: center;
}

.pagination-area .page-numbers {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background-color: var(--white-color);
  border: 2px solid var(--optional-color);
  color: var(--optional-color);
  text-align: center;
  border-radius: 50px;
  position: relative;
  margin: 0 2px;
  font-size: var(--font-size);
  font-weight: bold;
  font-family: var(--heading-font-family);
}

.pagination-area .page-numbers:hover, .pagination-area .page-numbers.current {
  color: var(--white-color);
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}

.pagination-area .page-numbers i {
  font-size: 20px;
}

.pagination-area .page-numbers.prev {
  background-color: #f2f1f3;
  border: 2px solid #f2f1f3;
}

.pagination-area .page-numbers.prev i {
  font-size: 25px;
  position: relative;
  top: 5px;
  left: -2px;
}

.pagination-area .page-numbers.prev:hover {
  color: var(--white-color);
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}

.pagination-area .page-numbers.next {
  background-color: #f2f1f3;
  border: 2px solid #f2f1f3;
}

.pagination-area .page-numbers.next i {
  font-size: 25px;
  position: relative;
  top: 5px;
  left: 2px;
}

.pagination-area .page-numbers.next:hover {
  color: var(--white-color);
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}

.scroll-to-top i {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  color: var(--white-color);
  background-color: var(--main-color);
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 999999;
}

.scroll-to-top i:hover {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
          box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
}

.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: #dce3ff;
  overflow: hidden;
}

.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  -webkit-animation: run 15s 0s infinite;
          animation: run 15s 0s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  border-radius: 50%;
}

.lines .line:nth-child(1) {
  margin-left: -25%;
}

.lines .line:nth-child(2) {
  margin-left: 0;
}

.lines .line:nth-child(3) {
  margin-left: 25%;
}

@-webkit-keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

.map-location iframe {
  border: none;
  border-radius: 15px;
}

.gm-style {
  border-radius: 10px !important;
}

.gm-style .place-card-large {
  padding: 10px !important;
}

/*=================================
Buy Now Btn
====================================*/
.buy-now-btn {
  right: 30px;
  z-index: 99;
  top: 50%;
  position: fixed;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: var(--white-color) !important;
  background-color: #82b440;
  padding: 8px 20px 8px;
  font-size: 14px;
  font-weight: 500;
  -webkit-animation-name: tada;
          animation-name: tada;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.buy-now-btn:hover {
  background-color: var(--main-color);
  color: var(--white-color) !important;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.social-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.social-links li {
  display: inline-block;
  margin-right: 10px;
}

.social-links li:last-child {
  margin-right: 0;
}

.social-links li a i {
  background-color: #fff;
  color: #000;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 30px;
  line-height: 30px;
}

.social-links li a i:hover {
  background-color: var(--main-color);
  color: #fff;
}
