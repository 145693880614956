/*================================================
Topbar Area CSS
=================================================*/
.topbar-area {
  background-image: url(../../public/images/topbar-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 15px;
  padding-bottom: 15px;
}

.topbar-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.topbar-information {
  padding-left: 0;
  margin-bottom: 0;
}

.topbar-information li {
  list-style-type: none;
  display: inline-block;
  color: var(--white-color);
  font-size: var(--font-size);
  font-family: var(--heading-font-family);
  font-weight: 400;
  margin-right: 20px;
  position: relative;
  padding-left: 25px;
}

.topbar-information li:last-child {
  margin-right: 0;
}

.topbar-information li i {
  position: absolute;
  top: 2.2px;
  left: 0;
  color: var(--white-color);
  font-size: 18px;
}

.topbar-information li span {
  color: #FB64BF;
  font-weight: 600;
}

.topbar-information li span a {
  color: var(--white-color);
  font-weight: 400;
}

.topbar-information li span a:hover {
  color: var(--main-color);
}

.topbar-action {
  padding: 0;
  margin-bottom: 0;
  text-align: end;
}

.topbar-action li {
  list-style-type: none;
  display: inline-block;
}

.language-option {
  padding: 0;
}

.language-option button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--white-color);
  font-size: var(--font-size);
  font-family: var(--heading-font-family);
  font-weight: 400;
  padding-left: 22px;
  padding-right: 22px;
}

.language-option button i {
  position: absolute;
  top: 4.2px;
  left: 0;
  color: var(--white-color);
}

.language-option button::after {
  display: none;
}

.language-option button::before {
  content: "\ea4e";
  display: block;
  position: absolute;
  top: 1.8px;
  right: 0;
  font-size: 15px;
  border-color: var(--white-color);
  font-family: "remixicon";
}

.language-option .dropdown-menu[style] {
  right: 0 !important;
  left: auto !important;
  -webkit-transform: translateY(38px) !important;
          transform: translateY(38px) !important;
}

.language-option .language-dropdown-menu {
  border-radius: 5px;
  border: 0;
  background-color: var(--white-color);
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
          box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  padding: 0;
}

.language-option .language-dropdown-menu .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--paragraph-color);
  font-size: 14px;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
  border-radius: 5px;
}

.language-option .language-dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}

.language-option .language-dropdown-menu .dropdown-item img {
  width: 25px;
  margin-right: 10px;
}

.language-option .language-dropdown-menu .dropdown-item.selected {
  background-color: var(--main-color);
  color: var(--white-color);
  border-radius: 5px;
}

/*================================================
Navbar Area CSS
=================================================*/
.main-navbar {
  padding: 10px 0;
}

.main-navbar.navbar-with-black-color {
  background-color: var(--black-color);
}

.main-navbar .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.main-navbar .navbar {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0;
}

.main-navbar .navbar .navbar-brand {
  padding: 0 30px 0 0;
}

.main-navbar .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.main-navbar .navbar .navbar-nav .nav-item {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}

.main-navbar .navbar .navbar-nav .nav-item a {
  color: #CDC4D9;
  font-size: var(--font-size);
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-family: var(--heading-font-family);
  position: relative;
}

.main-navbar .navbar .navbar-nav .nav-item a i {
  font-size: 14px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-left: -2px;
  margin-right: -2px;
}

.main-navbar .navbar .navbar-nav .nav-item a::after {
  content: "";
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 0;
  height: 4.5px;
  background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 50px;
}

.main-navbar .navbar .navbar-nav .nav-item a:hover, .main-navbar .navbar .navbar-nav .nav-item a:focus, .main-navbar .navbar .navbar-nav .nav-item a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item a:hover::after, .main-navbar .navbar .navbar-nav .nav-item a:focus::after, .main-navbar .navbar .navbar-nav .nav-item a.active::after {
  width: 25px;
  right: auto;
  left: -5px;
}

.main-navbar .navbar .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.main-navbar .navbar .navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.main-navbar .navbar .navbar-nav .nav-item:hover a, .main-navbar .navbar .navbar-nav .nav-item.active a {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu {
  border: none;
  top: 70px;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 230px;
  display: block;
  border-radius: 8px;
  padding: 10px 0;
  margin-top: 15px;
  position: absolute;
  visibility: hidden;
  background: var(--white-color);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li {
  margin: 0;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 10px 20px;
  position: relative;
  display: block;
  color: var(--black-color);
  font-size: 15px;
  font-weight: 500;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 15px;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a::before {
  position: absolute;
  content: "";
  left: 20px;
  top: 19.8px;
  height: 2px;
  width: 15px;
  background: var(--btn-gradient-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--main-color);
  padding-left: 42px;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::before, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
  opacity: 1;
  visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::after, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::after, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active::after {
  display: none;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  right: auto;
  margin-top: 15px;
  visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  right: 100%;
  visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 100%;
  visibility: hidden;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-navbar .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-navbar .navbar .others-options {
  margin-left: 35px;
}

.main-navbar .navbar .others-options .option-item {
  position: relative;
  margin-right: 25px;
}

.main-navbar .navbar .others-options .option-item:last-child {
  margin-right: 0;
}

.main-navbar .navbar .others-options .option-item .search-btn {
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #CDC4D9;
  font-size: 25px;
  position: relative;
  top: 3.5px;
}

.main-navbar .navbar .others-options .option-item .search-btn:hover {
  color: var(--main-color);
}

.main-navbar .navbar .others-options .option-item .close-btn {
  cursor: pointer;
  display: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #CDC4D9;
  font-size: 25px;
  transition: var(--transition);
}

.main-navbar .navbar .others-options .option-item .close-btn:hover {
  color: var(--main-color);
}

.main-navbar .navbar .others-options .option-item .close-btn.active {
  display: block;
}

.main-navbar .navbar .others-options .option-item .side-menu-btn i {
  font-size: 25px;
  color: #CDC4D9;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  top: 3.5px;
}

.main-navbar .navbar .others-options .option-item .side-menu-btn i:hover {
  color: var(--main-color);
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: white;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.navbar-style-two-with-color.is-sticky {
  background: var(--white-color);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item a {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item a:hover, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item a:focus, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item a.active {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item:hover a, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item.active a {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a::before {
  background: var(--btn-gradient-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.navbar-style-two-with-color .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .others-options .option-item .search-btn {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .others-options .option-item .search-btn:hover {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .others-options .option-item .close-btn {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .others-options .option-item .close-btn:hover {
  color: var(--main-color);
}

.navbar-style-two-with-color .main-navbar .navbar .others-options .option-item .close-btn.active {
  display: block;
}

.navbar-style-two-with-color .main-navbar .navbar .others-options .option-item .side-menu-btn i {
  color: var(--black-color);
}

.navbar-style-two-with-color .main-navbar .navbar .others-options .option-item .side-menu-btn i:hover {
  color: var(--main-color);
}

.navbar-style-two-with-color .navbar-toggler .icon-bar {
  background: #000;
}

.main-header-area {
  position: absolute;
  width: 100%;
  z-index: 9;
}

.navbar-toggler {
  border: none;
  border-radius: 0;
  padding: 0;
}

.navbar-toggler:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.navbar-toggler .icon-bar {
  width: 35px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #CDC4D9;
  height: 3px;
  display: block;
  border-radius: 3px;
}

.navbar-toggler .top-bar {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
          transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 5px 0;
}

.navbar-toggler .bottom-bar {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
          transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}

.navbar-toggler.collapsed .top-bar {
  -webkit-transform: rotate(0);
          transform: rotate(0);
  left: 0;
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  -webkit-transform: rotate(0);
          transform: rotate(0);
  left: 0;
  margin-top: 6px;
}

/*================================================
Search Popup CSS
=================================================*/
.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.search-overlay .search-overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.search-overlay .search-overlay-layer:nth-child(1) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.search-overlay .search-overlay-layer:nth-child(2) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.3s ease-in-out 0.3s;
  transition: all 0.3s ease-in-out 0.3s;
}

.search-overlay .search-overlay-layer:nth-child(3) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.9s ease-in-out 0.6s;
  transition: all 0.9s ease-in-out 0.6s;
}

.search-overlay .search-overlay-close {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 50px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  -webkit-transition: all 0.9s ease-in-out 1.5s;
  transition: all 0.9s ease-in-out 1.5s;
  opacity: 0;
  visibility: hidden;
}

.search-overlay .search-overlay-close .search-overlay-close-line {
  width: 100%;
  height: 3px;
  float: left;
  margin-bottom: 5px;
  background-color: var(--white-color);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(2) {
  margin-top: -7px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.search-overlay .search-overlay-close:hover .search-overlay-close-line {
  background: var(--main-color);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.search-overlay .search-overlay-form {
  -webkit-transition: all 0.9s ease-in-out 1.4s;
  transition: all 0.9s ease-in-out 1.4s;
  opacity: 0;
  visibility: hidden;
  position: relative;
  z-index: 2;
  max-width: 500px;
  margin: auto;
  padding: 0 15px;
}

.search-overlay .search-overlay-form form {
  position: relative;
}

.search-overlay .search-overlay-form form .input-search {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  color: var(--paragraph-color);
  padding: 0 0 0 25px;
  font-weight: 500;
  outline: 0;
}

.search-overlay .search-overlay-form form .input-search::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--paragraph-color);
}

.search-overlay .search-overlay-form form .input-search:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--paragraph-color);
}

.search-overlay .search-overlay-form form .input-search::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--paragraph-color);
}

.search-overlay .search-overlay-form form .input-search::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--paragraph-color);
}

.search-overlay .search-overlay-form form .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus::placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form button {
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  color: var(--white-color);
  height: 50px;
  border-radius: 50%;
  background-color: var(--main-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: none;
  font-size: 20px;
  line-height: 50px;
}

.search-overlay .search-overlay-form form button:hover {
  background-color: var(--black-color);
  color: var(--white-color);
}

.search-overlay.search-overlay-active.search-overlay {
  opacity: 1;
  visibility: visible;
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-layer {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-close {
  opacity: 1;
  visibility: visible;
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-form {
  opacity: 1;
  visibility: visible;
}

/*================================================
Sidebar Modal CSS
=================================================*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  background-color: var(--white-color);
  border: none;
  border-radius: 0;
}

.modal.right .modal-content button.close {
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: transparent;
  border: none;
  text-shadow: unset;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  z-index: 2;
  opacity: 1;
}

.modal.right .modal-content button.close i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: var(--main-color);
  color: var(--white-color);
  font-size: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
}

.modal.right .modal-content button.close i:hover {
  background-color: var(--optional-color);
}

.modal.right.fade {
  opacity: 1 !important;
  right: -100%;
  left: auto;
}

.modal.right.fade .modal-dialog {
  right: -100%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show {
  right: 0;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.sidebarModal.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.8);
}

.sidebarModal.modal .modal-body {
  padding: 25px;
}

.sidebarModal.modal .modal-body .sidebar-content {
  margin-top: 35px;
}

.sidebarModal.modal .modal-body .sidebar-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.sidebarModal.modal .modal-body .sidebar-content .sidebar-btn {
  margin-top: 25px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info {
  margin-top: 30px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info h3 {
  font-size: 22px;
  margin-bottom: 0;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list {
  padding-left: 0;
  margin-top: 30px;
  margin-bottom: 0;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li {
  list-style-type: none;
  font-size: 14px;
  color: var(--paragraph-color);
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
  padding-left: 22px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li:last-child {
  margin-bottom: 0;
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li a {
  color: var(--paragraph-color);
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li a:hover {
  color: var(--main-color);
}

.sidebarModal.modal .modal-body .sidebar-contact-info .info-list li i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: var(--font-size);
  color: var(--main-color);
}

.sidebarModal.modal .modal-body .sidebar-social-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 30px;
}

.sidebarModal.modal .modal-body .sidebar-social-list li {
  display: inline-block;
  margin-right: 8px;
}

.sidebarModal.modal .modal-body .sidebar-social-list li:last-child {
  margin-right: 0;
}

.sidebarModal.modal .modal-body .sidebar-social-list li i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background: var(--btn-gradient-color);
  text-align: center;
  color: var(--white-color);
  font-size: var(--font-size);
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sidebarModal.modal .modal-body .sidebar-social-list li i:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  background-color: var(--optional-color);
  color: var(--white-color);
}

.sidebarModal.modal .modal-body .contact-form {
  margin-top: 30px;
}

.sidebarModal.modal .modal-body .contact-form h3 {
  font-size: 22px;
  margin-bottom: 25px;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group {
  margin-bottom: 20px;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control {
  height: 55px;
  color: var(--black-color);
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 15px;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control::-webkit-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control:-ms-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control::-ms-input-placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control::placeholder {
  color: var(--paragraph-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control:focus {
  border-color: var(--main-color);
  background-color: transparent;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group .form-control:focus::placeholder {
  color: transparent;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .default-btn {
  border: none;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .list-unstyled {
  padding: 0;
  margin-bottom: 0;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .list-unstyled li {
  font-size: 14px;
  margin-top: 10px;
  color: red;
}

.sidebarModal.modal .modal-body .contact-form #contactForm .text-danger {
  font-size: 18px;
  margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
  .navbar-area {
    background-color: var(--black-color);
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-area {
    padding-top: 0;
    padding-bottom: 0;
  }
  .main-navbar .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-navbar .navbar .navbar-nav {
    margin-top: 15px;
    padding-top: 5px;
    overflow-y: auto;
    max-height: 70vh;
    padding-right: 15px;
  }
  .main-navbar .navbar .navbar-nav::-webkit-scrollbar {
    width: 10px;
  }
  .main-navbar .navbar .navbar-nav::-webkit-scrollbar-track {
    background: #eee;
  }
  .main-navbar .navbar .navbar-nav::-webkit-scrollbar-thumb {
    background: #a19c9c;
  }
  .main-navbar .navbar .navbar-nav::-webkit-scrollbar-thumb:hover {
    background: #a19c9c;
  }
  .main-navbar .navbar .navbar-nav .nav-item {
    margin: 0;
  }
  .main-navbar .navbar .navbar-nav .nav-item a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .main-navbar .navbar .navbar-nav .nav-item a i {
    display: none;
  }
  .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu {
    opacity: 1;
    width: 100%;
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: initial;
    visibility: visible;
  }
  .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a {
    padding: 8px 15px;
    font-size: 15px;
  }
  .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    opacity: 1;
    width: 90%;
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: initial;
    visibility: visible;
    margin-left: 15px;
  }
  .main-navbar .navbar .others-options {
    position: absolute;
    margin-left: 0;
    top: 8px;
    right: 50px;
  }
  .main-navbar .navbar .others-options .option-item {
    margin-right: 15px;
  }
  .topbar-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 540px;
  }
  .topbar-information li {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .topbar-information li:last-child {
    margin-bottom: 0;
  }
  .topbar-information li span {
    color: var(--black-color);
  }
  ul.social-links.text-end {
    text-align: left !important;
    padding: 0;
    margin-top: 15px;
  }
  .topbar-action {
    text-align: start;
    margin-top: 10px;
  }
  .language-option .dropdown-menu[style] {
    left: 0 !important;
    right: auto !important;
  }
  .modal.right .modal-dialog {
    width: 315px;
  }
  .sidebarModal.modal .modal-body {
    padding: 20px;
  }
  .sidebarModal.modal .modal-body .sidebar-content h3 {
    font-size: 20px;
  }
  .sidebarModal.modal .modal-body .sidebar-contact-info h3 {
    font-size: 20px;
  }
  .sidebarModal.modal .modal-body .contact-form h3 {
    font-size: 20px;
  }
  .navbar-style-two-with-color {
    background-color: var(--white-color);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-area {
    padding: 0;
  }
  .main-navbar .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-navbar .navbar .navbar-brand {
    padding: 0;
  }
  .main-navbar .navbar .navbar-nav .nav-item {
    margin-left: 8px;
    margin-right: 8px;
  }
  .main-navbar .navbar .navbar-nav .nav-item a {
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .main-navbar .navbar .navbar-nav .nav-item a i {
    font-size: 12px;
    margin-left: -3px;
    margin-right: -3px;
  }
  .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a {
    padding: 6px 15px;
    font-size: 14px;
  }
  .main-navbar .navbar .others-options {
    margin-left: 15px;
  }
  .main-navbar .navbar .others-options .option-item {
    margin-right: 10px;
  }
  .main-navbar .navbar .others-options .option-item .search-btn {
    font-size: 20px;
  }
  .topbar-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 720px;
  }
  .topbar-information li {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .topbar-information li:last-child {
    margin-bottom: 0;
  }
  .topbar-information li span {
    color: var(--black-color);
  }
  .modal.right .modal-dialog {
    width: 315px;
  }
  .sidebarModal.modal .modal-body {
    padding: 20px;
  }
  .sidebarModal.modal .modal-body .sidebar-content h3 {
    font-size: 20px;
  }
  .sidebarModal.modal .modal-body .sidebar-contact-info h3 {
    font-size: 20px;
  }
  .sidebarModal.modal .modal-body .contact-form h3 {
    font-size: 20px;
  }
  .mean-container .mean-nav ul li a.mean-expand {
    right: 18px;
  }
  .navbar-style-two-with-color {
    background-color: var(--white-color);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .topbar-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 960px;
  }
  .topbar-information li {
    margin-right: 10px;
    font-size: 15px;
  }
  .topbar-information li i {
    font-size: var(--font-size);
  }
  .language-option button {
    font-size: 15px;
  }
  .modal.right .modal-dialog {
    width: 315px;
  }
  .sidebarModal.modal .modal-body {
    padding: 20px;
  }
  .sidebarModal.modal .modal-body .sidebar-content h3 {
    font-size: 20px;
  }
  .sidebarModal.modal .modal-body .sidebar-contact-info h3 {
    font-size: 20px;
  }
  .sidebarModal.modal .modal-body .contact-form h3 {
    font-size: 20px;
  }
  .mean-container .mean-bar {
    padding: 0 0 0;
  }
  .mean-container .mean-nav ul li a.mean-expand {
    right: 18px;
  }
  .navbar-style-two-with-color {
    background-color: var(--white-color);
  }
}

@media only screen and (min-width: 1550px) {
  .topbar-area .container-fluid {
    max-width: 1900px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .main-navbar .container-fluid {
    max-width: 1900px;
    padding-left: 100px;
    padding-right: 100px;
  }
}
