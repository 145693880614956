/*
@File: Stax Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Default Btn CSS
** - Section Title CSS
** - Main Slides Area CSS
** - Main Hero Area CSS
** - Main Banner Area CSS
** - Partner Area CSS
** - About Area CSS
** - Services Area CSS
** - Services Details Area CSS
** - Choose Area CSS
** - Video Area CSS
** - Projects Area CSS
** - Projects Details Area CSS
** - Testimonials Area CSS
** - Pricing Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Skill Area CSS
** - Features Area CSS
** - Talk Area CSS
** - Team Area CSS
** - Overview Area CSS
** - Page Banner Area CSS
** - Gallery Area CSS
** - FAQ Area CSS
** - Events Area CSS
** - Events Details Area CSS
** - Career Area CSS
** - Apply Area CSS
** - Career Details Area CSS
** - Profile Authentication Area CSS
** - Membership Levels Area CSS
** - Purchase Guide Area CSS
** - Terms of Service Area CSS
** - Privacy Policy Area CSS
** - 404 Error Area CSS
** - Coming Soon Area CSS
** - Courses Area CSS
** - Courses Details Area CSS
** - Products Area CSS
** - Cart Area CSS
** - Wishlist Area CSS
** - Checkout Area CSS
** - Products Details Area CSS
** - Widget Sidebar CSS
** - Pagination CSS

*/

/*================================================
Default CSS
=================================================*/
:root {
  --main-font-family: "Open Sans", sans-serif;
  --heading-font-family: "Jost", sans-serif;
  --monoton-font-family: "Monoton", cursive;
  --main-color: #feb302;
  --optional-color: #3e019a;
  --white-color: #ffffff;
  --black-color: #14042c;
  --paragraph-color: #646464;
  --font-size: 16px;
  --transition: 0.6s;
  --btn-gradient-color: radial-gradient(
    circle,
    #feb302,
    #f8c600,
    #eed900,
    #e0ec00,
    #cdff05
  );
}
body {
  padding: 0;
  margin: 0;
  font-family: var(--main-font-family);
  font-size: var(--font-size);
}
a {
  text-decoration: none;
  transition: var(--transition);
  color: var(--black-color);
  display: inline-block;

  &:hover,
  &:focus {
    color: var(--main-color);
    text-decoration: none;
  }
}
button {
  outline: 0 !important;
}
p {
  margin-bottom: 15px;
  line-height: 1.8;
  color: var(--paragraph-color);
  font-weight: 500;
  font-family: var(--main-font-family);
  font-size: var(--font-size);

  &:last-child {
    margin-bottom: 0;
  }
}
i {
  line-height: normal;
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-75 {
  padding-bottom: 75px;
}
.radius-0 {
  .services-area.with-radius {
    margin: 0;
    border-radius: 0;
  }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black-color);
  font-family: var(--heading-font-family);
  font-weight: 900;

  &:last-child {
    margin-bottom: 0;
  }
}
.mb-minus-100 {
  margin-bottom: -110px;
}

/*================================================
Default Btn CSS
=================================================*/
.default-btn {
  padding: 15.8px 35px;
  text-align: center;
  color: var(--black-color) !important;
  font-size: var(--font-size);
  font-weight: 600;
  transition: var(--transition);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  z-index: 0;
  background: var(--btn-gradient-color);
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-family: var(--heading-font-family);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 550px;
    height: 550px;
    margin: auto;
    background-color: var(--main-color);
    border-radius: 50%;
    z-index: -1;
    transform-origin: top center;
    transform: translateX(-50%) translateY(-5%) scale(0.4);
    transition: transform 0.9s;
  }
  &:hover {
    color: var(--white-color);

    &:before {
      transition: transform 1s;
      transform: translateX(-45%) translateY(0) scale(1);
      transform-origin: bottom center;
    }
  }
}

/*================================================
Section Title CSS
=================================================*/
.section-title {
  max-width: 630px;
  margin: 0 auto 45px;
  text-align: center;
  position: relative;

  .section-bg-text {
    color: #f8f7f8;
    font-size: 95px;
    font-family: var(--monoton-font-family);
    font-weight: 400;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    z-index: -1;
    line-height: 0;
  }
  span {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--heading-font-family);
  }
  h2 {
    font-size: 42px;
    margin-bottom: 0;
    line-height: 1.4;
    position: relative;

    b {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  p {
    margin-top: 15px;
  }
  &.section-style-two {
    margin-top: 30px;
  }
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  position: fixed;
  z-index: 999999;
  background-color: var(--white-color);
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;

      &::after {
        content: " ";
        display: block;
        position: absolute;
        top: 5px;
        left: 35px;
        width: 5px;
        height: 20px;
        border-radius: 20%;
        background: var(--main-color);
      }
      &:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }
      &:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
      }
      &:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
      }
      &:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
      }
      &:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
      }
      &:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
      }
      &:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
      }
      &:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
      }
      &:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
      }
      &:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
      }
      &:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
      }
      &:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }
    }
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

/*================================================
Main Slides Area CSS
=================================================*/
.main-slides-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.main-slides-item {
  background-color: var(--black-color);
  padding: {
    top: 150px;
    bottom: 100px;
  }
  .container-fluid {
    padding: {
      left: 30px;
      right: 30px;
    }
  }
}
.main-slides-content {
  padding-right: 50px;

  span {
    font-size: var(--font-size);
    font-weight: 600;
    color: var(--main-color);
    display: block;
    margin-bottom: 18px;
    font-family: var(--heading-font-family);
  }
  h1 {
    font-size: 85px;
    margin-bottom: 0;
    color: var(--white-color);
    position: relative;
  }
  p {
    margin: {
      top: 18px;
      bottom: 0;
    }
    color: #cdc4d9;
  }
  .slides-btn {
    margin-top: 30px;
  }
}
.home-slides {
  .swiper-pagination {
    bottom: 30px;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #d1cfdb;
      transition: var(--transition);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: radial-gradient(
          circle,
          #a66bff,
          #c666ef,
          #dd62df,
          #ee61cf,
          #fb64bf
        );
        width: 20px;
        border-radius: 30px;
      }
    }
  }
}
.main-slides-shape-1 {
  position: absolute;
  left: 50px;
  top: 65px;
  z-index: 1;
  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.main-slides-shape-2 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateY(-20%) translateX(-50%);
  z-index: 1;
  animation: moveleftbounce 2s linear infinite;
}
.main-slides-shape-3 {
  position: absolute;
  bottom: 10%;
  left: 20%;
  z-index: 1;
  transform: translateY(-10%) translateX(-20%);
  animation: moveleftbounce 1s linear infinite;
}
.main-slides-shape-4 {
  position: absolute;
  bottom: 10%;
  right: 32%;
  transform: translateY(-10%) translateX(-32%);
  z-index: 1;
  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.main-slides-shape-5 {
  position: absolute;
  bottom: 5%;
  right: 10%;
  transform: translateY(-5%) translateX(-10%);
  z-index: 1;
  animation: animationFramesOne 10s infinite linear;
}
@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}

/*================================================
Main Hero Area CSS
=================================================*/
.main-hero-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  &.mt-94 {
    margin-top: 94px;
  }
}
.main-hero-item {
  background-image: url(../../public/images/main-hero/hero-bg-1.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: {
    top: 150px;
    bottom: 150px;
  }
  position: relative;
  z-index: 1;

  &.item-bg-2 {
    background-image: url(../../public/images/main-hero/hero-bg-2.jpg);
  }
  &.item-bg-3 {
    background-image: url(../../public/images/main-hero/hero-bg-3.jpg);
  }
  &::before {
    background-image: linear-gradient(to left, rgba(20,4,44,0), rgba(20,4,44,1));
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  .container-fluid {
    padding: {
      left: 30px;
      right: 30px;
    }
  }
}
.main-hero-content {
  max-width: 1050px;

  span {
    font-size: var(--font-size);
    font-weight: 600;
    color: var(--main-color);
    display: block;
    margin-bottom: 18px;
    font-family: var(--heading-font-family);
  }
  h1 {
    font-size: 85px;
    margin-bottom: 0;
    color: var(--white-color);
    position: relative;

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  p {
    margin: {
      top: 18px;
      bottom: 0;
    }
    color: #cdc4d9;
    max-width: 825px;
  }
  .slides-btn {
    margin-top: 30px;
  }
}
.hero-slides {
  .swiper-button-next, .swiper-button-prev {
    &:after {
      color: var(--main-color);
    }
  }
}
.hero-shape-1 {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.hero-shape-2 {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.hero-shape-3 {
  position: absolute;
  bottom: 5%;
  left: 50%;
  z-index: 1;
  transform: translateY(-5%) translateY(-50%);
  animation: animationFramesOne 10s infinite linear;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url(../../public/images/main-banner/banner-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px;

  .container-fluid {
    padding: {
      left: 30px;
      right: 30px;
    }
  }
}
.main-banner-content {
  margin-top: -120px;
  position: relative;
  z-index: 1;

  span {
    font-size: var(--font-size);
    font-weight: 600;
    color: var(--optional-color);
    display: block;
    margin-bottom: 20px;
    font-family: var(--heading-font-family);
  }
  h1 {
    font-size: 75px;
    margin-bottom: 0;
    position: relative;

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  p {
    margin: {
      top: 18px;
      bottom: 0;
    }
    max-width: 825px;
  }
  .banner-btn {
    margin-top: 30px;
  }
}
.main-banner-image {
  position: relative;
  z-index: 1;
  text-align: center;

  .banner-circle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100px;
    right: 0;
    margin: auto;
    z-index: -1;

    img {
      animation: {
        name: rotateme;
        duration: 5s;
        iteration-count: infinite;
        timing-function: linear;
      }
    }
  }
}
.banner-shape-1 {
  position: absolute;
  top: -100px;
  left: -100px;
  animation: moveleftbounce 1s linear infinite;
  z-index: -1;
}
.banner-shape-2 {
  position: absolute;
  bottom: 25px;
  right: -50px;
  animation: animationFramesOne 10s infinite linear;
  z-index: -1;
}
.banner-dot-shape-1 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateY(-20%) translateX(-50%);
  animation: animationFramesOne 25s infinite linear;
  z-index: -1;
}
.banner-dot-shape-2 {
  position: absolute;
  top: 15%;
  left: 5%;
  transform: translateY(-15%) translateX(-5%);
  animation: animationFramesOne 10s infinite linear;
  z-index: -1;
}
.banner-dot-shape-3 {
  position: absolute;
  bottom: 10%;
  left: 35%;
  transform: translateY(-10%) translateX(-35%);
  animation: animationFramesOne 20s infinite linear;
  z-index: -1;
}
.banner-dot-shape-4 {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  animation: animationFramesOne 15s infinite linear;
  z-index: -1;
}
.banner-dot-shape-5 {
  position: absolute;
  top: 20%;
  right: 10%;
  transform: translateY(-20%) translateX(-10%);
  animation: animationFramesOne 20s infinite linear;
  z-index: -1;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  background-image: url(../../public/images/partner/partner-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.partner-card {
  text-align: center;
  a {
    display: block;
    position: relative;

    img {
      transition: var(--transition);
      margin: auto;
      width: auto !important;

      &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
        visibility: hidden;
      }
    }
    &:hover {
      img {
        &:nth-child(1) {
          opacity: 0;
          visibility: hidden;
        }
        &:nth-child(2) {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.about-content {
  padding-left: 10px;
  position: relative;
  z-index: 1;

  span {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--heading-font-family);
  }
  h3 {
    font-size: 42px;
    margin-bottom: 15px;
    line-height: 1.4;
    position: relative;

    b {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .about-btn {
    margin-top: 25px;
  }
}
.about-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
  z-index: -1;
}
.about-wrap-image {
  overflow: hidden;
  border-radius: 15px;

  img {
    border-radius: 20px;
    transition: var(--transition);
  }
  &:hover {
    img {
      transform: scale(1.1) !important;
      filter: blur(2px);
    }
  }
}
.about-wrap-content {
  position: relative;
  z-index: 1;
  padding-left: 45px;

  .about-bg-text {
    color: #f8f7f8;
    font-size: 95px;
    font-family: var(--monoton-font-family);
    font-weight: 400;
    position: absolute;
    top: 0;
    z-index: -1;
    line-height: 0;
  }
  span {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--heading-font-family);
  }
  h3 {
    font-size: 42px;
    margin-bottom: 15px;
    line-height: 1.4;
    position: relative;

    b {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}
.about-inner-box {
  margin-top: 50px;
}
.single-about-card {
  margin-bottom: 25px;

  h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
  }
  .about-btn {
    margin-top: 25px;
  }
  .card-image {
    overflow: hidden;
    border-radius: 15px;

    img {
      border-radius: 20px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1) !important;
        filter: blur(2px);
      }
    }
  }
}
.about-circle-shape {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%) translateX(-5%);

  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &.with-radius {
    margin: {
      left: 10px;
      right: 10px;
    }
    border-radius: 15px;
    background-color: var(--black-color);
    overflow: hidden;
  }
  .container-fluid {
    overflow: hidden;
    left: calc((100% - 1320px) / 2);
    position: relative;
  }
  &.bg-with-14042C-color {
    background-color: var(--black-color);
    border-radius: 0 0 100px 100px;

    .section-title {
      h2 {
        color: var(--white-color);
      }
      p {
        color: #cdc4d9;
      }
    }
  }
  &.margin-zero {
    background-color: var(--black-color);
  }
}
.services-area-style-two {
  background-color: var(--black-color);
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-title {
    h2 {
      color: var(--white-color);
    }
    p {
      color: #cdc4d9;
    }
  }
  .container-fluid {
    padding: {
      left: 0;
      right: 0;
    }
  }
}
.services-section-content {
  position: relative;

  .services-bg-text {
    color: #24153a;
    font-size: 95px;
    font-family: var(--monoton-font-family);
    font-weight: 400;
    position: absolute;
    top: 0;
    z-index: -1;
    line-height: 0;
  }
  span {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--heading-font-family);
  }
  h3 {
    font-size: 42px;
    margin-bottom: 15px;
    line-height: 1.4;
    position: relative;
    color: var(--white-color);

    b {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  p {
    color: #cdc4d9;
  }
  .services-section-btn {
    margin-top: 25px;
  }
}
.services-item {
  background-color: #2e2141;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 25px;
  position: relative;
  transition: var(--transition);

  .services-image {
    overflow: hidden;
    border-radius: 15px;

    a {
      display: block;

      img {
        border-radius: 15px;
        transition: var(--transition);
      }
    }
  }
  .services-content {
    padding: 30px 15px 20px;

    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;

      a {
        color: var(--white-color);
      }
    }
    p {
      color: #cdc4d9;
      margin-bottom: 0;
    }
    .services-btn {
      background: var(--btn-gradient-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      margin-top: 20px;
      font-family: var(--heading-font-family);
      transition: var(--transition);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 20px;
        height: 1.5px;
        background: var(--btn-gradient-color);
        transition: all ease 0.5s;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
      }
    }
  }
  &:hover {
    .services-image {
      a {
        img {
          transform: scale(1.1);
          filter: blur(2px);
        }
      }
    }
    .services-content {
      h3 {
        a {
          color: var(--main-color);
        }
      }
      .services-btn {
        padding-left: 25px;

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
.services-slides {
  .swiper-pagination {
    position: initial;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #d1cfdb;
      transition: var(--transition);
      &.swiper-pagination-bullet-active {
        background: var(--btn-gradient-color);
        width: 20px;
        border-radius: 30px;
      }
    }
  }
}
.services-all-btn {
  text-align: center;
  margin-top: 15px;
}
.services-shape-1 {
  position: absolute;
  top: 20%;
  left: 5%;
  transform: translateY(-20%) translateX(-5%);
  animation: moveleftbounce 2s linear infinite;
}
.services-shape-2 {
  position: absolute;
  top: 10%;
  right: 5%;
  transform: translateY(-20%) translateX(-5%);

  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.services-details-desc {
  max-width: 1050px;
  margin: auto;

  .article-services-image {
    overflow: hidden;
    border-radius: 15px;

    img {
      border-radius: 15px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1);
        filter: blur(2px);
      }
    }
  }
  .article-services-content {
    padding: {
      top: 35px;
      left: 45px;
      right: 35px;
    }
    h3 {
      font-size: 42px;
      margin-bottom: 18px;
    }
    .list {
      padding: 0;
      margin: {
        top: 25px;
        bottom: 0;
      }
      li {
        list-style-type: none;
        margin-bottom: 20px;
        color: var(--paragraph-color);
        position: relative;
        padding-left: 25px;
        font-weight: 500;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        i {
          position: absolute;
          left: 0;
          top: 2.8px;
          font-size: 18px;
          color: #a66bff;
        }
        h4 {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
    }
  }
  .article-services-middle-image {
    overflow: hidden;
    border-radius: 15px;
    margin-top: 35px;

    img {
      border-radius: 15px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1);
        filter: blur(2px);
      }
    }
  }
  .article-services-quote {
    background-color: #f5f5f5;
    padding: 30px 30px 30px 90px;
    border-radius: 15px;
    margin-top: 30px;
    position: relative;
    z-index: 1;

    i {
      color: #cfc3e2;
      font-size: 65px;
      position: absolute;
      left: 45px;
      top: 10px;
      z-index: -1;
      animation: moveleftbounce 2s linear infinite;
    }
    p {
      font-size: 22px;
      font-weight: 400;
      color: var(--black-color);
      font-family: var(--heading-font-family);
      margin-bottom: 0;
    }
    .quote-shape {
      position: absolute;
      right: -45px;
      bottom: -45px;
      max-width: 100px;

      img {
        animation: {
          name: rotateme;
          duration: 5s;
          iteration-count: infinite;
          timing-function: linear;
        }
      }
    }
  }
}
.services-details-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

/*================================================
Choose Area CSS
=================================================*/
.choose-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.bg-with-F5F5F5-color {
  background-color: #f5f5f5;
  border-radius: 100px 100px 0 0;
}
.single-choose-card {
  margin-bottom: 25px;

  .choose-image {
    margin-bottom: 25px;
    position: relative;
    display: inline-block;

    a {
      display: block;
    }
    .number {
      position: absolute;
      right: -35px;
      top: -30px;
      font-size: 100px;
      font-weight: 400;
      font-family: var(--monoton-font-family);
      color: #e7e5e9;
    }
  }
  .choose-content {
    h3 {
      font-size: 22px;
      margin-bottom: 15px;

      a {
        color: var(--black-color);
      }
    }
  }
  &:hover {
    .choose-content {
      h3 {
        a {
          color: var(--main-color);
        }
      }
    }
  }
}
.choose-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
  z-index: -1;
}
.choose-shape-2 {
  position: absolute;
  left: 200px;
  margin: auto;
  top: -300px;
  z-index: -1;
}

/*================================================
Video Area CSS
=================================================*/
.video-area {
  position: relative;
  z-index: 1;
}
.video-view-content {
  text-align: center;
  position: relative;
  z-index: 1;

  .video-image {
    overflow: hidden;
    border-radius: 15px;

    img {
      transition: var(--transition);
      border-radius: 15px;
    }
  }
  .video-btn {
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 110px;
    background-color: var(--black-color);
    border-radius: 50%;
    color: var(--optional-color);
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 65px;
    transition: var(--transition);
    border: 2px solid #fb64bf;

    i {
      transition: var(--transition);
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      border: 2px solid #fb64bf;
      animation: ripple 2s linear infinite;
    }
    &:hover {
      background-color: var(--optional-color);

      i {
        background: var(--btn-gradient-color);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &:hover {
    .video-image {
      overflow: hidden;

      img {
        transform: scale(1.1);
        filter: blur(5px);
      }
    }
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
  overflow: hidden;
  position: relative;

  &.with-black-background {
    background-color: var(--black-color);
    margin: {
      left: 10px;
      right: 10px;
    }
    border-radius: 15px;
    overflow: hidden;
    padding-top: 200px;

    .section-title {
      h2 {
        color: var(--white-color);
      }
      p {
        color: #cdc4d9;
      }
    }
    .container-fluid {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
  &.style-two-area {
    .container-fluid {
      overflow: hidden;
      left: calc((100% - 1320px) / 2);
      position: relative;
    }
  }
}
.projects-item {
  background-color: #2e2141;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 25px;

  .projects-image {
    overflow: hidden;
    border-radius: 15px;

    a {
      display: block;

      img {
        border-radius: 15px;
        transition: var(--transition);
      }
    }
  }
  .projects-content {
    padding: 30px 20px 20px;
 
    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 18px;

      a {
        color: var(--white-color);
      }
    }
    .projects-btn {
      background: var(--btn-gradient-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-family: var(--heading-font-family);
      transition: var(--transition);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 20px;
        height: 1.5px;
        background: var(--btn-gradient-color);
        transition: all ease 0.5s;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
      }
    }
  }
  &:hover {
    .projects-image {
      a {
        img {
          transform: scale(1.1);
          filter: blur(2px);
        }
      }
    }
    .projects-content {
      h3 {
        a {
          color: var(--main-color);
        }
      }
      .projects-btn {
        padding-left: 25px;

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &.bg-F2F1F3 {
    background-color: #f2f1f3;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 0%;
      transition: all 0.5s ease-in-out;
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      border-radius: 50%;
      transform: scale(1.5);
    }
    .projects-content {
      h3 {
        a {
          color: var(--black-color);
        }
      }
      .projects-btn {
        background: var(--main-color);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::before {
          background: var(--main-color);
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    &:hover {
      &::before {
        width: 100%;
        height: 100%;
        opacity: 1;
        visibility: visible;
      }
      .projects-content {
        h3 {
          a {
            color: var(--white-color);
          }
        }
        .projects-btn {
          background: var(--btn-gradient-color);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          &::before {
            background: var(--btn-gradient-color);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.projects-slides {
  .swiper-button-next,
  .swiper-button-prev {
    margin-left: 5px;
    margin-right: 5px;
    position: initial;
    margin-top: 15px;
    color: #a66bff;
    border: 2px solid #a66bff;
    border-radius: 50%;
    transition: var(--transition);
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;

    &::after {
      line-height: 46px;
      font-size: 26px;
    }

    &:hover {
      background-color: var(--main-color);
      color: var(--white-color);
      border: 2px solid var(--main-color);
    }
  }
}
.projects-bg-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}
.projects-shape-1 {
  position: absolute;
  top: 40%;
  left: 10%;
  transform: translateY(-40%) translateX(-10%);
  animation: animationFramesOne 10s infinite linear;
}
.projects-shape-2 {
  position: absolute;
  bottom: 2%;
  left: 28%;
  transform: translateY(-2%) translateX(-28%);
  animation: moveleftbounce 1s linear infinite;
}
.projects-shape-3 {
  position: absolute;
  top: 35%;
  right: 5%;
  transform: translateY(-35%) translateX(-5%);

  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.projects-filter-menu {
  list-style-type: none;
  padding: 0;
  text-align: center;
  margin-bottom: 45px;

  li {
    display: inline-block;
    padding: 10px 25px;
    background-color: var(--white-color);
    color: var(--black-color);
    box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-size: var(--font-size);
    font-weight: 600;
    font-family: var(--heading-font-family);
    margin-right: 15px;
    transition: var(--transition);
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      color: var(--white-color);
    }
  }
  .filter {
    &.mixitup-control-active {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      color: var(--white-color);
    }
  }
}
.single-projects-item {
  margin-bottom: 25px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: var(--transition);
    visibility: hidden;
    background: radial-gradient(
      circle,
      #a66bff,
      #c666ef,
      #dd62df,
      #ee61cf,
      #fb64bf
    );
    border-radius: 20px;
    z-index: 1;
  }
  .projects-image {
    overflow: hidden;
    border-radius: 20px;

    a {
      display: block;

      img {
        border-radius: 20px;
        transition: var(--transition);
      }
    }
  }
  .projects-content {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    padding: 30px;
    padding-bottom: 0;
    visibility: hidden;
    transition: var(--transition);
    z-index: 9;

    .category {
      color: #fff;
      margin-bottom: 10px;
      font-size: 14px;
    }

    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;

      a {
        color: var(--white-color);

        &:hover {
          color: var(--black-color);
        }
      }
    }
    .projects-btn {
      background: var(--btn-gradient-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-family: var(--heading-font-family);
      transition: var(--transition);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 20px;
        height: 1.5px;
        background: var(--btn-gradient-color);
        transition: all ease 0.5s;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
      }
      &:hover {
        padding-left: 25px;

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &:hover {
    &::before {
      margin: 10px;
      opacity: 1;
      visibility: visible;
    }
    .projects-image {
      a {
        img {
          transform: scale(1.1);
          filter: blur(2px);
        }
      }
    }
    .projects-content {
      opacity: 1;
      visibility: visible;
      padding-bottom: 30px;
    }
  }
}
.projects-section-content {
  position: relative;

  .projects-bg-text {
    color: #f7f7f8;
    font-size: 95px;
    font-family: var(--monoton-font-family);
    font-weight: 400;
    position: absolute;
    top: 0;
    z-index: -1;
    line-height: 0;
  }
  span {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--heading-font-family);
  }
  h3 {
    font-size: 42px;
    margin-bottom: 15px;
    line-height: 1.4;
    position: relative;

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  p {
    margin-bottom: 0;
  }
  .projects-section-btn {
    margin-top: 25px;
  }
}
.projects-slides-two {
  .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #d1cfdb;
      transition: var(--transition);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: radial-gradient(
          circle,
          #a66bff,
          #c666ef,
          #dd62df,
          #ee61cf,
          #fb64bf
        );
        width: 20px;
        border-radius: 30px;
      }
    }
  }
}

/*================================================
Projects Details Area CSS
=================================================*/
.projects-details-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.projects-details-desc {
  max-width: 1050px;
  margin: auto;

  .article-projects-with-info {
    .projects-info-image {
      position: relative;

      img {
        border-radius: 25px;
      }
      .info-shape {
        position: absolute;
        left: -55px;
        bottom: -50px;

        img {
          animation: {
            name: rotateme;
            duration: 5s;
            iteration-count: infinite;
            timing-function: linear;
          }
        }
      }
    }
    .projects-info-content {
      padding-left: 20px;

      span {
        font-size: var(--font-size);
        color: var(--main-color);
        font-weight: 600;
        display: inline-block;
        margin-bottom: 15px;
        font-family: var(--heading-font-family);
      }
      h3 {
        font-size: 42px;
        margin-bottom: 0;
        line-height: 1.4;
        position: relative;

        .overlay {
          position: absolute;
          left: 0;
          top: 0;
          background: var(--btn-gradient-color);
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
      .info-list {
        padding: 0;
        margin: {
          top: 25px;
          bottom: 0;
        }
        display: inline-block;
        li {
          font-family: var(--heading-font-family);
          font-weight: 400;
          color: var(--paragraph-color);
          list-style-type: none;
          margin-bottom: 10px;
          display: block;
          padding: 15px 30px;
          border-radius: 30px;
          background-color: var(--white-color);
          box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);

          &:last-child {
            margin-bottom: 0;
          }
          span {
            color: var(--optional-color);
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .article-projects-content {
    padding: {
      top: 35px;
      left: 45px;
    }
    .content {
      h3 {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 18px;
      }
      p {
        margin-bottom: 0;
      }
    }
    .image {
      overflow: hidden;
      border-radius: 15px;

      img {
        border-radius: 15px;
        transition: var(--transition);
      }
      &:hover {
        img {
          transform: scale(1.1);
          filter: blur(2px);
        }
      }
    }
    .projects-list {
      padding: 0;
      margin: {
        top: 30px;
        bottom: 0;
      }
      li {
        list-style-type: none;
        margin-bottom: 20px;
        color: var(--paragraph-color);
        position: relative;
        padding-left: 25px;
        font-weight: 500;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        i {
          position: absolute;
          left: 0;
          top: 2.8px;
          font-size: 18px;
          color: #a66bff;
        }
        h4 {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
    }
  }
  .article-projects-image {
    overflow: hidden;
    border-radius: 15px;
    margin-top: 35px;

    img {
      border-radius: 15px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1);
        filter: blur(2px);
      }
    }
  }
  .article-projects-quote {
    background-color: #f5f5f5;
    padding: 30px 30px 30px 90px;
    border-radius: 15px;
    margin-top: 30px;
    position: relative;
    z-index: 1;

    i {
      color: #cfc3e2;
      font-size: 65px;
      position: absolute;
      left: 45px;
      top: 10px;
      z-index: -1;
      animation: moveleftbounce 2s linear infinite;
    }
    p {
      font-size: 22px;
      font-weight: 400;
      color: var(--black-color);
      font-family: var(--heading-font-family);
      margin-bottom: 0;
    }
    .quote-shape {
      position: absolute;
      right: -45px;
      bottom: -45px;
      max-width: 100px;

      img {
        animation: {
          name: rotateme;
          duration: 5s;
          iteration-count: infinite;
          timing-function: linear;
        }
      }
    }
  }
  .article-projects-text {
    padding: {
      top: 35px;
      left: 45px;
      right: 30px;
    }
    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}
.projects-details-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-area {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .container-fluid {
    padding: {
      left: 0;
      right: 0;
    }
  }
}
.testimonials-section-content {
  padding-right: 35px;

  span {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--heading-font-family);
  }
  h3 {
    font-size: 42px;
    margin-bottom: 15px;
    line-height: 1.4;
    position: relative;

    b {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  .testimonials-btn {
    margin-top: 25px;
  }
}
.testimonials-item {
  .item-box {
    position: relative;
    border: 1px solid #f2f1f3;
    border-radius: 15px;
    padding: 30px 30px 30px 155px;
    margin-bottom: 25px;
    transition: var(--transition);

    &:last-child {
      margin-bottom: 0;
    }
    img {
      position: absolute;
      left: 30px;
      top: 28px;
      max-width: 100px;
    }
    h4 {
      font-size: var(--font-size);
      margin-bottom: 0;
      font-weight: 500;
      color: var(--optional-color);

      span {
        font-weight: 400;
        color: var(--paragraph-color);
      }
    }
    &:hover {
      border: 1px solid var(--white-color);
      box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
    }
  }
}
.single-testimonials-card {
  margin-bottom: 25px;

  p {
    background-color: #f2f1f3;
    padding: 30px;
    border-radius: 25px 25px 25px 0;
    font-size: 20px;
    font-weight: 400;
    font-family: var(--heading-font-family);
    color: var(--black-color);
  }
  .info-item-box {
    position: relative;
    margin-top: 25px;
    padding-left: 72px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      max-width: 55px;
    }
    h4 {
      font-size: var(--font-size);
      margin-bottom: 0;
      font-weight: 500;
      color: var(--optional-color);

      span {
        font-weight: 400;
        color: var(--paragraph-color);
      }
    }
    .rating-list {
      padding: 0;
      margin: {
        top: 15px;
        bottom: 0;
      }
      li {
        list-style-type: none;
        margin-right: 2px;
        display: inline-block;

        &:last-child {
          margin-right: 0;
        }
        i {
          color: #ffc744;
          font-size: var(--font-size);
        }
      }
    }
  }
}
.testimonials-slides {
  .swiper-pagination {
    position: initial;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #d1cfdb;
      transition: var(--transition);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: var(--btn-gradient-color);
        width: 20px;
        border-radius: 30px;
      }
    }
  }
}
.testimonialsbg-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &.with-black-background {
    background-color: var(--black-color);
    overflow: hidden;

    .section-title {
      h2 {
        color: var(--white-color);
      }
      p {
        color: #cdc4d9;
      }
    }
    .container-fluid {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
}
.single-pricing-card {
  background-color: #2e2141;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 25px;
  position: relative;

  h3 {
    background: radial-gradient(
      circle,
      #a66bff,
      #c666ef,
      #dd62df,
      #ee61cf,
      #fb64bf
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .price {
    font-size: 35px;
    color: var(--white-color);
    font-family: var(--heading-font-family);
    font-weight: bold;
    margin: {
      top: 15px;
      bottom: 15px;
    }
    span {
      color: #9b8dac;
      font-size: var(--font-size);
      font-weight: 400;
      position: relative;
      top: -2px;
    }
  }
  p {
    color: #cdc4d9;
    margin-bottom: 0;
  }
  .pricing-list {
    padding: 0;
    margin: {
      top: 25px;
      bottom: 0;
    }
    li {
      list-style-type: none;
      list-style-type: none;
      margin-bottom: 20px;
      color: #f2f1f3;
      position: relative;
      padding-left: 35px;

      &:last-child {
        margin-bottom: 0;
      }
      i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        height: 25px;
        width: 25px;
        line-height: 25px;
        background-color: var(--black-color);
        border: 1px solid #a66bff;
        border-radius: 50px;
        text-align: center;
        font-size: var(--font-size);
        transition: var(--transition);
      }
      &:hover {
        i {
          background-color: var(--main-color);
          border: 1px solid var(--main-color);
          color: var(--black-color);
        }
      }
    }
  }
  .pricing-btn {
    margin-top: 30px;
  }
  .most-popular {
    position: absolute;
    right: 20px;
    top: -15px;

    span {
      display: inline-block;
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      padding: 10px 20px;
      border-radius: 15px;
      color: var(--white-color);
      font-family: var(--heading-font-family);
      font-size: 15px;
      font-weight: 600;
    }
  }
}
.pricing-bg-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}
.pricing-shape-1 {
  position: absolute;
  top: 10%;
  right: 10%;
  transform: translateY(-10%) translateX(-10%);
  z-index: -1;
  animation: animationFramesOne 10s infinite linear;
}
.pricing-shape-2 {
  position: absolute;
  bottom: 5%;
  left: 25%;
  transform: translateY(-5%) translateX(-25%);
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.blog-card {
  margin-bottom: 25px;

  .blog-image {
    overflow: hidden;
    border-radius: 15px;

    a {
      display: block;

      img {
        border-radius: 15px;
        transition: var(--transition);
      }
    }
  }
  .blog-content {
    .date {
      display: inline-block;
      font-size: var(--font-size);
      font-weight: 500;
      font-family: var(--heading-font-family);
      padding: 12px 25px;
      border-radius: 50px;
      border: 1px solid var(--white-color);
      box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
      margin-bottom: 25px;
      color: var(--optional-color);
      transition: var(--transition);
    }
    h3 {
      font-size: 22px;
      line-height: 1.5;
      margin-bottom: 10px;
      font-weight: bold;

      a {
        color: var(--black-color);
      }
    }
    p {
      margin-bottom: 0;
    }
    .blog-btn {
      background: var(--btn-gradient-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-family: var(--heading-font-family);
      transition: var(--transition);
      position: relative;
      margin-top: 20px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 20px;
        height: 1.5px;
        background: var(--btn-gradient-color);
        transition: all ease 0.5s;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
      }
    }
  }
  &:hover {
    .blog-image {
      a {
        img {
          transform: scale(1.1);
          filter: blur(2px);
        }
      }
    }
    .blog-content {
      .date {
        background: radial-gradient(
          circle,
          #a66bff,
          #c666ef,
          #dd62df,
          #ee61cf,
          #fb64bf
        );
        color: var(--white-color);
      }
      h3 {
        a {
          color: var(--main-color);
        }
      }
      .blog-btn {
        padding-left: 25px;

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
.blog-slides {
  .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #d1cfdb;
      transition: var(--transition);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: radial-gradient(
          circle,
          #a66bff,
          #c666ef,
          #dd62df,
          #ee61cf,
          #fb64bf
        );
        width: 20px;
        border-radius: 30px;
      }
    }
  }
}
.blog-shape-1 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 5s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}
.blog-grid-sorting {
  margin-bottom: 45px;
  text-align: end;

  label {
    display: inline-block;
    margin-bottom: 0;
    color: var(--paragraph-color);
    margin-right: 5px;
    font-weight: 600;
    font-size: 15px;
  }
  .nice-select {
    display: inline-block;
    width: 300px;
    background: #f2f1f3;
    border-color: #f2f1f3;
    color: #9b8dac;
    transition: var(--transition);
    padding: 0 0 0 25px;
    height: 70px;
    line-height: 70px;
    font-weight: 400;
    border-radius: 15px;

    .list {
      background-color: var(--white-color);
      border-radius: 10px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      list-style-type: none;
      border: none;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-top: 10px;
      padding-bottom: 10px;

      .option {
        line-height: 38px;
        min-height: 38px;
        color: var(--black-color);
        position: relative;
        transition: var(--transition);
        padding-left: 15px;
        padding-right: 25px;
        font-weight: 600;

        &.selected {
          &.focus {
            color: var(--main-color);
            background-color: var(--white-color);
          }
        }
        &:hover {
          background-color: var(--main-color);
          color: var(--white-color);
        }
      }
    }
    &:after {
      border-color: var(--black-color);
      height: 8px;
      width: 8px;
      margin-top: -5px;
      right: 20px;
    }
    &:hover {
      border-color: var(--main-color);
      background-color: transparent;
    }
  }
  form {
    position: relative;
    max-width: 450px;

    .search-field {
      height: 70px;
      color: #9b8dac;
      border: 1px solid #f2f1f3;
      display: block;
      width: 100%;
      border-radius: 15px;
      padding: 15px 20px 15px 70px;
      transition: var(--transition);
      font-size: 15px;
      font-weight: 400;
      outline: 0;
      background: #f2f1f3;

      &::placeholder {
        color: #9b8dac;
        transition: var(--transition);
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
    button {
      border: none;
      background-color: transparent;
      color: var(--black-color);
      position: absolute;
      left: 32px;
      padding: 0;
      transition: var(--transition);
      top: 22.8px;
      font-size: 25px;
      line-height: 0;

      &:hover,
      &:focus {
        color: var(--main-color);
      }
    }
  }
}
.single-blog-card {
  margin-bottom: 25px;

  .blog-image {
    position: relative;
    overflow: hidden;
    border-radius: 15px;

    a {
      display: block;

      img {
        border-radius: 15px;
        transition: var(--transition);
      }
    }
    .date {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      font-family: var(--heading-font-family);
      padding: 12px 25px;
      border-radius: 50px;
      color: var(--white-color);
      position: absolute;
      left: 15px;
      bottom: 15px;
      transition: var(--transition);
    }
  }
  .blog-content {
    margin-top: 25px;

    h3 {
      font-size: 22px;
      line-height: 1.5;
      margin-bottom: 10px;
      font-weight: bold;

      a {
        color: var(--black-color);
      }
    }
    p {
      margin-bottom: 0;
    }
    .blog-btn {
      color: var(--optional-color);
      font-weight: 600;
      font-family: var(--heading-font-family);
      transition: var(--transition);
      position: relative;
      margin-top: 15px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 20px;
        height: 1.5px;
        background: var(--optional-color);
        transition: all ease 0.5s;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
      }
    }
  }
  &:hover {
    .blog-image {
      a {
        img {
          transform: scale(1.1);
        }
      }
      .date {
        animation: bounce 1s;
      }
    }
    .blog-content {
      h3 {
        a {
          color: var(--main-color);
        }
      }
      .blog-btn {
        padding-left: 25px;

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
  .article-image {
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;

    img {
      border-radius: 15px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    .video-btn {
      display: inline-block;
      width: 80px;
      height: 80px;
      line-height: 90px;
      background-color: var(--black-color);
      border-radius: 50%;
      color: var(--optional-color);
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      font-size: 45px;
      transition: var(--transition);
      border: 2px solid #fb64bf;

      i {
        transition: var(--transition);
        background: radial-gradient(
          circle,
          #a66bff,
          #c666ef,
          #dd62df,
          #ee61cf,
          #fb64bf
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border: 2px solid #fb64bf;
        animation: ripple 2s linear infinite;
      }
      &:hover {
        background-color: var(--optional-color);

        i {
          background: var(--btn-gradient-color);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .article-content {
    .entry-list {
      padding: 0;
      margin-bottom: 18px;

      li {
        color: var(--black-color);
        font-size: var(--font-size);
        font-family: var(--heading-font-family);
        font-weight: 400;
        list-style-type: none;
        display: inline-block;
        margin-right: 20px;
        position: relative;
        padding-left: 25px;

        &:last-child {
          margin-right: 0;
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 15px;
          height: 2.5px;
          background: var(--optional-color);
          transition: all ease 0.5s;
          border-radius: 50px;
        }
        a {
          color: var(--optional-color);
          font-weight: 500;
        }
      }
    }
    h3 {
      font-size: 42px;
      margin-bottom: 15px;
      line-height: 1.5;
    }
    .list {
      padding: 0;
      margin: {
        top: 30px;
        bottom: 0;
      }
      li {
        list-style-type: none;
        margin-bottom: 20px;
        color: var(--paragraph-color);
        position: relative;
        padding-left: 25px;
        font-weight: 500;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        i {
          position: absolute;
          left: 0;
          top: 2.8px;
          font-size: 18px;
          color: #a66bff;
        }
        h4 {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
    }
    .block-item {
      position: relative;
      overflow: hidden;
      border-radius: 15px;
      margin: {
        top: 30px;
        bottom: 30px;
      }
      img {
        transition: var(--transition);
        border-radius: 15px;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
    .article-quote {
      background-color: #f5f5f5;
      padding: 30px 30px 30px 90px;
      border-radius: 15px;
      position: relative;
      z-index: 1;
      margin: {
        top: 30px;
        bottom: 30px;
      }
      i {
        color: #cfc3e2;
        font-size: 65px;
        position: absolute;
        left: 45px;
        top: 10px;
        z-index: -1;
        animation: moveleftbounce 2s linear infinite;
      }
      p {
        font-size: 22px;
        font-weight: 400;
        color: var(--black-color);
        font-family: var(--heading-font-family);
        margin-bottom: 0;
      }
      .quote-shape {
        position: absolute;
        right: -45px;
        bottom: -45px;
        max-width: 100px;

        img {
          animation: {
            name: rotateme;
            duration: 5s;
            iteration-count: infinite;
            timing-function: linear;
          }
        }
      }
    }
  }
  .article-share {
    background-color: #f2f1f3;
    padding: 30px;
    border-radius: 10px;
    margin-top: 30px;

    .share-content {
      h4 {
        margin-bottom: 0;
        font-size: var(--font-size);
        font-weight: bold;
      }
    }
    .share-social {
      padding: 0;
      margin-bottom: 0;

      li {
        display: inline-block;
        list-style-type: none;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
        a {
          i {
            display: inline-block;
            transition: var(--transition);
            height: 35px;
            width: 35px;
            line-height: 35px;
            text-align: center;
            border-radius: 50px;
            background-color: var(--white-color);
            color: #9b8dac;
            font-size: 18px;

            &:hover {
              color: var(--white-color);
              background: radial-gradient(
                circle,
                #a66bff,
                #c666ef,
                #dd62df,
                #ee61cf,
                #fb64bf
              );
            }
          }
        }
      }
    }
  }
  .article-comments {
    margin-top: 35px;

    h3 {
      font-size: 22px;
      margin-bottom: 35px;
    }
    .comments-list {
      position: relative;
      padding-left: 150px;
      margin-bottom: 35px;

      img {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 20px;
        max-width: 128px;
      }
      h5 {
        font-size: 18px;
        margin-bottom: 12px;
        color: var(--optional-color);
        font-weight: 600;

        span {
          font-size: var(--font-size);
          font-weight: 400;
          color: #9b8dac;
        }
      }
      p {
        margin-bottom: 12px;
      }
      .reply-btn {
        font-size: var(--font-size);
        font-weight: 600;
        color: var(--main-color);
        font-family: var(--heading-font-family);
      }
      &.children {
        margin-left: 50px;
      }
    }
  }
  .article-leave-comment {
    border-top: 1px solid #f2f1f3;
    margin-top: 45px;
    padding-top: 35px;

    h3 {
      font-size: 22px;
      margin-bottom: 35px;
    }
    .form-group {
      margin-bottom: 25px;

      label {
        display: block;
        margin-bottom: 10px;
        color: var(--black-color);
        font-weight: 500;
        font-size: 15px;
      }
      .form-control {
        height: 55px;
        padding: 15px 20px;
        line-height: initial;
        color: #9b8dac;
        background-color: var(--white-color);
        border: 1px solid var(--white-color);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 15px;
        transition: var(--transition);
        font-size: var(--font-size);
        font-weight: 400;
        outline: 0;
        font-family: var(--heading-font-family);

        &::placeholder {
          color: #9b8dac;
        }
        &:focus {
          border: 1px solid var(--main-color);

          &::placeholder {
            color: transparent;
            transition: var(--transition);
          }
        }
      }
      textarea {
        &.form-control {
          min-height: 115px;
        }
      }
    }
    .form-cookies-consent {
      margin-bottom: 20px;

      a {
        color: var(--main-color);

        &:hover {
          color: var(--optional-color);
        }
      }
      [type="checkbox"]:checked,
      [type="checkbox"]:not(:checked) {
        display: none;
      }
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 0;
        color: var(--paragraph-color);
        font-weight: 400;
        font-size: 15px;
      }
      [type="checkbox"]:checked + label:before,
      [type="checkbox"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 15px;
        height: 15px;
        transition: all 0.2s ease;
        border: 1px solid #dddddd;
        border-radius: 5px;
        background: var(--white-color);
      }
      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after {
        content: "";
        width: 5px;
        height: 5px;
        background: var(--main-color);
        position: absolute;
        top: 10.5px;
        left: 5px;
        transition: all 0.2s ease;
        border-radius: 30px;
      }
      [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      [type="checkbox"]:hover + label:before {
        border-color: var(--main-color);
      }
      [type="checkbox"]:checked + label:before {
        border-color: var(--main-color);
      }
      p {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .default-btn {
      border: none;
    }
  }
}
/*================================================
Skill Area CSS
=================================================*/
.skill-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.skill-content {
  span {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--heading-font-family);
  }
  h3 {
    font-size: 42px;
    margin-bottom: 15px;
    line-height: 1.4;
    position: relative;

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}
.skill-bar {
  margin-top: 25px;

  .progress-title {
    font-size: var(--font-size);
    font-weight: 500;
    color: var(--black-color);
    font-family: var(--heading-font-family);
    margin-bottom: 10px;
  }
  .progress {
    border-radius: 30px;
    padding: 5px;
    background-color: transparent;
    .progress-bar {
      border-radius: 30px;
    }
  }
  .border-3e019a {
    border: 1px solid #3e019a;
  }
  .bg-3e019a {
    background-color: #3e019a;
  }
  .border-D5158F {
    border: 1px solid #D5158F;
  }
  .bg-D5158F {
    background-color: #D5158F;
  }
  .border-feb302 {
    border: 1px solid #feb302;
  }
  .bg-feb302 {
    background-color: #feb302;
  }
  .border-A66BFF {
    border: 1px solid #A66BFF;
  }
  .bg-A66BFF {
    background-color: #A66BFF;
  }
}
.skill-bar-btn {
  margin-top: 35px;
}
.skill-image {
  padding-left: 45px;
  position: relative;
  z-index: 1;

  .skill-shape-1 {
    position: absolute;
    right: 55px;
    top: -15px;

    img {
      animation: {
        name: rotateme;
        duration: 5s;
        iteration-count: infinite;
        timing-function: linear;
      }
    }
  }
  .skill-shape-2 {
    position: absolute;
    left: 0;
    bottom: -15px;

    img {
      animation: {
        name: rotateme;
        duration: 5s;
        iteration-count: infinite;
        timing-function: linear;
      }
    }
  }
}
.skill-bg-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

/*================================================
Features Area CSS
=================================================*/
.features-inner-box {
  position: relative;
  margin-top: -50px;
  z-index: 1;
}
.single-features-card {
  background-color: var(--white-color);
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.1);
  padding: 25px 25px 25px 140px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 25px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 0%;
    transition: all 0.5s ease-in-out;
    background: radial-gradient(
      circle,
      #a66bff,
      #c666ef,
      #dd62df,
      #ee61cf,
      #fb64bf
    );
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    transform: scale(1.5);
  }
  .features-image {
    position: absolute;
    left: 25px;
    top: 22px;
    max-width: 90px;
  }
  .content {
    transition: var(--transition);

    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 14px;

      a {
        color: var(--black-color);
      }
    }
    p {
      margin-bottom: 0;
      transition: var(--transition);
    }
  }
  .hover-content {
    position: absolute;
    top: 45px;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);

    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 14px;

      a {
        color: var(--black-color);
      }
    }
    .features-btn {
      background: var(--btn-gradient-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-family: var(--heading-font-family);
      transition: var(--transition);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 20px;
        height: 1.5px;
        background: var(--btn-gradient-color);
        transition: all ease 0.5s;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
      }
      &:hover {
        padding-left: 25px;

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &:hover {
    &::before {
      opacity: 1;
      visibility: visible;
      width: 100%;
      height: 100%;
    }
    .content {
      opacity: 0;
      visibility: hidden;

      h3 {
        a {
          color: var(--white-color);
        }
      }
      p {
        color: var(--white-color);
      }
    }
    .hover-content {
      opacity: 1;
      visibility: visible;

      h3 {
        a {
          color: var(--white-color);
        }
      }
      p {
        color: var(--white-color);
      }
    }
  }
}

/*================================================
Talk Area CSS
=================================================*/
.talk-area {
  background-color: #f5f5f5;
}
.talk-image {
  position: relative;
  z-index: 1;

  .talk-circle {
    position: absolute;
    bottom: -12px;
    left: -50px;

    img {
      animation: {
        name: rotateme;
        duration: 5s;
        iteration-count: infinite;
        timing-function: linear;
      }
    }
  }
}
.talk-content {
  position: relative;
  z-index: 1;
  margin-top: 45px;

  .talk-bg-text {
    color: #eeedee;
    font-size: 95px;
    font-family: var(--monoton-font-family);
    font-weight: 400;
    position: absolute;
    top: 0;
    z-index: -1;
    line-height: 0;
  }
  span {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    font-family: var(--heading-font-family);
  }
  h3 {
    font-size: 42px;
    margin-bottom: 15px;
    line-height: 1.4;
    position: relative;

    b {
      background: radial-gradient(
        circle,
        #a66bff,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--btn-gradient-color);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  p {
    margin-bottom: 0;
  }
  .contact-from {
    margin-top: 25px;

    .form-group {
      margin-bottom: 20px;

      .form-control {
        height: 55px;
        color: #9b8dac;
        box-shadow: unset !important;
        border: 1px solid var(--white-color);
        background-color: var(--white-color);
        transition: var(--transition);
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 15px;

        &::placeholder {
          color: #9b8dac;
          transition: var(--transition);
        }
        &:focus {
          border-color: var(--main-color);
          background-color: transparent;

          &::placeholder {
            color: transparent;
          }
        }
      }
      textarea {
        &.form-control {
          height: auto;
          padding-top: 15px;
        }
      }
    }
    .default-btn {
      border: none;
    }
    .list-unstyled {
      padding: 0;
      margin-bottom: 0;

      li {
        font-size: 14px;
        margin-top: 10px;
        color: red;
      }
    }
    .text-danger {
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  &.margin-zero {
    margin-top: 0;
  }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.single-team-card {
  margin-bottom: 25px;
  text-align: center;

  .team-image {
    position: relative;

    .team-social {
      padding: 0;
      margin-bottom: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: var(--transition);

      li {
        list-style-type: none;
        display: inline-block;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
        a {
          i {
            display: inline-block;
            height: 40px;
            width: 40px;
            line-height: 40px;
            background-color: var(--white-color);
            color: var(--black-color);
            text-align: center;
            font-size: 18px;
            border-radius: 50px;
            transition: var(--transition);

            &:hover {
              background-color: var(--main-color);
              color: var(--white-color);
            }
          }
        }
      }
    }
  }
  .team-content {
    margin-top: 25px;

    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 0;
    }
    span {
      font-size: 14px;
      color: var(--paragraph-color);
      font-weight: 600;
      display: inline-block;
      margin-top: 15px;
    }
  }
  &:hover {
    .team-image {
      position: relative;

      .team-social {
        bottom: 25px;
        opacity: 1;
      }
    }
  }
}
.team-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  z-index: -1;
}

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
  background-image: url(../../public/images/overview/overview-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.col-lg-3 {
  &:last-child {
    .overview-card {
      &::before {
        display: none;
      }
    }
  }
}
.overview-card {
  margin-bottom: 25px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    width: 80px;
    height: 2px;
    background-color: var(--white-color);
  }
  h3 {
    font-size: var(--font-size);
    font-weight: bold;
    margin-bottom: 0;
    color: var(--optional-color);
  }
  span {
    font-size: var(--font-size);
    font-family: var(--heading-font-family);
    color: var(--black-color);
    display: inline-block;
    margin-top: 15px;
    font-weight: 600;

    a {
      color: var(--black-color);

      &:hover {
        color: var(--main-color);
      }
    }
  }
  .overview-shape {
    position: absolute;
    left: -15px;
    top: -15px;
    z-index: -1;

    img {
      animation: {
        name: rotateme;
        duration: 5s;
        iteration-count: infinite;
        timing-function: linear;
      }
    }
  }
}

/*================================================
Page Banner Area CSS
=================================================*/
.page-banner-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; 
  padding: {
    top: 240px;
    bottom: 150px;
  }
}
.page-banner-content {
  h2 {
    font-size: 70px;
    margin-bottom: 20px;
    color: var(--white-color);
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      display: inline-block;
      list-style-type: none;
      font-size: var(--font-size);
      font-weight: 400;
      color: var(--main-color);
      position: relative;
      margin-left: 25px;

      &::before {
        content: "";
        position: absolute;
        left: -15px;
        top: 6px;
        height: 15px;
        width: 1px;
        background: #cdc4d9;
        transform: rotate(20deg);
      }
      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }
      }
      a {
        display: block;
        color: #cdc4d9;
        transition: var(--transition);

        &:hover {
          color: var(--main-color);
        }
      }
    }
  }
  &.wrap-content {
    h2 {
      color: var(--black-color);
    }
    ul {
      li {
        color: var(--main-color);

        &::before {
          background: var(--paragraph-color);
        }
        a {
          color: var(--paragraph-color);

          &:hover {
            color: var(--main-color);
          }
        }
      }
    }
  }
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
  position: relative;
  margin-bottom: 25px;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;

  a {
    display: block;
    border-radius: 10px;

    img {
      transition: var(--transition);
    }
  }
  &:hover {
    a {
      img {
        transform: scale(1.1);
        filter: blur(2px);
      }
    }
  }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion {
  max-width: 1050px;
  margin: auto;

  .accordion {
    border: none;
  }
  .accordion__item {
    border: 1px solid #e5e5e5;
    margin-bottom: 15px;
    transition: var(--transition);
    border-radius: 5px;

    &:last-child {
      margin-bottom: 0;
    }
    .accordion__button {
      color: var(--black-color);
      position: relative;
      text-align: start;
      box-shadow: unset;
      margin-bottom: 0;
      display: block;
      border: none;
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      padding: 20px 25px 20px 25px;
      transition: var(--transition);
      background-color: transparent;
      font-family: var(--heading-font-family);

      &::before {
        position: absolute;
        right: 20px;
        top: 28px;
      }
    }
    .accordion__panel {
      padding: 0 25px 20px;
      border-bottom: 5px solid var(--main-color);
      transition: var(--transition);
      border-radius: 0 0 5px 5px;

      p {
        color: var(--paragraph-color);
        font-size: 15px;
      }
    }
    &:hover {
      border-radius: 5px;
      border: 1px solid var(--main-color);

      .accordion__panel {
        border-radius: 5px;
      }
    }
  }
}

/*================================================
Events Area CSS
=================================================*/
.coze-grid-sorting {
  margin-bottom: 30px;

  .result-count {
    p {
      .count {
        font-weight: 600;
        background: radial-gradient(
          circle,
          #a66bff,
          #c666ef,
          #dd62df,
          #ee61cf,
          #fb64bf
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .ordering {
    text-align: end;

    label {
      display: inline-block;
      margin-bottom: 0;
      color: var(--paragraph-color);
      margin-right: 5px;
      font-weight: 600;
      font-size: 15px;
    }
    .nice-select {
      display: inline-block;
      width: 215px;
      background: #f8f8f8;
      border-color: #eeeeee;
      color: var(--black-color);
      transition: var(--transition);
      padding: 0 0 0 15px;
      height: 50px;
      line-height: 50px;
      font-weight: bold;
      border-radius: 5px;

      .list {
        background-color: var(--white-color);
        border-radius: 10px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        list-style-type: none;
        border: none;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        padding-top: 10px;
        padding-bottom: 10px;

        .option {
          line-height: 38px;
          min-height: 38px;
          color: var(--black-color);
          position: relative;
          transition: var(--transition);
          padding-left: 15px;
          padding-right: 25px;
          font-weight: 600;

          &.selected {
            &.focus {
              color: var(--main-color);
              background-color: var(--white-color);
            }
          }
          &:hover {
            background-color: var(--main-color);
            color: var(--white-color);
          }
        }
      }
      &:after {
        border-color: var(--black-color);
        height: 8px;
        width: 8px;
        margin-top: -5px;
        right: 15px;
      }
      &:hover {
        border-color: var(--main-color);
        background-color: transparent;
      }
    }
  }
}
.single-events-box {
  transition: var(--transition);
  background-color: var(--white-color);
  margin-bottom: 25px;

  .image {
    position: relative;
    overflow: hidden;
    border-radius: 15px 15px 0 0;

    .date {
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: inline-block;
      background-color: var(--white-color);
      color: var(--optional-color);
      padding: 8px 20px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      font-family: var(--heading-font-family);
    }
    img {
      border-radius: 15px 15px 0 0;
      transition: var(--transition);
    }
  }
  .content {
    padding: 25px 20px;
    position: relative;
    background-color: #f3f3f3;
    border-radius: 0 0 15px 15px;

    h3 {
      margin-bottom: 0;
      line-height: 1.5;
      font-size: 22px;
      font-weight: bold;

      a {
        display: inline-block;
      }
    }
    .location {
      display: block;
      color: var(--paragraph-color);
      margin-top: 20px;
      position: relative;
      padding-left: 22px;
      font-size: 14px;
      font-weight: 500;

      i {
        color: var(--main-color);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: var(--font-size);
      }
    }
  }
  &:hover {
    .image {
      img {
        transform: scale(1.1);
      }
    }
  }
}

/*================================================
Events Details Area CSS
=================================================*/
.events-details-area {
  background-color: #f9f9f9;

  .container-fluid {
    overflow: hidden;
    padding: {
      left: 0;
      right: 0;
    }
  }
}
.events-details-image {
  position: relative;
  margin-bottom: 50px;

  #timer {
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    bottom: 50px;

    div {
      background: var(--btn-gradient-color);
      color: var(--white-color);
      width: 165px;
      height: 150px;
      z-index: 1;
      border-radius: 10px;
      font-size: 60px;
      font-weight: bold;
      margin-left: 8px;
      margin-right: 8px;
      font-family: var(--heading-font-family);

      span {
        display: block;
        margin-top: -6px;
        font-size: var(--font-size);
        font-weight: 500;
        color: var(--black-color);
      }
    }
  }
}
.events-details-header {
  margin-bottom: 25px;

  .events-list {
    margin-bottom: 0;
    list-style-type: none;
    background-color: var(--white-color);
    padding: 30px;
    border-radius: 15px;
    border: 1px solid #f3f3f3;

    li {
      display: inline-block;
      margin-right: 20px;
      color: var(--paragraph-color);
      position: relative;
      padding-left: 25px;
      font-size: 14px;
      font-weight: 500;

      i {
        color: var(--main-color);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.events-details-location {
  margin-bottom: 25px;

  iframe {
    height: 450px;
    width: 100%;
    border: none;
    border-radius: 15px;
  }
}
.events-details-desc {
  h3 {
    margin-bottom: 20px;
    font-size: 22px;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}
.events-details-info {
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 25px 30px 30px;
  border: 1px solid #f3f3f3;

  .info {
    margin-bottom: 0;
    list-style-type: none;
    padding-left: 0;

    li {
      border-bottom: 1px solid #f3f3f3;
      color: var(--paragraph-color);
      font-size: var(--font-size);
      font-weight: 500;
      padding-top: 15px;
      padding-bottom: 15px;

      span {
        font-weight: 600;
        color: var(--black-color);
        position: relative;
      }
      &.price {
        padding-bottom: 10px;
        color: var(--main-color);
        font-size: 28px;
        font-weight: bold;

        span {
          color: var(--black-color);
          font-size: var(--font-size);
          font-weight: bold;
        }
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
  .events-btn-box {
    text-align: center;
    margin-top: 30px;

    p {
      margin-top: 20px;

      a {
        color: var(--main-color);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .events-share {
    text-align: center;
    margin-top: 30px;

    .share-info {
      display: inline-block;
      position: relative;
      cursor: pointer;

      span {
        display: inline-block;
        color: var(--main-color);
        line-height: 1;
        font-size: var(--font-size);
        font-weight: bold;
        font-family: var(--heading-font-family);
      }
      .social-link {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 12px;

        li {
          display: inline-block;
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
          a {
            i {
              display: inline-block;
              width: 35px;
              height: 35px;
              line-height: 35px;
              text-align: center;
              background-color: #e1e1e1;
              font-size: var(--font-size);
              color: var(--black-color);
              position: relative;
              border-radius: 50px;
              transition: var(--transition);

              &:hover {
                background-color: var(--main-color);
                color: var(--white-color);
                transform: translateY(-5px);
              }
            }
          }
        }
      }
    }
  }
}

/*================================================
Career Area CSS
=================================================*/
.career-item {
  border: 1px solid #f5f5f5;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
  .career-content {
    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 16.5px;

      a {
        color: var(--black-color);

        &:hover {
          color: var(--main-color);
        }
      }
    }
    p {
      margin-bottom: 18px;
    }
    .list {
      padding: 0;
      margin-bottom: 0;

      li {
        font-family: var(--heading-font-family);
        list-style-type: none;
        display: inline-block;
        margin-right: 15px;
        color: var(--paragraph-color);
        font-weight: 500;

        &:last-child {
          margin-right: 0;
        }
        span {
          color: var(--main-color);
          font-weight: 500;
        }
      }
    }
  }
}

/*================================================
Apply Area CSS
=================================================*/
.apply-area {
  background-image: url(../../public/images/apply-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.apply-form-wrap {
  background-color: var(--white-color);
  padding: 45px;
  border-radius: 15px;
  max-width: 550px;

  .content {
    margin-bottom: 30px;

    h3 {
      font-size: 22px;
      margin-bottom: 0;
    }
  }
  .apply-form {
    .form-group {
      margin-bottom: 15px;

      .form-control {
        height: 55px;
        color: var(--paragraph-color);
        box-shadow: unset !important;
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        transition: var(--transition);
        border-radius: 10px;
        padding: 15px 20px;
        font-size: 15px;
        font-weight: 400;

        &::placeholder {
          color: var(--paragraph-color);
          transition: var(--transition);
        }
        &:focus {
          border-color: var(--main-color);
          background-color: transparent;

          &::placeholder {
            color: transparent;
          }
        }
      }
      textarea {
        &.form-control {
          height: 100px;
          padding-top: 15px;
        }
      }
      .nice-select {
        display: inline-block;
        width: 100%;
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        color: var(--paragraph-color);
        transition: var(--transition);
        padding: 0 0 0 20px;
        height: 55px;
        line-height: 55px;
        font-size: 15px;
        font-weight: 400;
        border-radius: 10px;

        .list {
          background-color: var(--white-color);
          border-radius: 10px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          list-style-type: none;
          border: none;
          width: 100%;
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 0;
          padding-top: 10px;
          padding-bottom: 10px;

          .option {
            line-height: 38px;
            min-height: 38px;
            color: var(--black-color);
            position: relative;
            transition: var(--transition);
            padding-left: 15px;
            padding-right: 25px;
            font-weight: 400;

            &.selected {
              &.focus {
                color: var(--main-color);
                background-color: var(--white-color);
              }
            }
            &:hover {
              background-color: var(--main-color);
              color: var(--white-color);
            }
          }
        }
        &:after {
          border-color: var(--paragraph-color);
          height: 8px;
          width: 8px;
          margin-top: -5px;
          right: 20px;
        }
        &:hover {
          border-color: var(--main-color);
          background-color: transparent;
        }
      }
    }
    .default-btn {
      border: none;
      width: 100%;
      padding: 20px 25px;

      &::before {
        width: 850px;
        height: 850px;
      }
    }
  }
}

/*================================================
Career Details Area CSS
=================================================*/
.career-details-desc {
  h3 {
    font-size: 35px;
    margin-bottom: 0;
  }
  .career-content {
    margin-top: 35px;

    h4 {
      font-size: 22px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .list {
      padding: 0;
      margin-bottom: 0;

      li {
        list-style-type: none;
        color: var(--paragraph-color);
        margin-bottom: 15px;
        position: relative;
        padding-left: 20px;

        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 7.5px;
          display: inline-block;
          height: 10px;
          width: 10px;
          background-color: var(--main-color);
          border-radius: 50px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .career-details-image {
    margin-top: 35px;
    overflow: hidden;
    border-radius: 15px;
    position: relative;

    img {
      border-radius: 15px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    .video-btn {
      display: inline-block;
      width: 80px;
      height: 80px;
      line-height: 90px;
      background-color: var(--black-color);
      border-radius: 50%;
      color: var(--optional-color);
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      font-size: 45px;
      transition: var(--transition);
      border: 2px solid #fb64bf;

      i {
        transition: var(--transition);
        background: radial-gradient(
          circle,
          #a66bff,
          #c666ef,
          #dd62df,
          #ee61cf,
          #fb64bf
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border: 2px solid #fb64bf;
        animation: ripple 2s linear infinite;
      }
      &:hover {
        background-color: var(--optional-color);

        i {
          background: var(--btn-gradient-color);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
.career-details-info {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 30px;

  h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
    position: relative;
    padding-bottom: 15px;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      background: var(--optional-color);
      height: 2px;
      width: 50px;
    }
  }
  .list {
    padding: 0;
    margin: {
      top: 25px;
      bottom: 0;
    }
    display: inline-block;
    li {
      list-style-type: none;
      margin-bottom: 20px;
      font-family: var(--heading-font-family);
      font-weight: 400;
      color: var(--paragraph-color);

      &:last-child {
        margin-bottom: 0;
      }
      span {
        color: var(--black-color);
        font-weight: 600;
        font-family: var(--heading-font-family);
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
  padding: 35px;
  border-radius: 15px;
  background-color: var(--white-color);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  max-width: 650px;
  margin: auto;
  border: 1px solid #e1e1e1;

  h2 {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  form {
    .form-group {
      margin-bottom: 25px;

      label {
        display: block;
        margin-bottom: 8px;
        color: var(--paragraph-color);
        font-weight: 500;
        font-size: var(--font-size);
      }
      .form-control {
        height: 55px;
        padding: 20px;
        line-height: initial;
        color: var(--btn-gradient-color);
        background-color: transparent;
        border: 1px solid #eeeeee;
        border-radius: 10px;
        box-shadow: unset;
        transition: var(--transition);
        font-size: 15px;
        font-weight: 500;

        &:focus {
          border: 1px solid --main-color;
          background-color: transparent;

          &::placeholder {
            color: transparent;
            transition: var(--transition);
          }
        }
      }
    }
    .form-check-input {
      &:focus {
        border-color: var(--main-color);
        box-shadow: unset;
      }
    }
    .lost-your-password {
      text-align: end;

      a {
        display: inline-block;
        position: relative;
        font-weight: 500;
        font-size: 15px;
        color: var(--paragraph-color);

        &::before {
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          transition: var(--transition);
          background-color: #eeeeee;
        }
        &::after {
          width: 0;
          height: 1px;
          position: absolute;
          left: 0;
          transition: var(--transition);
          bottom: 0;
          content: "";
          background-color: var(--main-color);
        }
        &:hover {
          &::before {
            width: 0;
          }
          &::after {
            width: 100%;
          }
        }
      }
    }
    .default-btn {
      margin-top: 25px;
      padding: 15px 35px;
      width: 100%;
      border: none;

      &::before {
        width: 850px;
        height: 850px;
      }
    }
  }
}
.register-form {
  padding: 35px;
  border-radius: 15px;
  background-color: var(--white-color);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  max-width: 650px;
  margin: auto;
  border: 1px solid #e1e1e1;

  h2 {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  form {
    .form-group {
      margin-bottom: 25px;

      label {
        display: block;
        margin-bottom: 8px;
        color: var(--paragraph-color);
        font-weight: 500;
        font-size: var(--font-size);
      }
      .form-control {
        height: 55px;
        padding: 20px;
        line-height: initial;
        color: var(--black-color);
        background-color: transparent;
        border: 1px solid #eeeeee;
        border-radius: 10px;
        box-shadow: unset;
        transition: var(--transition);
        font-size: 15px;
        font-weight: 500;

        &:focus {
          border: 1px solid --main-color;
          background-color: transparent;

          &::placeholder {
            color: transparent;
            transition: var(--transition);
          }
        }
      }
    }
    .description {
      font-style: italic;
      font-size: 14px;
      margin-top: -5px;
      margin-bottom: 15px;
    }
    .default-btn {
      margin-top: 10px;
      padding: 15px 35px;
      width: 100%;
      border: none;

      &::before {
        width: 850px;
        height: 850px;
      }
    }
  }
}

/*================================================
Membership Levels Area CSS
=================================================*/
.membership-levels-table {
  .table {
    margin-bottom: 0;
    border: 1px solid #f5f5f5;

    thead {
      th {
        vertical-align: middle;
        background-color: var(--main-color);
        white-space: nowrap;
        padding: 20px;
        text-align: center;
        color: var(--white-color);
        border-left: 1px solid var(--white-color);
        border-right: 1px solid var(--white-color);
        border-bottom: none;

        .desc-1 {
          color: var(--white-color);
          margin-bottom: 0;
          font-size: 30px;
          font-weight: bold;
          display: block;
          font-family: var(--heading-font-family);
          line-height: 1;
        }
        .desc-2 {
          color: var(--white-color);
          margin-top: 18px;
          margin-bottom: 10px;
          font-size: var(--font-size);
          font-weight: 400;
          display: block;
        }
        .desc-3 {
          color: var(--white-color);
          font-size: var(--font-size);
          font-weight: 400;
          display: block;
        }
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 20px 25px;
          white-space: nowrap;
          vertical-align: middle;
          border: none;
          text-align: center;
          font-size: var(--font-size);
          font-weight: 600;

          &:first-child {
            text-align: start;
          }
          &.item-check {
            i {
              width: 20px;
              height: 20px;
              line-height: 20px;
              border-radius: 100%;
              background-color: #0eb582;
              color: var(--white-color);
              display: block;
              margin: 0 auto;
            }
          }
          &.item-none {
            i {
              width: 20px;
              height: 20px;
              line-height: 20px;
              border-radius: 100%;
              background-color: var(--optional-color);
              color: var(--white-color);
              display: block;
              margin: 0 auto;
            }
          }
          .select-btn {
            display: inline-block;
            color: var(--black-color);
            background-color: #f5f5f5;
            padding: 15px 35px;
            border-radius: 10px;
            font-size: var(--font-size);
            font-weight: 600;

            &:hover {
              color: var(--white-color);
              background-color: var(--main-color);
            }
          }
        }
      }
      &:not(:first-child) {
        border-top: unset;
      }
    }
  }
}

/*================================================
Purchase Guide Area CSS
=================================================*/
.purchase-guide-content {
  .guide-image {
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 30px;

    img {
      border-radius: 15px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  h3 {
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: bold;
  }
  .blockquote,
  blockquote {
    margin-top: 25px;
    margin-bottom: 30px;
    background-color: #e6f2f5;
    text-align: left !important;
    padding: 25px !important;
    border-radius: 10px;

    p {
      margin-bottom: 0;
      line-height: 1.8;
      font-size: 15px !important;
      font-weight: 500;
    }
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
  ol,
  ul {
    margin-top: 20px;

    li {
      margin-bottom: 10px;
      color: var(--paragraph-color);
      line-height: 1.8;
      font-weight: 500;
      font-size: 15px;
    }
  }
}

/*================================================
Terms of Service Area CSS
=================================================*/
.terms-of-service-content {
  .terms-of-service-image {
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 30px;

    img {
      border-radius: 15px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  h3 {
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: bold;
  }
  .blockquote,
  blockquote {
    margin-top: 25px;
    margin-bottom: 30px;
    background-color: #e6f2f5;
    text-align: left !important;
    padding: 25px !important;
    border-radius: 10px;

    p {
      margin-bottom: 0;
      line-height: 1.8;
      font-size: 15px !important;
      font-weight: 500;
    }
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
  ol,
  ul {
    margin-top: 20px;

    li {
      margin-bottom: 10px;
      color: var(--paragraph-color);
      line-height: 1.8;
      font-weight: 500;
      font-size: 15px;
    }
  }
}

/*================================================
Privacy Policy Area CSS
=================================================*/
.privacy-policy-content {
  .privacy-policy-image {
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 30px;

    img {
      border-radius: 15px;
      transition: var(--transition);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  h3 {
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: bold;
  }
  .blockquote,
  blockquote {
    margin-top: 25px;
    margin-bottom: 30px;
    background-color: #e6f2f5;
    text-align: left !important;
    padding: 25px !important;
    border-radius: 10px;

    p {
      margin-bottom: 0;
      line-height: 1.8;
      font-size: 15px !important;
      font-weight: 500;
    }
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
  ol,
  ul {
    margin-top: 20px;

    li {
      margin-bottom: 10px;
      color: var(--paragraph-color);
      line-height: 1.8;
      font-weight: 500;
      font-size: 15px;
    }
  }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-content {
  text-align: center;

  h3 {
    margin-top: 35px;
    margin-bottom: 20px;
    font-size: 30px;
  }
  p {
    max-width: 550px;
    margin: auto;
  }
  .default-btn {
    margin-top: 25px;
  }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  background-image: url(../../public/images/coming-soon-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 120px;
  padding-bottom: 120px;
}
.coming-soon-content {
  max-width: 650px;
  background-color: var(--white-color);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 35px;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  margin: 0 auto 0 0;
  transition: var(--transition);
  border: 1px solid var(--white-color);

  .logo {
    display: inline-block;
  }
  h2 {
    font-size: 35px;
    margin-top: 30px;
    margin-bottom: 0;
  }
  #timer {
    margin-top: 35px;

    div {
      background: var(--btn-gradient-color);
      color: var(--white-color);
      width: 130px;
      height: 130px;
      border-radius: 50%;
      font-size: 40px;
      font-weight: bold;
      margin-left: 5px;
      margin-right: 5px;
      transition: var(--transition);

      &:hover {
        transform: translateY(-5px);
      }
      span {
        display: block;
        margin-top: -2px;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  form {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;

    .form-group {
      margin-bottom: 15px;

      .form-control {
        height: 55px;
        padding: 15px 20px;
        line-height: initial;
        color: var(--paragraph-color);
        background-color: var(--white-color);
        border: 1px solid #e5e7ec;
        box-shadow: -12px 8px 25px rgba(217, 35, 12, 0.03);
        border-radius: 20px;
        transition: var(--transition);
        font-size: var(--font-size);
        font-weight: 400;

        &::placeholder {
          color: var(--paragraph-color);
        }
        &:focus {
          &::placeholder {
            color: transparent;
            transition: var(--transition);
          }
        }
      }
    }
    .default-btn {
      width: 100%;
      border: none;
      border-radius: 20px;

      &::before {
        width: 850px;
        height: 850px;
      }
    }
    .validation-danger {
      margin-top: 15px;
      color: red;
    }
    .validation-success {
      margin-top: 15px;
    }
  }
  &:hover {
    border: 1px solid var(--main-color);
  }
}

/*================================================
Courses Area CSS
=================================================*/
.single-courses-box {
  margin-bottom: 25px;
  transition: var(--transition);

  .courses-image {
    border-radius: 10px 10px 0 0;
    position: relative;
    z-index: 1;

    img {
      border-radius: 10px 10px 0 0;
      transition: var(--transition);
    }
    .price {
      display: inline-block;
      background: var(--btn-gradient-color);
      color: var(--white-color);
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: absolute;
      right: 20px;
      bottom: -32.5px;
      text-align: center;
      line-height: 65px;
      font-size: 25px;
      font-weight: 500;
      transition: var(--transition);
    }
  }
  .courses-content {
    border-radius: 0 0 10px 10px;
    padding: 25px;
    background-color: var(--white-color);
    box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);

    .course-author {
      margin-bottom: 20px;

      img {
        width: 45px;
        height: 45px;
        margin-right: 10px;
      }
      span {
        color: var(--optional-color);
        font-size: var(--font-size);
        font-weight: 600;
        font-family: var(--heading-font-family);
      }
    }
    h3 {
      margin-bottom: 15px;
      line-height: 1.5;
      font-size: 22px;
      font-weight: bold;

      a {
        display: inline-block;
      }
    }
    .courses-box-footer {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
      margin-left: -7px;
      margin-right: -7px;
      margin-top: 25px;

      li {
        color: var(--paragraph-color);
        font-size: 15px;
        font-weight: 500;
        position: relative;
        padding-left: 30px;
        padding-right: 8px;

        i {
          color: var(--main-color);
          position: absolute;
          left: 6px;
          top: 50%;
          transform: translateY(-50%);
          font-size: var(--font-size);
        }
      }
    }
  }
  &:hover {
    transform: translateY(-5px);

    .courses-image {
      .price {
        animation: bounce 1s;
      }
    }
  }
}

/*================================================
Courses Details Area CSS
=================================================*/
.courses-details-area {
  background-color: #f5f5f5;

  .container-fluid {
    padding: {
      left: 0;
      right: 0;
    }
    overflow: hidden;
  }
}
.courses-details-image {
  position: relative;

  img {
    transition: var(--transition);
  }
  .video-btn {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 90px;
    background-color: var(--main-color);
    border-radius: 50px;
    color: var(--white-color);
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    i {
      font-size: 55px;
      position: relative;
      top: 12px;
      left: 2px;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      border-radius: 50px;
      border: 1px solid var(--main-color);
      animation: ripple 2s linear 1s infinite;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      border-radius: 50px;
      border: 1px solid var(--main-color);
      animation: ripple 2s linear infinite;
    }
    &:hover {
      background-color: var(--optional-color);
      color: var(--white-color);
    }
  }
}
.courses-details-desc {
  margin-top: 50px;

  .nav {
    margin-bottom: 0;
    list-style-type: none;
    background-color: var(--white-color);
    border: none;
    border-radius: 15px;

    .nav-item {
      margin-bottom: 0;
      margin-right: 20px;

      .nav-link {
        border-radius: 0;
        border: none;
        padding: 0;
        transition: var(--transition);
        padding: 30px;
        border-radius: 15px;
        font-size: var(--font-size);
        font-weight: bold;
        color: var(--black-color);

        &:hover,
        &.active {
          color: var(--white-color);
          background-color: var(--main-color);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .tab-content {
    margin-top: 45px;

    .courses-overview {
      h3 {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: bold;

        &:not(:first-child) {
          margin-top: 25px;
        }
      }
    }
    .courses-curriculum {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      padding: 30px;

      h3 {
        margin-bottom: 18px;
        font-size: 20px;
        font-weight: bold;

        &:not(:first-child) {
          margin-top: 20px;
        }
      }
      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          display: block;

          a {
            background-color: #f8f9f8;
            color: var(--black-color);
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-left: -30px;
            margin-right: -30px;

            .courses-name {
              font-size: var(--font-size);
              font-weight: 500;
              color: var(--paragraph-color);
              transition: var(--transition);

              &:hover {
                color: var(--main-color);
              }
            }
            .courses-meta {
              text-align: right;

              .questions {
                display: inline-block;
                background: var(--main-color);
                color: var(--white-color);
                border-radius: 5px;
                margin-right: 5px;
                padding: 5px 10px;
                font-size: 15px;
                font-weight: 500;
                transition: var(--transition);

                &:hover {
                  background-color: var(--optional-color);
                  color: var(--white-color);
                }
              }
              .duration {
                display: inline-block;
                background: var(--optional-color);
                color: var(--white-color);
                border-radius: 5px;
                padding: 5px 10px;
                font-size: 15px;
                font-weight: 500;
                transition: var(--transition);

                &:hover {
                  background-color: var(--main-color);
                  color: var(--white-color);
                }
              }
              .status {
                display: inline-block;
                background: var(--black-color);
                color: var(--white-color);
                border-radius: 5px;
                margin-left: 5px;
                padding: 5px 10px;
                font-size: 15px;
                font-weight: 500;
                transition: var(--transition);

                &:hover {
                  background-color: var(--main-color);
                  color: var(--white-color);
                }
                &.locked {
                  color: var(--black-color);
                  background-color: transparent;
                  padding: 0;
                  margin-left: 8px;
                  border-radius: 0;
                  position: relative;
                  top: 1px;
                  font-size: 18px;
                  font-weight: normal;
                  transition: var(--transition);

                  &:hover {
                    color: var(--main-color);
                  }
                }
              }
            }
            &:hover {
              color: var(--main-color);
            }
          }
        }
      }
    }
    .courses-instructor {
      .instructor-image {
        img {
          border-radius: 15px;
        }
      }
      .instructor-content {
        padding-left: 15px;

        h3 {
          font-size: 20px;
          margin-bottom: 18px;
          font-weight: bold;
        }
        span {
          font-weight: 500;
          color: var(--main-color);
          display: inline-block;
          margin-bottom: 15px;
          font-size: 15px;
        }
        p {
          margin-bottom: 20px;
        }
        .social-link {
          padding-left: 0;
          margin-bottom: 0;
          margin-top: 25px;

          li {
            list-style-type: none;
            display: inline-block;
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }
            a {
              i {
                display: inline-block;
                width: 38px;
                height: 38px;
                line-height: 38px;
                text-align: center;
                background: var(--btn-gradient-color);
                transition: var(--transition);
                color: var(--white-color);
                border-radius: 50px;
                font-size: 18px;

                &:hover {
                  transform: translateY(-5px);
                }
              }
            }
          }
        }
      }
    }
    .courses-reviews {
      h3 {
        margin-bottom: 15px;
        display: inline-block;
        font-size: 22px;
        font-weight: bold;
      }
      .rating {
        span {
          font-size: var(--font-size);
          color: #cecfd2;

          &.checked {
            color: orange;
          }
        }
      }
      .rating-count {
        margin-top: 10px;
        margin-bottom: 20px;

        span {
          display: block;
          font-size: 15px;
          color: var(--paragraph-color);
          font-weight: 500;
        }
      }
      .row {
        overflow: hidden;
        margin-left: 0;
        margin-right: 0;
      }
      .side {
        float: left;
        width: 8%;
        margin-top: 10px;
        padding-left: 0;
        padding-right: 0;

        div {
          font-size: var(--font-size);
          font-weight: 600;
          font-family: var(--heading-font-family);
        }
      }
      .middle {
        margin-top: 14px;
        float: left;
        width: 82%;
      }
      .right {
        text-align: end;
      }
      .bar-container {
        width: 100%;
        background-color: var(--white-color);
        text-align: center;
        border-radius: 5px;
      }
      .bar-5 {
        width: 100%;
        height: 18px;
        background-color: #4caf50;
        border-radius: 5px;
      }
      .bar-4 {
        width: 75%;
        height: 18px;
        background-color: #2196f3;
        border-radius: 5px;
        border-radius: 5px;
      }
      .bar-3 {
        width: 50%;
        height: 18px;
        background-color: #00bcd4;
        border-radius: 5px;
      }
      .bar-2 {
        width: 25%;
        height: 18px;
        background-color: #ff9800;
        border-radius: 5px;
      }
      .bar-1 {
        width: 0;
        height: 18px;
        background-color: #f44336;
        border-radius: 5px;
      }
    }
    .courses-review-comments {
      margin-top: 40px;

      h3 {
        border-bottom: 1px solid var(--white-color);
        padding-bottom: 12px;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
      }
      .user-review {
        border-bottom: 1px solid var(--white-color);
        padding: 20px 0 20px 110px;
        position: relative;

        img {
          position: absolute;
          left: 0;
          top: 20px;
          width: 90px;
          height: 90px;
          border-radius: 20px;
        }
        .sub-comment {
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 15px;
          color: var(--main-color);
        }
        p {
          font-size: 15px;
        }
        .review-rating {
          display: block;
          margin-bottom: 8px;

          .review-stars {
            display: inline-block;

            i {
              color: #cecfd2;
              font-size: 15px;
              display: inline-block;
              margin-right: -2px;

              &.checked {
                color: orange;
              }
            }
          }
          span {
            color: var(--black-color);
            position: relative;
            top: -2px;
            font-weight: 600;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.courses-details-info {
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 12px 12px 35px 12px;
  margin-top: 50px;
  border: 1px solid #f3f3f3;

  .image {
    text-align: center;
    position: relative;

    img {
      border-radius: 15px;
    }
    .video-btn {
      display: inline-block;
      width: 70px;
      height: 70px;
      line-height: 70px;
      background-color: var(--white-color);
      border-radius: 50%;
      color: var(--black-color);
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      left: 0;
      margin: auto;
      text-align: center;
      transition: var(--transition);

      i {
        font-size: 50px;
        position: relative;
        top: 10px;
        left: 2px;
        color: var(--main-color);
        transition: var(--transition);
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border: 2px solid var(--white-color);
        animation: ripple 2s linear infinite;
      }
      &:hover {
        background-color: var(--main-color);

        i {
          color: var(--white-color);
        }
      }
    }
  }
  .info {
    margin-bottom: 0;
    list-style-type: none;
    padding: 30px 20px;

    li {
      border-bottom: 1px solid #e5e5e7;
      color: var(--paragraph-color);
      font-size: var(--font-size);
      font-weight: 500;
      padding-top: 15px;
      padding-bottom: 15px;

      span {
        font-weight: 600;
        color: var(--black-color);
      }
      &.price {
        padding-bottom: 10px;
        color: var(--main-color);
        font-size: 28px;
        font-weight: bold;
        font-family: var(--heading-font-family);

        span {
          color: var(--black-color);
          font-size: var(--font-size);
          font-weight: bold;
        }
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
  .courses-btn-box {
    text-align: center;
  }
  .courses-share {
    text-align: center;
    margin-top: 25px;

    .share-info {
      display: inline-block;
      position: relative;
      cursor: pointer;

      span {
        display: inline-block;
        color: var(--main-color);
        line-height: 1;
        font-size: var(--font-size);
        font-weight: 500;
      }
      .social-link {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 12px;

        li {
          display: inline-block;
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
          a {
            i {
              display: inline-block;
              width: 35px;
              height: 35px;
              line-height: 35px;
              text-align: center;
              background: var(--btn-gradient-color);
              font-size: var(--font-size);
              color: var(--white-color);
              position: relative;
              border-radius: 50px;
              transition: var(--transition);

              &:hover {
                transform: translateY(-5px);
              }
            }
          }
        }
      }
    }
  }
}

/*================================================
Products Area CSS
=================================================*/
.products-grid-sorting {
  margin-bottom: 30px;

  .ordering {
    text-align: end;

    label {
      display: inline-block;
      margin-bottom: 0;
      color: var(--paragraph-color);
      margin-right: 5px;
      font-weight: 600;
      font-size: 15px;
    }
    .nice-select {
      display: inline-block;
      width: 100%;
      background: #f2f1f3;
      border-color: #f2f1f3;
      color: #9b8dac;
      transition: var(--transition);
      padding: 0 0 0 15px;
      height: 70px;
      line-height: 70px;
      font-weight: 400;
      border-radius: 15px;

      .list {
        background-color: var(--white-color);
        border-radius: 10px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        list-style-type: none;
        border: none;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        padding-top: 10px;
        padding-bottom: 10px;

        .option {
          line-height: 38px;
          min-height: 38px;
          color: var(--black-color);
          position: relative;
          transition: var(--transition);
          padding-left: 15px;
          padding-right: 25px;
          font-weight: 600;

          &.selected {
            &.focus {
              color: var(--main-color);
              background-color: var(--white-color);
            }
          }
          &:hover {
            background-color: var(--main-color);
            color: var(--white-color);
          }
        }
      }
      &:after {
        border-color: var(--black-color);
        height: 8px;
        width: 8px;
        margin-top: -5px;
        right: 15px;
      }
      &:hover {
        border-color: var(--main-color);
        background-color: transparent;
      }
    }
    form {
      position: relative;

      .search-field {
        height: 70px;
        color: #9b8dac;
        border: 1px solid #f2f1f3;
        display: block;
        width: 100%;
        border-radius: 15px;
        padding: 15px 20px 15px 70px;
        transition: var(--transition);
        font-size: 15px;
        font-weight: 400;
        outline: 0;
        background: #f2f1f3;

        &::placeholder {
          color: #9b8dac;
          transition: var(--transition);
        }
        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
      button {
        border: none;
        background-color: transparent;
        color: var(--black-color);
        position: absolute;
        left: 32px;
        padding: 0;
        transition: var(--transition);
        top: 22.8px;
        font-size: 25px;
        line-height: 0;

        &:hover,
        &:focus {
          color: var(--main-color);
        }
      }
    }
  }
}
.single-products-card {
  margin-bottom: 25px;

  .products-image {
    overflow: hidden;
    border-radius: 15px;
    position: relative;
    text-align: center;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: var(--transition);
      visibility: hidden;
      background: radial-gradient(
        circle,
        #fb64bf,
        #c666ef,
        #dd62df,
        #ee61cf,
        #fb64bf
      );
      border-radius: 15px;
      z-index: 1;
    }
    a {
      display: block;

      img {
        border-radius: 15px;
        transition: var(--transition);
      }
    }
    .heart-line {
      position: absolute;
      right: 20px;
      top: 20px;
      opacity: 1;
      visibility: visible;
      transition: var(--transition);
      z-index: 1;

      a {
        i {
          color: #ff3666;
          font-size: 25px;
          transition: var(--transition);
        }
      }
    }
    .heart-fill {
      position: absolute;
      right: 20px;
      top: 20px;
      opacity: 0;
      visibility: hidden;
      transition: var(--transition);
      z-index: 1;

      a {
        i {
          color: #ff3666;
          font-size: 25px;
          transition: var(--transition);
        }
      }
    }
    .add-to-cart-btn {
      position: absolute;
      left: -30px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: var(--transition);

      .default-btn {
        display: inline-block;
      }
    }
    .sale {
      position: absolute;
      left: 10px;
      bottom: 10px;
      z-index: 1;
      display: inline-block;
      background-color: #a66bff;
      color: var(--white-color);
      padding: 5px 20px;
      font-size: 15px;
      border-radius: 10px;
      font-weight: 500;
      font-family: var(--heading-font-family);
    }
    .new {
      position: absolute;
      left: 10px;
      bottom: 10px;
      z-index: 1;
      display: inline-block;
      background-color: #a66bff;
      color: var(--white-color);
      padding: 5px 20px;
      font-size: 15px;
      border-radius: 10px;
      font-weight: 500;
      font-family: var(--heading-font-family);
    }
  }
  .products-content {
    margin-top: 25px;

    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 0;

      a {
        color: var(--black-color);

        &:hover {
          color: var(--main-color);
        }
      }
    }
    span {
      color: var(--paragraph-color);
      font-size: var(--font-size);
      font-weight: 600;
      display: inline-block;
      margin-top: 15px;
    }
  }
  &:hover {
    .products-image {
      &::before {
        opacity: 80%;
        visibility: visible;
      }
      a {
        img {
          transform: scale(1.1);
          filter: blur(2px);
        }
      }
      .heart-line {
        opacity: 0;
        visibility: hidden;
      }
      .heart-fill {
        opacity: 1;
        visibility: visible;
      }
      .add-to-cart-btn {
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
  table {
    margin-bottom: 0;

    thead {
      tr {
        border: none;
        border-bottom: 1px solid #f2f1f3;

        th {
          vertical-align: middle;
          padding: 0 25px 25px 25px;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 600;
          font-family: var(--heading-font-family);
          border: none;
        }
      }
    }
    tbody {
      tr {
        border: none;
        border-bottom: 1px solid #f2f1f3;

        td {
          vertical-align: middle;
          color: var(--paragraph-color);
          white-space: nowrap;
          padding: 25px;
          font-weight: 400;
          font-size: var(--font-size);
          border: none;

          &.product-thumbnail {
            a {
              display: block;

              img {
                width: 100px;
                border-radius: 5px;
              }
            }
          }
          &.product-name {
            a {
              font-size: 18px;
              display: inline-block;
              font-weight: 600;
              font-family: var(--heading-font-family);
              color: var(--paragraph-color);

              &:hover {
                color: var(--main-color);
              }
            }
          }
          &.product-price {
            .unit-amount {
              font-size: var(--font-size);
              font-weight: 600;
              color: var(--paragraph-color);
            }
          }
          &.product-subtotal {
            .subtotal-amount {
              font-size: var(--font-size);
              font-weight: 600;
              color: var(--paragraph-color);
            }
            .remove {
              color: red;
              float: right;
              position: relative;
              top: -1px;
              font-size: 18px;
            }
          }
          &.product-quantity {
            .input-counter {
              text-align: center;
              display: inline-block;
              position: relative;

              span {
                display: inline-block;
                background-color: var(--optional-color);
                cursor: pointer;
                color: var(--white-color);
                width: 50px;
                height: 50px;
                line-height: 55px;
                transition: var(--transition);
                font-size: 20px;
                border-radius: 10px;

                &:hover {
                  color: var(--white-color);
                  background-color: var(--main-color);
                }
              }
              input {
                height: 50px;
                color: var(--optional-color);
                outline: 0;
                display: inline-block;
                border: 1px solid #a868fb;
                background-color: var(--white-color);
                text-align: center;
                width: 50px;
                font-size: var(--font-size);
                font-weight: bold;
                border-radius: 10px;
                position: relative;
                top: -4px;

                &::placeholder {
                  color: var(--optional-color);
                }
              }
            }
          }
        }
      }
      &:not(:first-child) {
        border-top: unset;
      }
    }
  }
}
.cart-buttons {
  margin-top: 30px;
  text-align: end;

  .shopping-coupon-code {
    position: relative;
    max-width: 550px;

    .form-control {
      display: block;
      height: 60px;
      outline: 0;
      background-color: #f2f1f3;
      border: 1px solid #f2f1f3;
      border-radius: 10px;
      box-shadow: none;
      padding: 15px 25px;
      transition: var(--transition);
      font-size: var(--font-size);
      color: #9b8dac;

      &::placeholder {
        transition: var(--transition);
        color: #9b8dac;
      }
      &:focus {
        outline: 0;
        box-shadow: none;

        &::placeholder {
          color: transparent;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 60px;
      border: none;

      &:hover {
        background-color: var(--optional-color);
        color: var(--black-color);
      }
    }
  }
  .default-btn {
    padding: 18px 30px;
  }
}
.cart-totals {
  border: 1px dashed #9b8dac;
  padding: 30px;
  max-width: 500px;
  margin: 50px auto 0;
  border-radius: 15px;
  text-align: center;
  transition: var(--transition);

  h3 {
    margin-bottom: 25px;
    font-size: 22px;
    font-weight: bold;
  }
  ul {
    padding-left: 0;
    margin: 0 0 25px;
    list-style-type: none;

    li {
      border-bottom: 1px solid #f2f1f3;
      color: var(--paragraph-color);
      overflow: hidden;
      font-weight: bold;
      font-size: var(--font-size);
      margin-bottom: 15px;
      padding-bottom: 15px;
      font-family: var(--heading-font-family);

      &:last-child {
        span {
          color: var(--optional-color);
          font-weight: 500;
          font-size: 20px;
        }
      }
    }
  }
  .default-btn {
    padding: 15px 30px;
  }
  &:hover {
    border: 1px dashed var(--main-color);
  }
}

/*================================================
Wishlist Area CSS
=================================================*/
.wishlist-table {
  table {
    margin-bottom: 0;

    thead {
      tr {
        border: none;
        border-bottom: 1px solid #f2f1f3;

        th {
          vertical-align: middle;
          padding: 0 25px 25px 25px;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 600;
          font-family: var(--heading-font-family);
          border: none;
        }
      }
    }
    tbody {
      tr {
        border: none;
        border-bottom: 1px solid #f2f1f3;

        td {
          vertical-align: middle;
          color: var(--paragraph-color);
          white-space: nowrap;
          padding: 25px;
          font-weight: 400;
          font-size: var(--font-size);
          border: none;

          &.product-thumbnail {
            a {
              display: block;

              img {
                width: 100px;
                border-radius: 5px;
              }
            }
          }
          &.product-name {
            a {
              font-size: 18px;
              display: inline-block;
              font-weight: 600;
              font-family: var(--heading-font-family);
              color: var(--paragraph-color);

              &:hover {
                color: var(--main-color);
              }
            }
          }
          &.product-price {
            .unit-amount {
              font-size: var(--font-size);
              font-weight: 600;
              color: var(--paragraph-color);
            }
          }
          &.instock {
            color: #05ce69;
            font-size: var(--font-size);
            font-weight: 600;
          }
          &.remove {
            a {
              i {
                color: #ff3666;
                font-size: 22px;
              }
            }
          }
        }
      }
      &:not(:first-child) {
        border-top: unset;
      }
    }
  }
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  background-color: #f5f5f5;
  padding: 15px 20px;
  position: relative;
  margin-bottom: 40px;
  border-radius: 15px;

  i {
    margin-right: 5px;
    font-size: 20px;
    position: relative;
    top: 3.5px;
    color: var(--paragraph-color);
  }
  span {
    letter-spacing: 1px;
    display: inline-block;
    color: var(--paragraph-color);
    font-size: var(--font-size);
    font-weight: 600;
    font-family: var(--heading-font-family);

    a {
      display: inline-block;
      color: var(--paragraph-color);

      &:hover {
        color: var(--optional-color);
      }
    }
  }
}
.billing-details {
  .title {
    margin-bottom: 30px;
    position: relative;
    font-size: 22px;
    font-weight: bold;
  }
  .form-group {
    margin-bottom: 25px;

    label {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: var(--font-size);
      color: var(--paragraph-color);
      font-family: var(--heading-font-family);

      .required {
        color: red;
      }
    }
    .form-control {
      height: 55px;
      padding: 15px 20px;
      line-height: initial;
      color: var(--paragraph-color);
      border: 1px solid #eeeeee;
      box-shadow: unset;
      border-radius: 10px;
      transition: var(--transition);
      font-size: 15px;
      font-weight: 500;

      &::placeholder {
        color: var(--paragraph-color);
      }
      &:focus {
        border: 1px solid var(--main-color);

        &::placeholder {
          color: transparent;
          transition: var(--transition);
        }
      }
    }
    textarea {
      &.form-control {
        min-height: 115px;
      }
    }
    .nice-select {
      float: unset;
      height: 55px !important;
      line-height: 55px;
      color: var(--paragraph-color);
      padding-top: 0;
      padding-bottom: 0;
      font-size: 15px;
      font-weight: 500;
      border-radius: 10px;

      .list {
        background: var(--btn-gradient-color);
        border-radius: 0;
        margin-top: 0;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;

        .option {
          transition: var(--transition);
          padding: {
            left: 20px;
            right: 20px;
          }
          color: var(--black-color);
          font-weight: 500;
          &:hover {
            background-color: var(--main-color) !important;
            color: var(--white-color);
          }
          &.selected {
            background-color: transparent;
            font-weight: 400;
          }
        }
      }
      &:after {
        right: 20px;
        width: 8px;
        height: 8px;
        margin-top: -5.5px;
      }
    }
    .form-check {
      margin-bottom: 0;

      .form-check-input {
        margin-top: 2.8px;

        &:focus {
          outline: 0;
          box-shadow: unset;
        }
      }
      label {
        margin-bottom: 0;
      }
    }
  }
  .col-lg-12 {
    &:last-child {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
}
.order-details {
  .title {
    margin-bottom: 30px;
    position: relative;
    font-size: 22px;
    font-weight: bold;
  }
  .order-table {
    table {
      margin-bottom: 0;

      thead {
        tr {
          th {
            border-bottom-width: 0;
            vertical-align: middle;
            border: 1px solid rgba(187, 187, 187, 0.15);
            padding: 14px 20px 10px;
            color: var(--paragraph-color);
            white-space: nowrap;
            letter-spacing: 1px;
            font-weight: 500;
            font-size: var(--font-size);
          }
        }
      }
      tbody {
        tr {
          td {
            vertical-align: middle;
            color: var(--paragraph-color);
            white-space: nowrap;
            border: 1px solid rgba(187, 187, 187, 0.15);
            font-size: 15px;
            font-weight: 400;
            padding: {
              left: 20px;
              right: 20px;
              top: 15px;
              bottom: 12px;
            }
            &.product-name {
              a {
                display: inline-block;
                color: var(--paragraph-color);

                &:hover {
                  color: var(--main-color);
                }
              }
            }
            &.order-subtotal,
            &.order-shipping,
            &.total-price {
              span {
                color: var(--paragraph-color);
                font: {
                  weight: 500;
                  size: var(--font-size);
                }
              }
            }
            &.shipping-price,
            &.order-subtotal-price,
            &.product-subtotal {
              color: var(--paragraph-color);
              font: {
                weight: 500;
                size: var(--font-size);
              }
            }
          }
        }
        &:not(:first-child) {
          border-top: unset;
        }
      }
    }
  }
  .payment-box {
    background-color: #f5f5f5;
    margin-top: 30px;
    padding: 30px;
    border-radius: 15px;

    .payment-method {
      p {
        font-size: 15px;

        [type="radio"] {
          &:checked,
          &:not(:checked) {
            display: none;
          }
        }
        [type="radio"] {
          &:checked,
          &:not(:checked) {
            + label {
              padding-left: 25.8px;
              cursor: pointer;
              display: block;
              color: var(--paragraph-color);
              position: relative;
              margin-bottom: 8px;
              font-weight: 600;
              font-size: var(--font-size);
              font-family: var(--heading-font-family);

              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 3px;
                width: 18px;
                height: 18px;
                border: 1px solid #dddddd;
                border-radius: 50%;
                background: var(--white-color);
              }
              &::after {
                content: "";
                width: 12px;
                height: 12px;
                background: var(--main-color);
                position: absolute;
                top: 6px;
                left: 3px;
                border-radius: 50%;
                transition: var(--transition);
              }
            }
          }
        }
        [type="radio"] {
          &:not(:checked) {
            + label {
              &::after {
                opacity: 0;
                visibility: hidden;
                transform: scale(0);
              }
            }
          }
        }
        [type="radio"] {
          &:checked {
            + label {
              &::after {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
              }
            }
          }
        }
      }
    }
    .default-btn {
      margin-top: 20px;
      display: block;
      width: 100%;
      text-align: center;
      transform: unset;
      border-radius: 50px;
      padding: 15px 30px;

      &::before {
        width: 750px;
        height: 750px;
      }
    }
  }
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  img {
    border-radius: 20px;
    transition: var(--transition);
  }
  .heart-line {
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 1;
    visibility: visible;
    transition: var(--transition);
    z-index: 1;

    a {
      i {
        color: #ff3666;
        font-size: 25px;
        transition: var(--transition);
      }
    }
  }
  .heart-fill {
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);
    z-index: 1;

    a {
      i {
        color: #ff3666;
        font-size: 25px;
        transition: var(--transition);
      }
    }
  }
  .sale {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
    display: inline-block;
    background-color: #a66bff;
    color: var(--white-color);
    padding: 5px 20px;
    font-size: 15px;
    border-radius: 10px;
    font-weight: 500;
    font-family: var(--heading-font-family);
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
    .heart-line {
      opacity: 0;
      visibility: hidden;
    }
    .heart-fill {
      opacity: 1;
      visibility: visible;
    }
  }
}
.products-details-desc {
  padding-left: 25px;

  h3 {
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: bold;
  }
  .price {
    margin-bottom: 15px;
    color: var(--paragraph-color);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;

    .old-price {
      text-decoration: line-through;
      color: var(--optional-color);
      font-weight: 600;
      position: relative;
      top: -1px;
    }
  }
  p {
    margin-bottom: 0;
    color: var(--paragraph-color);
  }
  .products-meta {
    margin-top: 25px;

    span {
      display: block;
      color: var(--paragraph-color);
      margin-bottom: 20px;
      font-weight: 600;
      font-size: var(--font-size);

      &:last-child {
        margin-bottom: 0;
      }
      span {
        display: inline-block;
        margin-bottom: 0;

        &.sku {
          color: var(--optional-color);
        }
        &.in-stock {
          color: #05ce69;
        }
      }
      a {
        display: inline-block;
        color: var(--paragraph-color);

        &:hover,
        &:focus {
          color: var(--main-color);
        }
      }
    }
  }
  .products-add-to-cart {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #f2f1f3;

    .input-counter {
      text-align: center;
      display: inline-block;
      position: relative;

      span {
        display: inline-block;
        background-color: var(--optional-color);
        cursor: pointer;
        color: var(--white-color);
        width: 55px;
        height: 55px;
        line-height: 60px;
        transition: var(--transition);
        font-size: 20px;
        border-radius: 10px;

        &:hover {
          color: var(--white-color);
          background-color: var(--main-color);
        }
      }
      input {
        height: 55px;
        color: var(--optional-color);
        outline: 0;
        display: inline-block;
        border: 1px solid #a868fb;
        background-color: var(--white-color);
        text-align: center;
        width: 55px;
        font-size: var(--font-size);
        font-weight: bold;
        border-radius: 10px;
        position: relative;
        top: -4px;

        &::placeholder {
          color: var(--optional-color);
        }
      }
    }
    .default-btn {
      padding: 15.5px 30px;
      border: none;
      margin-left: 10px;
      position: relative;
      top: -4px;
    }
  }
}
.products-details-tabs {
  margin-top: 50px;

  .nav {
    margin-bottom: 35px;
    list-style-type: none;
    display: block;
    border-bottom: none;
    text-align: center;

    .nav-item {
      display: inline-block;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
      .nav-link {
        border-radius: 0;
        border: none;
        padding: 0;
        transition: var(--transition);
        padding: 15px 30px;
        border-radius: 15px;
        font-size: var(--font-size);
        font-weight: bold;
        background-color: #f2f1f3;
        color: var(--black-color);

        &:hover,
        &.active {
          color: var(--white-color);
          background: radial-gradient(
            circle,
            #a66bff,
            #c666ef,
            #dd62df,
            #ee61cf,
            #fb64bf
          );
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      padding: 30px;
      background: #f5f5f5;
      border-radius: 15px;

      .content {
        p {
          line-height: 1.8;
        }
      }
      .products-reviews {
        h3 {
          margin-bottom: 15px;
          display: inline-block;
          font-size: 22px;
          font-weight: bold;
        }
        .rating {
          span {
            font-size: var(--font-size);
            color: #cecfd2;

            &.checked {
              color: orange;
            }
          }
        }
        .rating-count {
          margin-top: 10px;
          margin-bottom: 20px;

          span {
            display: block;
            font-size: 15px;
            color: var(--paragraph-color);
            font-weight: 500;
          }
        }
        .row {
          overflow: hidden;
          margin-left: 0;
          margin-right: 0;
        }
        .side {
          float: left;
          width: 8%;
          margin-top: 10px;
          padding-left: 0;
          padding-right: 0;

          div {
            font-size: var(--font-size);
            font-weight: 600;
            font-family: var(--heading-font-family);
          }
        }
        .middle {
          margin-top: 14px;
          float: left;
          width: 82%;
        }
        .right {
          text-align: end;
        }
        .bar-container {
          width: 100%;
          background-color: var(--white-color);
          text-align: center;
          border-radius: 5px;
        }
        .bar-5 {
          width: 100%;
          height: 18px;
          background-color: #4caf50;
          border-radius: 5px;
        }
        .bar-4 {
          width: 75%;
          height: 18px;
          background-color: #2196f3;
          border-radius: 5px;
          border-radius: 5px;
        }
        .bar-3 {
          width: 50%;
          height: 18px;
          background-color: #00bcd4;
          border-radius: 5px;
        }
        .bar-2 {
          width: 25%;
          height: 18px;
          background-color: #ff9800;
          border-radius: 5px;
        }
        .bar-1 {
          width: 0;
          height: 18px;
          background-color: #f44336;
          border-radius: 5px;
        }
      }
      .products-review-comments {
        margin-top: 40px;

        h3 {
          border-bottom: 1px solid var(--white-color);
          padding-bottom: 12px;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: bold;
        }
        .user-review {
          border-bottom: 1px solid var(--white-color);
          padding: 20px 0 20px 110px;
          position: relative;

          img {
            position: absolute;
            left: 0;
            top: 20px;
            width: 90px;
            height: 90px;
            border-radius: 20px;
          }
          .sub-comment {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 15px;
            color: var(--main-color);
          }
          p {
            font-size: 15px;
          }
          .review-rating {
            display: block;
            margin-bottom: 8px;

            .review-stars {
              display: inline-block;

              i {
                color: #cecfd2;
                font-size: 15px;
                display: inline-block;
                margin-right: -2px;

                &.checked {
                  color: orange;
                }
              }
            }
            span {
              color: var(--black-color);
              position: relative;
              top: -2px;
              font-weight: 600;
              margin-left: 5px;
            }
          }
        }
      }
      .review-form-wrapper {
        margin-top: 30px;

        h3 {
          margin-bottom: 10px;
          font-size: 18px;
          letter-spacing: 1px;
          color: var(--black-color);
          font-weight: bold;
        }
        .comment-notes {
          span {
            color: red;
          }
        }
        form {
          margin-top: 20px;

          .form-group {
            margin-bottom: 25px;
            text-align: start;

            .form-control {
              height: 55px;
              padding: 15px 20px;
              line-height: initial;
              color: var(--paragraph-color);
              background-color: var(--white-color);
              box-shadow: unset;
              border-radius: 15px;
              transition: var(--transition);
              font-size: 15px;
              font-weight: 400;

              &::placeholder {
                color: var(--paragraph-color);
              }
              &:focus {
                border: 1px solid var(--main-color);

                &::placeholder {
                  color: transparent;
                  transition: var(--transition);
                }
              }
            }
            textarea {
              &.form-control {
                min-height: 115px;
              }
            }
          }
          .rating {
            text-align: left;
            overflow: hidden;
            max-width: 115px;
            margin-top: -5px;
            margin-bottom: 30px;

            label {
              float: right;
              position: relative;
              width: 23px;
              height: 23px;
              cursor: pointer;

              &:not(:first-of-type) {
                padding-right: 5px;
              }
              &:before {
                content: "\2605";
                transition: var(--transition);
                font-size: 27px;
                color: var(--paragraph-color);
                line-height: 1;
              }
            }
            input {
              display: none;
            }
            input:checked ~ label:before,
            &:not(:checked) > label:hover:before,
            &:not(:checked) > label:hover ~ label:before {
              color: #f6b500;
            }
          }
          .comment-form-cookies-consent {
            input {
              margin-right: 5px;
              position: relative;
            }
          }
          .default-btn {
            margin-top: 22px;
            border: none;
            padding: 15px 30px;
          }
        }
      }
    }
  }
}
.related-title {
  text-align: start;
  margin-bottom: 35px;

  h2 {
    font-size: 22px;
    font-weight: bold;
  }
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
  padding-left: 30px;

  .widget {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
    .widget-title {
      margin-bottom: 25px;
      position: relative;
      padding-bottom: 15px;
      font-size: 20px;
      font-weight: bold;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 1px;
        bottom: -1px;
        background-color: var(--main-color);
      }
    }
  }
  .widget_search {
    form {
      position: relative;

      .search-field {
        height: 70px;
        color: var(--black-color);
        border: 1px solid #f2f1f3;
        display: block;
        width: 100%;
        border-radius: 15px;
        padding: 15px 20px 15px 70px;
        transition: var(--transition);
        font-size: 15px;
        font-weight: 400;
        outline: 0;
        background: #f2f1f3;

        &::placeholder {
          color: var(--paragraph-color);
          transition: var(--transition);
        }
        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
      button {
        border: none;
        background-color: transparent;
        color: var(--black-color);
        position: absolute;
        left: 32px;
        padding: 0;
        transition: var(--transition);
        top: 22.8px;
        font-size: 25px;
        line-height: 0;

        &:hover,
        &:focus {
          color: var(--main-color);
        }
      }
    }
  }
  .widget_categories {
    .list {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: var(--paragraph-color);
          display: inline-block;
          font-weight: 500;
          font-family: var(--heading-font-family);
          padding: 15px 25px;
          background-color: var(--white-color);
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          border-radius: 30px;
          width: 100%;

          &:hover {
            color: var(--main-color);
          }
        }
      }
    }
  }
  .widget_recent_post {
    position: relative;
    overflow: hidden;

    .item {
      overflow: hidden;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
      .thumb {
        float: left;
        height: 90px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 90px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 90px;
          height: 90px;
          display: inline-block;
          border-radius: 10px;
          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }
          &.bg1 {
            background-image: url(../../public/images/blog/blog-1.jpg);
          }
          &.bg2 {
            background-image: url(../../public/images/blog/blog-2.jpg);
          }
          &.bg3 {
            background-image: url(../../public/images/blog/blog-3.jpg);
          }
        }
        &::before,
        &::after {
          transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
          content: "";
          background-color: var(--white-color);
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }
        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }
        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }
      .info {
        overflow: hidden;
        margin-top: 2.5px;

        span {
          display: block;
          color: var(--optional-color);
          margin-bottom: 12px;
          font-size: 15px;
          font-weight: 500;
          font-family: var(--heading-font-family);
        }
        .title {
          margin-bottom: 0;
          line-height: 1.5;
          font-size: var(--font-size);
          font-weight: 600;

          a {
            display: inline-block;
          }
        }
      }
      &:hover {
        .thumb {
          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }
  .widget_tag_cloud {
    .widget-title {
      margin-bottom: 18px;
    }
  }
  .tagcloud {
    a {
      display: inline-block;
      background: #f5f5f5;
      color: var(--paragraph-color);
      padding: 8px 15px;
      border: none;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      margin-top: 8px;
      margin-right: 5px;

      &:hover,
      &:focus {
        color: var(--white-color);
        background-color: var(--main-color);
      }
    }
  }
  .widget_recent_courses {
    position: relative;
    overflow: hidden;

    .item {
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
      .thumb {
        float: left;
        height: 90px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 90px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 90px;
          height: 90px;
          display: inline-block;
          border-radius: 10px;
          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }
          &.bg1 {
            background-image: url(../../public/images/courses/courses-1.jpg);
          }
          &.bg2 {
            background-image: url(../../public/images/courses/courses-2.jpg);
          }
          &.bg3 {
            background-image: url(../../public/images/courses/courses-3.jpg);
          }
        }
        &::before,
        &::after {
          transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
          content: "";
          background-color: var(--white-color);
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }
        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }
        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }
      .info {
        overflow: hidden;

        span {
          display: block;
          color: var(--main-color);
          margin-bottom: 14px;
          font-size: 15px;
          font-weight: 500;
          font-style: italic;
        }
        .title {
          margin-bottom: 0;
          line-height: 1.5;
          font-size: var(--font-size);
          font-weight: 600;

          a {
            display: inline-block;
          }
        }
      }
      &:hover {
        .thumb {
          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }
  .widget_insight {
    border-radius: 15px;

    .list {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
        a {
          position: relative;
          display: block;
          padding: 15px 25px 15px 40px;
          color: var(--paragraph-color);
          border: 1px solid #eeeeee;
          font-size: 15px;
          font-weight: 500;
          border-radius: 15px;

          &::before {
            width: 5px;
            height: 5px;
            transition: var(--transition);
            background-color: var(--main-color);
            content: "";
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
          }
          &:hover,
          &.active {
            border-color: var(--main-color);
            background-color: var(--main-color);
            color: var(--white-color);

            &::before {
              background-color: var(--white-color);
            }
          }
        }
        &.active {
          a {
            border-color: var(--main-color);
            background-color: var(--main-color);
            color: var(--white-color);

            &::before {
              background-color: var(--white-color);
            }
          }
        }
      }
    }
  }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 20px;
  text-align: center;

  .page-numbers {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    background-color: var(--white-color);
    border: 2px solid var(--optional-color);
    color: var(--optional-color);
    text-align: center;
    border-radius: 50px;
    position: relative;
    margin: 0 2px;
    font-size: var(--font-size);
    font-weight: bold;
    font-family: var(--heading-font-family);

    &:hover,
    &.current {
      color: var(--white-color);
      background-color: var(--main-color);
      border: 2px solid var(--main-color);
    }
    i {
      font-size: 20px;
    }
    &.prev {
      background-color: #f2f1f3;
      border: 2px solid #f2f1f3;

      i {
        font-size: 25px;
        position: relative;
        top: 5px;
        left: -2px;
      }
      &:hover {
        color: var(--white-color);
        background-color: var(--main-color);
        border: 2px solid var(--main-color);
      }
    }
    &.next {
      background-color: #f2f1f3;
      border: 2px solid #f2f1f3;

      i {
        font-size: 25px;
        position: relative;
        top: 5px;
        left: 2px;
      }
      &:hover {
        color: var(--white-color);
        background-color: var(--main-color);
        border: 2px solid var(--main-color);
      }
    }
  }
}

// Go Top Area CSS
.scroll-to-top {
  i {
    position: fixed;
    cursor: pointer;
    bottom: 20px;
    right: 20px;
    color: var(--white-color);
    background-color: var(--main-color);
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    font-size: 22px;
    transition: var(--transition);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    z-index: 999999;

    &:hover {
      transition: var(--transition);
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);
      transform: translateY(-5px);
    }
  }
}

// Lines CSS
.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;

  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: #dce3ff;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 15vh;
      width: 100%;
      top: -50%;
      left: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 75%,
        #ffffff 100%
      );
      animation: run 15s 0s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
      border-radius: 50%;
    }
    &:nth-child(1) {
      margin-left: -25%;
    }
    &:nth-child(2) {
      margin-left: 0;
    }
    &:nth-child(3) {
      margin-left: 25%;
    }
  }
}
@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

// Map CSS
.map-location {
  iframe {
    border: none;
    border-radius: 15px;
  }
}
.gm-style {
  border-radius: 10px !important;

  .place-card-large {
    padding: 10px !important;
  }
}

/*=================================
Buy Now Btn
====================================*/
.buy-now-btn {
  right: 30px;
  z-index: 99;
  top: 50%;
  position: fixed;
  transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: var(--white-color) !important;
  background-color: #82b440;
  padding: 8px 20px 8px;
  font-size: 14px;
  font-weight: 500;
  animation: {
    name: tada;
    duration: 5s;
    fill-mode: both;
    iteration-count: infinite;
  }
  &:hover {
    background-color: var(--main-color);
    color: var(--white-color) !important;
  }
}
@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.social-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    a {
      i {
        background-color: #fff;
        color: #000;
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 30px;
        line-height: 30px;
        &:hover{
          background-color: var(--main-color);
          color: #fff;
        }
      }
    }
  }
}